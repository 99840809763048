import {
  generic,
  deviceConfiguration,
  login,
  logout,
  mediaPermissions,
  meeting,
  screenShare,
  browserCheck,
  liveStreaming,
  tooltip,
  notificationMessage,
  layout,
  recording,
  accountCmp,
  userTabCmp,
  regenPinsTabCmp,
  liveStreamingCmp,
  errors,
  success,
  roster,
  chat,
} from './l10n.it';

import { LocaleMessages } from './types';


const it: LocaleMessages = Object.assign({},
  generic,
  deviceConfiguration,
  login,
  logout,
  mediaPermissions,
  meeting,
  screenShare,
  browserCheck,
  liveStreaming,
  tooltip,
  notificationMessage,
  layout,
  recording,
  userTabCmp,
  accountCmp,
  regenPinsTabCmp,
  liveStreamingCmp,
  errors,
  success,
  roster,
  chat,
);


export default it;
