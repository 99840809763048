import React from 'react';

import { Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { defineMessages, useIntl } from 'react-intl';

import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import AudioElement from '../AudioElement';

import { usePrevious } from '../../lib/utils/hooks';
import { checkPermissionNotification } from '../../notification';
import { State } from '../../lib/reducers';
import { isThereAnyVideo } from '../../lib/reduxSelectors/room';
import { isRecorder } from '../../lib/reduxSelectors/session';
import { findRoomOwner } from '../../lib/reduxSelectors/presentationLayout';
import {
  createVideoRoom,
  tearDownRoom,
  addStream,
  togglePublishVideo,
  toggleAllVideoMute,
  Action as RoomAction
} from '../../lib/actions/room';
import { joinRoom, leaveChannel } from '../../lib/actions/websocket';
import prepareWebRtcProvider from '../../rtc';
import { getLogger } from '../../lib/logger';

import FullscreenLayout from './FullscreenLayout';
import GridLayout from './GridLayout';
import PresentationLayout from './PresentationLayout';
import WebinarLayout from './WebinarLayout';
import LessonLayout from './LessonLayout';
import AudioOnlyLayout from './AudioOnlyLayout';


const messages = defineMessages({
  pleaseWaitForRoom: { id: 'pleaseWaitForRoom' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingMaskContainer: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loadingMaskMessage: {
      padding: theme.spacing(2),
    },
  })
);


function onWebinarLayout(
  dispatch: Dispatch<Action>,
  layout: MappedProps['layout'],
  previousLayout: MappedProps['layout'] | undefined,
  amIRoomOwner: boolean
) {
  if (layout === 'webinar' && previousLayout !== 'webinar') {
    if (!amIRoomOwner) {
      // disable  my video
      dispatch(togglePublishVideo(true));
    }
  }
  else if (layout !== 'webinar' && previousLayout === 'webinar') {
    if (!amIRoomOwner) {
      // reenable my video
      dispatch(togglePublishVideo(false));
    }
  }
}


function onFullscreenLayout(
  dispatch: Dispatch<Action>,
  layout: MappedProps['layout'],
  previousLayout: MappedProps['layout'] | undefined
) {
  if (layout !== 'fullscreen' && previousLayout === 'fullscreen') {
    dispatch(toggleAllVideoMute(false));
  }
}


function Layout(
  layout: MappedProps['layout'],
  previousLayout: MappedProps['layout'] | undefined,
  noVideos: boolean,
  amIRoomOwner: boolean
) {
  const dispatch = useDispatch();

  const logger = getLogger('Debug layout');
  logger.info('layout:', layout, 'prevLayout', previousLayout);

  if (noVideos) {
    return (
      <AudioOnlyLayout />
    );
  }

  if (layout === 'audioonly' && previousLayout !== 'audioonly') {
    // disable all videos
    dispatch(togglePublishVideo(true));
    dispatch(toggleAllVideoMute(true));
  }
  else if (layout !== 'audioonly' && previousLayout === 'audioonly') {
    // reenable all videos
    dispatch(togglePublishVideo(false));
    dispatch(toggleAllVideoMute(false));
  }

  onWebinarLayout(dispatch, layout, previousLayout, amIRoomOwner);

  onFullscreenLayout(dispatch, layout, previousLayout);

  if (layout === 'default') {
    return (
      <GridLayout />
    );
  }
  else if (layout === 'featured') {
    return (
      <PresentationLayout />
    );
  }
  else if (layout === 'audioonly') {
    return (
      <AudioOnlyLayout />
    );
  }
  else if (layout === 'fullscreen') {
    return (
      <FullscreenLayout />
    );
  }
  else if (layout === 'lesson') {
    return (
      <LessonLayout />
    );
  }
  else {
    return (
      <WebinarLayout />
    );
  }
}


// eslint-disable-next-line @typescript-eslint/no-explicit-any
type WsAction = any   // FIXME
type Action = RoomAction & WsAction


function SetupRoomActions(props: ExtendedProps) {
  const joinMuted = props.audioJoinMuted || Boolean(props.roomOptions.join_muted);
  const roomOptions = {
    video: props.withVideo,
    muted: joinMuted,
    frameRate: props.roomOptions.frame_rate,
    streamQuality: props.roomOptions.stream_quality,
  };
  const { isSocketConnected, roomJoined } = props;

  const meetingId = props.match.params.id;
  const logger = getLogger(`Meeting ${meetingId}`);

  const dispatch = useDispatch<Action>();

  // the callback to dispatch room actions are defined as refs because we do
  // not want to track their change when scheduling the effects below (e.g. we
  // need to run the join meeting callback only when the isSocketConnected prop
  // has changed, and not track the meetingId, logger changes).
  // Defining them as refs avoids putting them in the useEffect dependencies
  // array without removing the exhaustive-deps eslint check.
  const joinMeeting = React.useRef(
    () => {
      logger.info(`joining room ${meetingId}`);
      dispatch(createVideoRoom(meetingId, prepareWebRtcProvider(), logger));
      dispatch(joinRoom(meetingId, logger));
    }
  );

  const stopRoom = React.useRef(
    () => {
      logger.info('tear down room');
      dispatch(tearDownRoom());
      dispatch(leaveChannel());
    }
  );

  const addLocalStream = React.useRef(
    () => {
      logger.info('add local stream');
      dispatch(addStream(meetingId, roomOptions, logger));
    }
  );

  React.useEffect(
    () => {
      if (isSocketConnected) {
        joinMeeting.current();
      }
      // capture the current value of teardown to be run at unmount. This
      // should not be necessary, but avoids a compiler warning
      const tdown = stopRoom.current;
      return () => {
        if (isSocketConnected) {
          tdown();
        }
      };
    }
    , [isSocketConnected]
  );

  React.useEffect(
    () => {
      if (roomJoined) {
        addLocalStream.current();
      }
    },
    [roomJoined]
  );
}


function LoadingMask(props: {isRecorder: boolean}) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const getLoadingMask = () => {
    return (
      <React.Fragment>
        <CircularProgress />
        <Typography variant='caption' className={classes.loadingMaskMessage}>
          {formatMessage(messages.pleaseWaitForRoom)}
        </Typography>
      </React.Fragment>
    );
  };

  return (
    <div className={classes.loadingMaskContainer}>
      {props.isRecorder
        ? null
        : getLoadingMask()}
    </div>
  );
}


function Room(props: ExtendedProps) {
  React.useEffect(checkPermissionNotification, []);
  SetupRoomActions(props);

  const loadingMaskTimeout = 2000;

  const [loadingMask, setLoadingMask] = React.useState(false);

  const previousLayout = usePrevious(props.layout, undefined);

  React.useEffect(
    () => {
      if (props.isThereAnyVideo) {
        setLoadingMask(false);
      }
    }
    , [props.isThereAnyVideo]
  );

  React.useEffect(
    () => {
      setLoadingMask(true);
      const timer = setTimeout(
        () => {
          setLoadingMask(false);
        }, loadingMaskTimeout
      );
      return (
        () => {
          if (timer) clearTimeout(timer);
        }
      );
    }
    , []
  );

  return (
    <div style={{ width: 'inherit', height: 'inherit' }}>
      <AudioElement src={props.audio_stream} handleAudioOutputChange={true} />
      { loadingMask
        ? <LoadingMask isRecorder={props.isRecorder}/>
        : Layout(props.layout, previousLayout, !props.isThereAnyVideo, props.amIRoomOwner)
      }
    </div>
  );
}



type MappedProps =
  Pick<State['room'], 'layout' | 'audio_stream'>
& {
  isSocketConnected: State['websocket']['isConnected'];
  isRecorder: boolean;
  roomJoined: boolean;
  isThereAnyVideo: boolean;
  withVideo: boolean;
  audioJoinMuted: boolean;
  amIRoomOwner: boolean;
  roomOptions: State['appconfig']['room_options'];
}


type Props = {} & RouteComponentProps<{id: string}>


type ExtendedProps = Props & MappedProps


const mapStateToProps = (state: State, { match }: Props): MappedProps => {
  const roomOwner = findRoomOwner(state);
  return {
    layout: state.room.layout,
    // eslint-disable-next-line @typescript-eslint/camelcase
    audio_stream: state.room.audio_stream,
    isSocketConnected: state.websocket.isConnected,
    isRecorder: isRecorder(state, match.url),
    roomJoined: Boolean(state.websocket.room),
    isThereAnyVideo: isThereAnyVideo(state),
    withVideo: state.settings.videoEnabled,
    audioJoinMuted: state.settings.audioJoinMuted,
    amIRoomOwner: Boolean(roomOwner) && (roomOwner === state.websocket.uid),
    roomOptions: state.appconfig.room_options,
  };
};


export default withRouter(connect(mapStateToProps)(Room));
