import { commitMutation } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { ConnectionHandler } from 'relay-runtime';
import { newEvent, ERROR } from '../../notifications';

const mutation = graphql`
mutation updateMeetingMutation(
  $input: UpdateMeetingInput!
) {
  updateMeeting(input: $input)
  {
    errors {
      key
      message
      reason
    }
    meeting {
      id
      userId
      domainId
      title,
      dtStart,
      dtEnd,
      attendees {
        email
      },
      notes
    }
  }
}
`;

// FIXME
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
function maybeNotifyError(response) {
  let inError = false;

  if (response && response.updateMeeting.errors !== null) {
    inError = true;
  }
  if (!response) {
    inError = true;
  }

  if (inError) {
    newEvent(ERROR, 'errorCodeGenericBody', 'errorCodeGenericBody',
      "Update meeting error");
  }
}

// FIXME
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
function updateMeeting(environment, { id, title, userId, domainId, dtStart, dtEnd, users, notes }) {
  const attendees = users.value.map((el: string) => {
    return ({ email: el });
  });
  return new Promise((resolve, reject) => {
    const variables = {
      input: {
        meeting: {
          id,
          title,
          userId,
          domainId,
          dtStart,
          dtEnd,
          attendees,
          notes
        }
      },
    };

    /*const optimisticResponse = {
      updateMeeting: variables.input,
    };*/

    commitMutation(
      environment,
      {
        mutation,
        variables,
        updater: (store) => {
          const payload = store.getRootField("updateMeeting");

          // FIXME
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          const model = payload.getLinkedRecord("meeting");
          const meet = { id: 'client:root' };
          const parent = store.get(meet.id);
          // FIXME
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          const connection = ConnectionHandler.getConnection(parent, "data_myMeetings");
          if (connection) {
            // a date can be updated, need to refresh connection from scratch to preserve order...
            // FIXME
            // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
            // @ts-ignore
            ConnectionHandler.insertEdgeAfter(connection, model);
          }
        },
        //optimisticResponse,
        onCompleted: (response, errors) => {
          if (errors) {
            maybeNotifyError(null);
            return reject(errors);
          }
          maybeNotifyError(response);
          return resolve(response);
        },
        onError: err => {
          maybeNotifyError(null);
          reject(err);
        }
      },
    );
  });
}

export { updateMeeting };
