/* Libraries Imports */
import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import { withStyles, WithStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { withRouter } from 'react-router-dom';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';

/* UI Imports */
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Hidden } from '@material-ui/core';
import { IconEdit, IconTrash, IconMore } from '../IconSet';
/* Other Imports */
import ScheduleMeeting from '../ScheduleMeeting';
import getRouteFor, { Route } from '../../lib/utils/router';
import style from './styleDetails';
import { Meeting } from './View';
import StyledMenu from '../StyledMenu';


const messages = defineMessages({
  deleteMeeting: { id: 'deleteMeeting' },
  editMeeting: { id: 'editMeeting' },
  joinButton: { id: 'joinButton' },
  attendees: { id: 'attendees' },
});

export interface ScheduleEvent {
  users: Array<string | null> | null;
  title: string;
  notes: string;
  dtStart: moment.Moment;
  dtEnd: moment.Moment;
  userId: string;
  meetingId: string;
  domainId: string;
}

interface State {
  editMeetingDialog: boolean;
  editMeetingDetails: ScheduleEvent | null;
  anchorEl: HTMLElement | null;
}

interface Props {
  //formatDate: (date: string) => string;
  onDelete: (meeting: Meeting) => void;
  meeting: Meeting;
}

type PropsType = Props & WithStyles<typeof style> & RouteComponentProps & { intl: IntlShape };

class MeetingDetails extends Component<PropsType, State> {

  constructor(props: PropsType) {
    super(props);

    this.handleJoin = this.handleJoin.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.getMeetingActions = this.getMeetingActions.bind(this);

    this.state = {
      editMeetingDialog: false,
      editMeetingDetails: null,
      anchorEl: null,
    };

  }

  handleJoin() {
    const path = getRouteFor(Route.Meeting, { slug: this.props.meeting.slug });
    this.props.history.push(path);
  }

  handleEdit() {
    this.openScheduleMeetingDialog(this.props.meeting);
  }

  handleDelete = () => {
    this.props.onDelete(this.props.meeting);
  }

  openScheduleMeetingDialog(meeting: Meeting) {
    let notes = "";
    const { id, title, dtStart, dtEnd, attendees, userId, domainId } = meeting;

    if (meeting.notes) {
      notes = meeting.notes;
    }

    const users = attendees.map(function(a) {
      return a.email;
    });

    const meetingDetails: ScheduleEvent = {
      users: users,
      title: title,
      notes: notes,
      dtStart: moment(dtStart),
      dtEnd: moment(dtEnd),
      meetingId: id,
      userId: userId,
      domainId: domainId
    };

    this.setState({
      editMeetingDialog: true,
      editMeetingDetails: meetingDetails
    });
  }

  onEditMeetingClose = () => {
    this.setState({ editMeetingDialog: false, editMeetingDetails: null });
  }

  getMeetingActions = () => {
    return (
      <div >
        <Tooltip placement="top" title={this.props.intl.formatMessage(messages.deleteMeeting)}>
          <IconButton
            size="small"
            onClick={this.handleDelete}
          >
            <IconTrash size={24} />
          </IconButton>
        </Tooltip>
        <Tooltip placement="top" title={this.props.intl.formatMessage(messages.editMeeting)}>
          <IconButton
            size="small"
            onClick={this.handleEdit}
          >
            <IconEdit size={24} />
          </IconButton>
        </Tooltip>
        <Button color="primary" onClick={this.handleJoin} className={this.props.classes.buttonNoStyle}>
          {this.props.intl.formatMessage(messages.joinButton)}
        </Button>
      </div>
    );
  };

  handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    this.setState({ anchorEl: event.currentTarget });
  };

  closeMenu = (): void => {
    this.setState({ anchorEl: null });
  }

  render() {
    const { classes, intl } = this.props;
    const { title, dtStart, dtEnd, attendees } = this.props.meeting;
    const open = Boolean(this.state.anchorEl);
    const start = moment(dtStart).format('HH:mm');
    const end = moment(dtEnd).format('HH:mm');
    return (
      <div className={classes.container}>

        <Grid container spacing={3} justify-content="space-around">
          <Grid item xs={10} md={8} className={classes.detailsInfo}>
            <Typography variant="body2" gutterBottom className={classes.title}>
              {title}
            </Typography>
            <Grid container>
              <Typography variant="caption" gutterBottom className={classes.dtContainer}>
                {start} - {end}

              </Typography>
              <Typography variant="caption" gutterBottom className={classes.dtContainer}>
                |
              </Typography>
              <Typography variant="caption" gutterBottom>
                {attendees ? attendees.length : 0} {intl.formatMessage(messages.attendees).toLowerCase()}
              </Typography>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item md={4} className={classes.actionButtons}>
              {this.getMeetingActions()}
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid item xs={2} className={classes.actionButtons}>
              <IconButton onClick={this.handleMenu}>
                <IconMore size={20} />
              </IconButton>
              <StyledMenu
                anchorEl={this.state.anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={open}
                onClose={this.closeMenu}
              >
                {this.getMeetingActions()}
              </StyledMenu>
            </Grid>
          </Hidden>
        </Grid>
        {
          this.state.editMeetingDialog === true &&
          <ScheduleMeeting
            onCloseClick={this.onEditMeetingClose}
            meetingDetails={this.state.editMeetingDetails}
            dialogTitle={intl.formatMessage(messages.editMeeting)}
          />
        }
      </div >
    );
  }
}


export default withStyles(style)(injectIntl((withRouter(MeetingDetails))));
