import React from 'react';

import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Slide from '@material-ui/core/Slide';

import { State } from '../../lib/reducers';
import { isRecorder } from '../../lib/reduxSelectors/session';
import { ScreenSharingOptions } from '../../lib/redux_types';
import { getMemoizedDeskControlledUser } from '../Meeting/reduxSelectors';
import { ScreenSourceType } from '../../lib/redux_types';
import {
  amWebinarPresenter,
  isWebinarLayout,
  isLessonLayout,
  amModerator,
} from '../../lib/reduxSelectors/room';

import MicButton from './MicButton';
import VideoButton from './VideoButton';
import ShareScreenButton from './ShareScreenButton';
import InviteButton from './InviteButton';
import ExitButton from './ExitButton';
import DesktopControlButton from './DesktopControlButton';
import { FirstTimePopoverDialog, LocalStorageConfigBackend } from '../PopoverDialog';
import messages from './buttonsMessages';
import { isMobile, isElectron } from 'react-device-detect';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      zIndex: theme.zIndex.drawer,
      height: '90px', // TODO
    },
    paper: {
      position: 'absolute',
      top: 'auto',
      left: 0,
      bottom: 0,
      right: 0,
      height: 'auto',
      maxHeight: '100%',
      borderTop: `1px solid ${theme.palette.divider}`,
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 auto',
      zIndex: theme.zIndex.drawer,
      WebkitOverflowScrolling: 'touch',
      outline: 0,
      opacity: '0.8',
    },
    toolbar: {
      padding: '1em',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: '0.5em',
      },
    },
    buttons: {
      [theme.breakpoints.down('sm')]: {
        marginLeft: '0.2ex',
        marginRight: '0.2ex',
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: '2em',
        marginRight: '2em',
      },
    }
  })
);


function canIUnmute(props: ExtendedProps) {
  if (props.isLessonLayout) {
    return props.amIModerator;
  }
  else if (props.isWebinarLayout) {
    return props.amIModerator;
  }
  return true;
}


function OwnMeetingControls(props: ExtendedProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();
  const canSendVideo = props.isWebinarLayout ? props.amWebinarPresenter : true;
  const canUnmute = canIUnmute(props);

  const hideControlsTimeout = 3000;
  const easeTimeout = 500;

  const popoverConfigKey = 'videoToolbar';

  const [popoverDismissed, setPopoverDismissed] = React.useState(false);
  React.useEffect(() => {
    const ls = new LocalStorageConfigBackend();
    // FIXME: this component shouldn't know how to build the key
    setPopoverDismissed(ls.isDismissed(`FirstTimePopoverDialog::${popoverConfigKey}`));
  }, [setPopoverDismissed]
  );

  const [shown, setShown] = React.useState(!popoverDismissed);
  const preventHide = React.useRef(false);
  const timerRef = React.useRef<null | ReturnType<typeof setTimeout>>(null);

  const hideControlsWhenIdle = React.useCallback(
    () => {
      if (!popoverDismissed) {
        return;
      }
      else {
        timerRef.current = setTimeout(
          () => {
            if (shown && !preventHide.current) {
              setShown(false);
            }
            if (timerRef.current) {
              timerRef.current = null;
            }
          }
          , hideControlsTimeout
        );
      }
    }
    , [shown, timerRef, hideControlsTimeout, popoverDismissed]
  );

  // cleanup timers on unmount, to avoid trying to set state on an unmounted
  // component
  React.useEffect(() => {
    hideControlsWhenIdle();
    return function cleanup() {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  });

  const dismissPopover = React.useCallback(
    () => {
      setPopoverDismissed(true);
      preventHide.current = false;
    }
    , [setPopoverDismissed]
  );

  const onMouseMoved = React.useCallback(
    () => {
      if (!shown) {
        setShown(true);
      }
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      hideControlsWhenIdle();
    }
    , [shown, timerRef, hideControlsWhenIdle]
  );

  const onMouseEntered = React.useCallback(
    () => {
      if (!preventHide.current) preventHide.current = true;
    }
    , []
  );

  const onMouseLeft = React.useCallback(
    () => {
      if (preventHide.current) preventHide.current = false;
    }
    , []
  );

  let sharingConstraints: undefined | ScreenSharingOptions = undefined;
  if (props.isLessonLayout && !props.amIModerator) {
    sharingConstraints = {
      frameRate: 1,
    };
  }

  if (props.isRecorder) return null;

  return (
    <div
      className={classes.container}
      onMouseMove={onMouseMoved}
    >
      <Slide
        in={shown}
        direction='up'
        timeout={easeTimeout}
        appear={false}
      >
        <Paper
          elevation={0}
          square
          className={classes.paper}
          onMouseEnter={onMouseEntered}
          onMouseLeave={onMouseLeft}
        >
          <FirstTimePopoverDialog
            message={formatMessage(messages.manageYourControlsHere)}
            configKey={popoverConfigKey}
            onDismiss={dismissPopover}
          >
            <Toolbar 
              canSendVideo={canSendVideo}
              canUnmute={canUnmute}
              sharingConstraints={sharingConstraints}
              screenSourceType={props.screenSourceType}
              someoneHasDesktopControl={props.someoneHasDesktopControl}
            />
          </FirstTimePopoverDialog>
        </Paper>
      </Slide>
    </div>
  );
}


function Toolbar(props: {
  canSendVideo: boolean;
  canUnmute: boolean;
  sharingConstraints?: ScreenSharingOptions;
  screenSourceType: ScreenSourceType;
  someoneHasDesktopControl: boolean;
}) {
  const classes = useStyles();
  const canEnableDesktopControl = isElectron && props.screenSourceType === 'screen' && !props.someoneHasDesktopControl;

  return (
    <div className={classes.toolbar}>
      { props.canUnmute && <MicButton /> }
      { props.canSendVideo && props.canUnmute && <VideoButton /> }
      <ExitButton />
      { (props.canSendVideo && !isMobile) && <ShareScreenButton constraints={props.sharingConstraints}/> }
      <InviteButton />
      { (canEnableDesktopControl && <DesktopControlButton /> )}
    </div>
  );
}

type Props = {} & RouteComponentProps

type ExtendedProps = Props & MappedProps;


type MappedProps = {
  isRecorder: boolean;
  amWebinarPresenter: boolean;
  isWebinarLayout: boolean;
  screenSourceType: ScreenSourceType;
  someoneHasDesktopControl: boolean;
  isLessonLayout: boolean;
  amIModerator: boolean;
}


const mapStateToProps = (state: State, { match }: Props): MappedProps => {
  const controlledUser = getMemoizedDeskControlledUser(state);
  const myUser = state.websocket.uid;

  return {
    isRecorder: isRecorder(state, match.url),
    amWebinarPresenter: amWebinarPresenter(state),
    amIModerator: amModerator(state),
    isWebinarLayout: isWebinarLayout(state),
    screenSourceType: state.room.screenSourceType,
    someoneHasDesktopControl: controlledUser !== undefined && controlledUser !== myUser,
    isLessonLayout: isLessonLayout(state),
  };
};


export default withRouter(connect(mapStateToProps)(OwnMeetingControls));
