/* Libraries Imports */
import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import CopyToClipboard from 'react-copy-to-clipboard';
import classNames from 'classnames';
/* Components Imports */
import ClipboardIcon from '../../style/ClipboardIcon';
import IconButton from '@material-ui/core/IconButton';
import TooltipMultiLineText from '../TooltipMultiLineText';
/* Other Imports */
import { linkify } from '../../lib/utils';
/* Local Style */
import useStyles from './style';


const messages = defineMessages({
  roomWebAccess: { id: 'roomInfoWebAccess' },
  copyToClipboard: { id: 'copyToClipboard' },
  copiedToClipboard: { id: 'copiedToClipboard' },
});


type Props = {
  rawUrl: string;
}


function ConfWebAccess(props: Props) {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const [linkCopied, setLinkCopied] = React.useState<boolean>(false);

  let tooltipTimeoutHandler: NodeJS.Timeout | null = null;

  React.useEffect(() => {
    return () => {
      if (tooltipTimeoutHandler) {
        clearTimeout(tooltipTimeoutHandler);
      }
    };
  });

  const onLinkCopied = (): void => {
    setLinkCopied(true);
  };

  const handleClipboardTooltipClose = () => {
    // ugly, but avoid re-rendering the normal tooltip text just before closing it
    tooltipTimeoutHandler = setTimeout(() => {
      setLinkCopied(false);
      tooltipTimeoutHandler = null;
    },
    500);
  };

  const getConfWebAccessComponent = (rawUrl: string) => {
    const url = linkify(rawUrl, '_blank', 25);
    let cboardTtipText = formatMessage(messages.copyToClipboard);
    if (linkCopied) {
      cboardTtipText = formatMessage(messages.copiedToClipboard);
    }
    return (
      <div>
        <div className={classes.title}>
          {formatMessage(messages.roomWebAccess)}
        </div>
        <div className={classes.webAccessContainer}>
          <div className={classNames(classes.content, classes.linkContent)}>
            {url}
          </div>
          <div>
            <IconButton className={classes.iconContainer}>
              <TooltipMultiLineText
                placement="top"
                title={cboardTtipText}
                onClose={handleClipboardTooltipClose}
              >
                <CopyToClipboard
                  onCopy={onLinkCopied}
                  text={rawUrl}>
                  <ClipboardIcon />
                </CopyToClipboard>
              </TooltipMultiLineText>
            </IconButton>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {getConfWebAccessComponent(props.rawUrl)}
    </div>
  );

}





export default ConfWebAccess;