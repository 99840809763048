import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { iconColors } from '../../colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    screenButton: {
      backgroundColor: iconColors.contrast,
      width: theme.spacing(3),
      height: theme.spacing(3),
      '&:hover': {
        backgroundColor: iconColors.contrast
      },
      '&:disabled': {
        backgroundColor: iconColors.contrast
      }
    },
    badgeContent: {
      border: `1px solid ${theme.palette.grey.A100}`,
      borderRadius: '50%'
    },
    badge: {
      marginRight: theme.spacing(0.5)
    }
  }),
);


export default useStyles;