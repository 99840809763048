/* Libraries Imports */
import React, { Component, Fragment } from 'react';
import { injectIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { ReactRelayContext } from 'relay-hooks';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import moment from 'moment';
/* UI Imports */
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import Typography from '@material-ui/core/Typography';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import ClosableDialog from '../ClosableDialog';
import { IconCalendarNewEvent, IconCalendar, IconChevronDown, IconChevronLeft, IconChevronRight } from '../IconSet';
/* Components Imports */
import ListAdder from '../ListAdder';
import Spinner from '../Spinner';
/* Other Imports */
import IntlPropType from '../../intl';
import { scheduleMeeting } from '../../lib/api/relay/scheduleMeeting';
import { updateMeeting } from '../../lib/api/relay/updateMeeting';
import { isEmail } from '../../lib/utils';
import { relayOperation, SCHEDULE_MEETING } from '../../lib/utils/relayOperation';
import { generateRequestId } from '../../lib/utils/requestId';
/* Local Style */
import style from './style';

const messages = defineMessages({
  ok: { id: 'ok' },
  close: { id: 'close' },
  cancel: { id: 'cancel' },
  dateFormat: { id: 'dateFormat' },
  scheduleMeeting: { id: 'scheduleMeeting' },
  scheduleMeetingPlaceHolder: { id: 'scheduleMeetingPlaceHolder' },
  startMeeting: { id: 'startMeeting' },
  endMeeting: { id: 'endMeeting' },
  email: { id: 'email' },
  save: { id: 'save' },
  titleMeeting: { id: 'titleMeeting' },
  titleMeetingError: { id: 'titleMeetingError' },
  notes: { id: 'notes' },
  notesPlaceHolder: { id: 'notesAdd' },
  particpantsAdd: { id: 'particpantsAdd' },
  scheduleHour: { id: 'scheduleHour' },
  sendInvite: { id: 'sendInvite' },
  attendeesError: {
    email: {
      id: 'attendeesErrorEmail'
    },
    required: {
      id: 'attendeesErrorRequired'
    },
  },
  dtError: {
    too_long: {
      id: 'dtErrorTooLong'
    },
    too_brief: {
      id: 'dtErrorTooBrief'
    },
    invalid_format: {
      id: 'dtErrorInvalidFormat'
    }
  },
});

class ScheduleMeeting extends Component {

  static contextType = ReactRelayContext;

  constructor(props) {
    super(props);
    const now = moment();
    const now_hour = now.get('hour');
    const now_minutes = now.get('minutes');
    let selectedDay = null;
    let selectedDayEnd = null;

    if (props.selectedDay) {
      selectedDay = props.selectedDay.clone();
      selectedDay.set('minute', now_minutes);
      selectedDay.set('hour', now_hour);
      selectedDayEnd = this.addMinutes(selectedDay, 60);
    }
    this.state = {
      users: { error: false, errorType: null, value: props.meetingDetails.users },
      title: { error: false, errorType: null, value: props.meetingDetails.title },
      dt_start: { error: false, errorType: null, value: props.meetingDetails.dtStart || selectedDay || now },
      dt_end: {
        error: false, errorType: null, value: props.meetingDetails.dtEnd ||
          selectedDayEnd || this.addMinutes(now, 60)
      },
      notes: props.meetingDetails.notes,
      meeting_id: props.meetingDetails.meetingId,
      openSpinner: false,
      selectValue: 10
    };

    this.handleChangedtStart = this.handleChangedtStart.bind(this);
    this.handleChangedtTimeEnd = this.handleChangedtTimeEnd.bind(this);
    this.handleChangedtTimeStart = this.handleChangedtTimeStart.bind(this);
    this.handleChangedtEnd = this.handleChangedtEnd.bind(this);
    this.handleErrorDtEnd = this.handleErrorDtEnd.bind(this);
    this.handleErrorDtStart = this.handleErrorDtStart.bind(this);
    this.handleChangeTitleMeeting = this.handleChangeTitleMeeting.bind(this);
    this.handleChangeNotes = this.handleChangeNotes.bind(this);
    this.addUser = this.addUser.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
  }

  handleChangeTitleMeeting(event) {
    this.setState({
      title: update(this.state.title, { error: { $set: false }, value: { $set: event.target.value } })
    });
  }

  handleErrorDtEnd(error) {
    if (error) {
      this.setState({
        dt_end: update(this.state.dt_end, { error: { $set: true }, errorType: { $set: "invalid_format" } })
      });
    }
    return;
  }

  handleErrorDtStart(error) {
    if (error) {
      this.setState({
        dt_start: update(this.state.dt_start, { error: { $set: true }, errorType: { $set: "invalid_format" } })
      });
    }
    return;
  }

  handleChangedtTimeEnd(event) {
    if (event) {
      if (!moment(event).isValid()) {
        this.setState({
          dt_end: update(this.state.dt_end, { error: { $set: true }, errorType: { $set: "invalid_format" } })

        });
        return;
      }
      const edit_hour = event.get('hour');
      const edit_minutes = event.get('minutes');

      let new_end = this.state.dt_end.value.clone();
      new_end.set('minute', edit_minutes);
      new_end.set('hour', edit_hour);
      let dt_start = this.state.dt_start.value;
      if (new_end.isBefore(dt_start)) {
        dt_start = this.delMinutes(new_end, 60);
      }
      this.setState({
        dt_start: update(this.state.dt_start,
          { error: { $set: false }, errorType: { $set: null }, value: { $set: dt_start } }),
        dt_end: update(this.state.dt_end, {
          error: { $set: false },
          errorType: { $set: null }, value: { $set: new_end }
        })
      });
    }
  }

  handleChangedtTimeStart(event) {
    if (event) {

      if (!moment(event).isValid()) {
        this.setState({
          dt_start: update(this.state.dt_start, { error: { $set: true }, errorType: { $set: "invalid_format" } })

        });
        return;
      }

      let dt_end = this.state.dt_end.value;
      const edit_hour = event.get('hour');
      const edit_minutes = event.get('minutes');

      let new_start_date = this.state.dt_start.value.clone();
      new_start_date.set('minute', edit_minutes);
      new_start_date.set('hour', edit_hour);

      if (!new_start_date.isBefore(dt_end)) {
        dt_end = this.addMinutes(new_start_date, 60);
      }
      this.setState({
        dt_start: update(this.state.dt_start, {
          error: { $set: false },
          errorType: { $set: null }, value: { $set: new_start_date }
        }),
        dt_end: update(this.state.dt_end, {
          error: { $set: false }, errorType: { $set: null }, value: { $set: dt_end }
        })
      });
    }
  }

  handleChangedtStart(event) {
    if (event) {
      let dt_end = this.state.dt_end.value;
      if (!moment(event).isValid()) {
        this.setState({
          dt_start: update(this.state.dt_start, {
            error: { $set: true },
            errorType: { $set: "invalid_format" }
          })

        });
        return;
      }

      let old_start_date = this.state.dt_start.value.clone();
      const edit_hour = old_start_date.get('hour');
      const edit_minutes = old_start_date.get('minutes');

      let new_start_date = event.clone();
      new_start_date.set('minute', edit_minutes);
      new_start_date.set('hour', edit_hour);

      if (!new_start_date.isBefore(dt_end)) {
        dt_end = this.addMinutes(new_start_date, 60);
      }
      this.setState({
        dt_start: update(this.state.dt_start, {
          error: { $set: false },
          errorType: { $set: null }, value: { $set: new_start_date }
        }),
        dt_end: update(this.state.dt_end, {
          error: { $set: false }, errorType: { $set: null }, value: { $set: dt_end }
        })
      });
    }
  }

  handleChangedtEnd(event) {
    if (event) {

      if (!moment(event).isValid()) {
        this.setState({
          dt_end: update(this.state.dt_end, { error: { $set: true }, errorType: { $set: "invalid_format" } })

        });
        return;
      }

      let old_end_date = this.state.dt_end.value.clone();
      const edit_hour = old_end_date.get('hour');
      const edit_minutes = old_end_date.get('minutes');

      let new_end_date = event.clone();
      new_end_date.set('minute', edit_minutes);
      new_end_date.set('hour', edit_hour);

      let dt_start = this.state.dt_start.value;
      if (new_end_date.isBefore(dt_start)) {
        dt_start = this.delMinutes(new_end_date, 60);
      }
      this.setState({
        dt_start: update(this.state.dt_start, {
          error: { $set: false },
          errorType: { $set: null }, value: { $set: dt_start }
        }),
        dt_end: update(this.state.dt_end, {
          error: { $set: false },
          errorType: { $set: null }, value: { $set: new_end_date }
        })
      });
    }
  }

  handleChangeNotes(event) {
    this.setState({
      notes: update(this.state.notes, { $set: event.target.value })
    });
  }

  addMinutes(date, minutes) {
    return moment(date + (minutes * 60 * 1000));
  }

  delMinutes(date, minutes) {
    return moment(date - (minutes * 60 * 1000));
  }

  addUser(item) {
    this.setState({
      users: update(this.state.users, { error: { $set: false }, value: { $push: [item] } })
    });
  }

  deleteUser(index) {
    this.setState({
      users: update(this.state.users, { value: { $splice: [[index, 1]] } })
    });
  }

  isDuplicateEmail(v) {
    let found = false;
    if (this.state.users.value.length > 0) {
      this.state.users.value.forEach((users) => {
        if (v === users) {
          found = true;
        }
      });
    }
    return found;
  }

  validateTitleMeetingForm() {
    if (this.state.title.value.length > 0) {
      return true;
    } else {
      this.setState({
        title: update(this.state.title, { error: { $set: true }, errorType: { $set: "empty" } })
      });
      return false;
    }
  }

  validateUsers() {
    if (this.state.users.value.length > 0) {
      return true;
    } else {
      this.setState({
        users: update(this.state.users, { error: { $set: true }, errorType: { $set: "required" } })
      });
      return false;
    }
  }


  validateForm() {
    const isTitleMeetingFormValid = this.validateTitleMeetingForm();
    const areUsersValid = this.validateUsers();
    const dtStartValid = !this.state.dt_start.error;
    const dtEndValid = !this.state.dt_end.error;
    if (isTitleMeetingFormValid && areUsersValid && dtEndValid && dtStartValid) {
      return true;
    } else {
      return false;
    }
  }

  handleCloseSpinner() {
    this.setState({ openSpinner: false });
  }

  handleOpenSpinner() {
    this.setState({ openSpinner: true });
  }

  getMeetingParams() {
    const { title, dt_start, dt_end, users, notes, meeting_id } = this.state;
    const { userId, domainId } = this.props;
    return {
      title: title.value,
      dtStart: moment(dt_start.value),
      dtEnd: moment(dt_end.value),
      users: users,
      userId: userId,
      domainId: domainId,
      notes: notes ? notes : null,
      id: meeting_id
    };
  }

  sendInvite() {
    const meetingParams = this.getMeetingParams();
    const relayEnvironment = this.context.environment;

    if (meetingParams.id === null) {
      return scheduleMeeting(relayEnvironment, meetingParams)
        .then((res) => {
          const { errors } = res.scheduleMeeting;
          if (errors) {
            errors.forEach((form) => {
              this.setState({
                [form.key]: update(
                  this.state[form.key],
                  { error: { $set: true }, errorType: { $set: form.reason[0] } }
                )
              });
            });
          } else {
            this.props.onCloseClick();
          }
        })
        .catch(() => { });
    } else {
      return updateMeeting(relayEnvironment, meetingParams)
        .then((res) => {
          const { errors } = res.updateMeeting;
          if (errors) {
            errors.forEach((form) => {
              this.setState({
                [form.key]: update(
                  this.state[form.key],
                  { error: { $set: true }, errorType: { $set: form.reason[0] } }
                )
              });
            });
          } else {
            this.props.onCloseClick();
          }
        })
        .catch(() => { });
    }
  }

  handleSendInvite = () => {
    if (this.validateForm()) {
      return relayOperation(this.props.dispatch, () => this.sendInvite(), generateRequestId(), SCHEDULE_MEETING);
    }

  }

  handleChange = (ev) => {
    this.setState({ selectValue: ev.target.value });
  }

  emailValidator = (email) => {
    return (!this.isDuplicateEmail(email) && isEmail(email)) || email.length === 0;
  }

  render() {
    const classes = this.props.classes;
    const fullScreen = this.props.fullScreen;
    const dialogTitle = this.props.dialogTitle || this.props.intl.formatMessage(messages.scheduleMeeting);
    return (
      <div>
        <Fragment>
          <ClosableDialog
            open={true}
            disableBackdropClick
            maxWidth='md'
            fullWidth={true}
            onClose={this.props.onCloseClick}
            onEscapeKeyDown={this.props.onCloseClick}
            fullScreen={fullScreen}
          >
            {this.state.openSpinner &&
              <Spinner />
            }
            <DialogContent className={classes.dialogContent}>
              <form noValidate>
                <Grid container direction='row'
                  alignItems='flex-start'
                  justify='space-between'
                  spacing={1}
                >
                  <Grid item xs={12} sm={12}>
                    <Box pb={1} flexGrow={1} display="flex" alignItems='center'
                      alignContent='center'>
                      <Box pb={1}>
                        <IconCalendarNewEvent size={96} />
                      </Box>
                      <Box flexGrow={1} pb={1} px={2}>
                        <Typography variant="h5">
                          {dialogTitle}
                        </Typography>
                      </Box>
                    </Box>

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl className={classes.formControl} error={this.state.title.error}>
                      <InputLabel>{this.props.intl.formatMessage(messages.titleMeeting)}</InputLabel>
                      <Input
                        autoFocus
                        id="title"
                        variant="text"
                        placeholder={this.props.intl.formatMessage(messages.scheduleMeetingPlaceHolder)}
                        defaultValue={this.state.title.value}
                        onChange={this.handleChangeTitleMeeting}
                        className={classes.textField}
                      />
                      {this.state.title.error &&
                        <FormHelperText>
                          {this.props.intl.formatMessage(messages.titleMeetingError)}
                        </FormHelperText>
                      }
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl className={classes.formControl} error={this.state.dt_start.error}>
                      <KeyboardDatePicker
                        disableToolbar={true}
                        disablePast={true}
                        autoOk={true}
                        mask="__/__/____"
                        leftArrowIcon={<IconChevronLeft size={24} />}
                        rightArrowIcon={<IconChevronRight size={24} />}
                        format="L"
                        label={this.props.intl.formatMessage(messages.startMeeting)}
                        value={this.state.dt_start.value}
                        keyboardIcon={<IconCalendar size={24} />}
                        onChange={this.handleChangedtStart}
                        onError={this.handleErrorDtStart}
                        okLabel={this.props.intl.formatMessage(messages.ok)}
                        cancelLabel={this.props.intl.formatMessage(messages.cancel)}
                      />
                      {this.state.dt_start.error &&
                        <div>
                          <FormHelperText className={classes.formError}>
                            {this.props.intl.formatMessage(messages.dtError[this.state.dt_start.errorType])}
                          </FormHelperText>
                        </div>
                      }
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl className={classes.formControl} error={this.state.dt_start.error}>
                      <KeyboardTimePicker
                        clearable={false}
                        ampm={false}
                        mask="__:__"
                        label={this.props.intl.formatMessage(messages.scheduleHour)}
                        value={this.state.dt_start.value}
                        onChange={this.handleChangedtTimeStart}
                        keyboardIcon={<IconChevronDown size={24} />}
                        okLabel={this.props.intl.formatMessage(messages.ok)}
                        cancelLabel={this.props.intl.formatMessage(messages.cancel)}
                      />
                      {this.state.dt_start.error &&
                        <div>
                          <FormHelperText className={classes.formError}>
                            {this.props.intl.formatMessage(messages.dtError[this.state.dt_start.errorType])}
                          </FormHelperText>
                        </div>
                      }
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div>
                      <FormControl className={classes.formControl}>
                        <InputLabel>{this.props.intl.formatMessage(messages.notes)}</InputLabel>
                        <Input
                          id="text"
                          variant="text"
                          placeholder={this.props.intl.formatMessage(messages.notesPlaceHolder)}
                          multiline
                          rows="3"
                          rowsMax="4"
                          defaultValue={this.state.notes}
                          onChange={this.handleChangeNotes}
                          className={classes.textField}
                        />
                      </FormControl>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl className={classes.formControl} error={this.state.dt_end.error}>
                      <KeyboardDatePicker
                        disableToolbar={true}
                        disablePast={true}
                        autoOk={true}
                        mask="__/__/____"
                        leftArrowIcon={<IconChevronLeft size={24} />}
                        rightArrowIcon={<IconChevronRight size={24} />}
                        format="L"
                        label={this.props.intl.formatMessage(messages.endMeeting)}
                        value={this.state.dt_end.value}
                        onChange={this.handleChangedtEnd}
                        keyboardIcon={<IconCalendar size={24} />}
                        onError={this.handleErrorDtEnd}
                        okLabel={this.props.intl.formatMessage(messages.ok)}
                        cancelLabel={this.props.intl.formatMessage(messages.cancel)}
                      />
                      {this.state.dt_end.error &&
                        <div>
                          <FormHelperText className={classes.formError}>
                            {this.props.intl.formatMessage(messages.dtError[this.state.dt_end.errorType])}
                          </FormHelperText>
                        </div>
                      }
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <FormControl className={classes.formControl} error={this.state.dt_end.error}>
                      <KeyboardTimePicker
                        clearable={false}
                        mask="__:__"
                        ampm={false}
                        label={this.props.intl.formatMessage(messages.scheduleHour)}
                        value={this.state.dt_end.value}
                        onChange={this.handleChangedtTimeEnd}
                        keyboardIcon={<IconChevronDown size={24} />}
                        okLabel={this.props.intl.formatMessage(messages.ok)}
                        cancelLabel={this.props.intl.formatMessage(messages.cancel)}
                      />
                      {this.state.dt_end.error &&
                        <div>
                          <FormHelperText className={classes.formError}>
                            {this.props.intl.formatMessage(messages.dtError[this.state.dt_end.errorType])}
                          </FormHelperText>
                        </div>
                      }
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <div>

                      <FormControl className={classes.formControl} error={this.state.users.error}>
                        <ListAdder
                          items={this.state.users.value}
                          textFieldLabel={this.props.intl.formatMessage(messages.particpantsAdd)}
                          placeholder={"Insert the email address to invite"}
                          onAdd={this.addUser}
                          onRemove={this.deleteUser}
                          invalidMessage={this.props.intl.formatMessage(messages.attendeesError.email)}
                          validator={this.emailValidator}
                        />
                        {this.state.users.error &&
                          <Typography className={classes.formError} variant="body2" gutterBottom align="center">
                            {this.props.intl.formatMessage(messages.attendeesError.required)}
                          </Typography>
                        }
                      </FormControl>
                    </div>
                  </Grid>
                </Grid>
              </form>

            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button ref={this.myRef}
                color="primary" variant="contained"
                onClick={this.handleSendInvite}>
                {this.props.intl.formatMessage(messages.save)}
              </Button>
            </DialogActions>
          </ClosableDialog>
        </Fragment>
      </div>
    );
  }

}


ScheduleMeeting.defaultProps = {
  dialogTitle: null,
  meetingDetails: {
    users: [],
    title: '',
    notes: '',
    dtStart: null,
    dtEnd: null,
    meetingId: null
  }
};

ScheduleMeeting.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: IntlPropType.isRequired,
  userId: PropTypes.string,
  domainId: PropTypes.string,
  onCloseClick: PropTypes.func.isRequired,
  meetingDetails: PropTypes.object,
  selectedDay: PropTypes.object,
  dialogTitle: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  fullScreen: PropTypes.bool.isRequired,

};


function mapStateToProps(state) {
  return {
    domainId: state.session.domainId,
    userId: state.session.userId
  };
}

export { ScheduleMeeting };
export default withStyles(style)(injectIntl(connect(mapStateToProps)(withMobileDialog()(ScheduleMeeting))));
