import auth from './auth';
import websocket from './websocket';
import room from './room';
import session from './session';
import settings from './settings';
// eslint-disable-next-line @typescript-eslint/camelcase
import invite_participants from './invite_participants';
import notifications from './notifications';
import relay from './relay';

import { State as WebsocketState, defaultState as websocketDefaultState } from './websocket';
import { State as AuthState, defaultState as authDefaultState } from './auth';
import { State as RoomState, defaultState as roomDefaultState } from './room';
import { State as SessionState, defaultState as sessionDefaultState } from './session';
import appconfig, { State as AppConfigState, defaultState as appConfigDefaultState } from './appconfig';


// FIXME: remove anys
export interface State {
  auth: AuthState;
  websocket: WebsocketState;
  room: RoomState;
  session: SessionState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  settings: any;
  // eslint-disable-next-line @typescript-eslint/camelcase, @typescript-eslint/no-explicit-any
  invite_participants: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  relay: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  notifications: any;
  appconfig: AppConfigState;
}


const defaultState = {
  auth: authDefaultState,
  websocket: websocketDefaultState,
  room: roomDefaultState,
  session: sessionDefaultState,
  // eslint-disable-next-line @typescript-eslint/camelcase
  invite_participants: undefined,
  settings: undefined,
  relay: undefined,
  notifications: undefined,
  appconfig: appConfigDefaultState,
};


// eslint-disable-next-line @typescript-eslint/no-explicit-any
function rootReducer(state: State | undefined, action: any) {
  if (!state) {
    state = defaultState;
  }
  return {
    auth: auth(state.auth, action),
    websocket: websocket(state.websocket, action),
    room: room(state.room, action),
    session: session(state.session, action),
    settings: settings(state.settings, action),
    // eslint-disable-next-line @typescript-eslint/camelcase
    invite_participants: invite_participants(state.invite_participants, action),
    relay: relay(state.relay, action),
    notifications: notifications(state.notifications, action),
    appconfig: appconfig(state.appconfig, action),
  };
}


export { rootReducer };
