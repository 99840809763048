import React from 'react';
import { connect } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { DialogContent, DialogActions, Button, TextField, Typography } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ClosableDialog from '../ClosableDialog';
import Input from './ChatInput';
import { getRoster } from '../../lib/reduxSelectors/roster';
import { State } from '../../lib/reducers';
import lodash from 'lodash';


const messages = defineMessages({
  dialogTitle: { id: 'newPrivateChat' },
  selectUser: { id: 'selectUser' },
  noOtherUsers: { id: 'noOtherUsers' },
  close: { id: 'close' },
  userInvalid: { id: 'userInvalid' },
});


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    user: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  })
);


function ItemUser(props: { name: string; username: string }) {
  const classes = useStyles();
  return (
    <div className={classes.user}>
      <Typography variant={"body2"}>{props.name}</Typography>
      <Typography color="textSecondary" variant={"caption"}>{props.username}</Typography>
    </div>
  );
}


type ValueType = string | string[] | null;


function SelectDestUserDialog(props: ExtendedProps) {
  const { formatMessage } = useIntl();

  const [ user, setUser ] = React.useState<ValueType>(null);
  const [ userId, setUserId ] = React.useState<string | undefined>(undefined);

  const getUsername = (username: string): string => {
    const guest = 'guest';
    return username.includes(guest) ? guest : username;
  };

  const others = Object.entries(props.roster)
    .filter(([k, v]) => k !== props.myself && !v.viaPhone)
    .map(([_k, v]) => [v.display, v.username, getUsername(v.username)]);

  const findUserId = (value: ValueType) => {
    const user = others.find((u) => u === value);
    if (user) {
      setUserId(user[1]);
    }
  };

  const onChange = (_event: object, value: ValueType, _reason: string) => {
    setUser(value);
    if (value) {
      findUserId(value);
    }
  };

  const disabled = user === null || !others.some(e => lodash.isEqual(e, user));
  const error = disabled && Boolean(user);

  return (
    <ClosableDialog
      open={true}
      disableBackdropClick
      onClose={props.onClose}
      fullWidth={false}
      title={formatMessage(messages.dialogTitle)}
    >
      <DialogContent>
        <Autocomplete
          id='user-selection'
          style={{ padding: '1ex' }}
          options={others}
          autoComplete
          autoHighlight
          size='small'
          onChange={onChange}
          noOptionsText={formatMessage(messages.noOtherUsers)}
          // eslint-disable-next-line react/jsx-no-bind
          getOptionLabel={(option) => option[0]}
          // eslint-disable-next-line react/jsx-no-bind
          getOptionSelected={(option, value) => option[0] === value[0]}
          // eslint-disable-next-line react/jsx-no-bind
          renderOption={(option) => ( <ItemUser name={option[0]} username={option[2]} /> )}
          // eslint-disable-next-line react/jsx-no-bind
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={formatMessage(messages.selectUser)}
              fullWidth
              inputProps={{ ...params.inputProps, autoComplete: 'off' }}
              error={error}
              label={error ? formatMessage(messages.userInvalid) : null}
            />
          )}
        />
        <Input to={userId} disableFocus disabled={disabled} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} variant="contained" color="primary">
          {formatMessage(messages.close)}
        </Button>
      </DialogActions>
    </ClosableDialog>
  );
}


type Props = {
  onClose: () => void;
}

type MappedProps = {
  myself: string | null;
  roster: ReturnType<typeof getRoster>;
}

type ExtendedProps = Props & MappedProps

const mapStateToProps = (state: State): MappedProps => ({
  myself: state.websocket.uid,
  roster: getRoster(state)
});


export default connect(mapStateToProps)(SelectDestUserDialog);
