import { fetchQuery, IEnvironment, GraphQLTaggedNode } from 'relay-runtime';
import { Dispatch } from 'redux';
import { setTracedUser } from '../sentry';

import { sessionDetailsQuery } from '../api/relay/__generated__/sessionDetailsQuery.graphql';
import { getSession, SessionDetails } from '../api/relay/sessionDetails';

export const SESSIONDETAILS_REQUEST = 'SESSIONDETAILS_REQUEST';
export const SESSIONDETAILS_FAILURE = 'SESSIONDETAILS_FAILURE';
export const SESSIONDETAILS_SUCCESS = 'SESSIONDETAILS_SUCCESS';
export const SESSIONDETAILS_CLEAR = 'SESSIONDETAILS_CLEAR';
export const SESSION_SETRECORDER = 'SESSION_SETRECORDER';


export type Session = SessionDetails;


interface SessionDetailsAction {
  type: typeof SESSIONDETAILS_REQUEST;
  payload: {};
}


interface SessionDetailsFailureAction {
  type: typeof SESSIONDETAILS_FAILURE;
  payload: {
    errorMessage: string;
  };
  error: boolean;
}


interface SessionDetailsSuccessAction {
  type: typeof SESSIONDETAILS_SUCCESS;
  payload: {
    session: Session;
  };
}


interface SessionDetailsClearAction {
  type: typeof SESSIONDETAILS_CLEAR;
  payload: {};
}


interface SessionSetRecoredAction {
  type: typeof SESSION_SETRECORDER;
  payload: {
    is_recorder: boolean;
  };
}


export type Action =
  SessionDetailsAction
  | SessionDetailsFailureAction
  | SessionDetailsSuccessAction
  | SessionSetRecoredAction
  | SessionDetailsClearAction


function requestSessionDetails(): SessionDetailsAction {
  return {
    type: SESSIONDETAILS_REQUEST,
    payload: {
    }
  };
}


function errorSessionDetails(errorMessage: string): SessionDetailsFailureAction {
  return {
    type: SESSIONDETAILS_FAILURE,
    payload: {
      errorMessage: errorMessage
    },
    error: true
  };
}


function successSessionDetails(sessionDetails: Session): (
  SessionDetailsSuccessAction | SessionDetailsFailureAction) {
  if (sessionDetails) {
    return {
      type: SESSIONDETAILS_SUCCESS,
      payload: {
        session: sessionDetails
      }
    };
  }
  else {
    return errorSessionDetails("null session");
  }
}


function traceUser(session: Session) {
  if (session) {
    setTracedUser(session.username + "@" + session.realm, session.username);
  }
}


export function setRecorder(isRecorder: boolean): SessionSetRecoredAction {
  return {
    type: SESSION_SETRECORDER,
    payload: {
      // eslint-disable-next-line @typescript-eslint/camelcase
      is_recorder: isRecorder
    }
  };
}


export function fetchSessionDetails(relay: IEnvironment, session: GraphQLTaggedNode) {
  return (dispatch: Dispatch<Action>) => {
    dispatch(requestSessionDetails());
    fetchQuery<sessionDetailsQuery>(relay, session, {})
      .then((res) => {
        const session = getSession(res);
        traceUser(session);
        dispatch(successSessionDetails(session));
      })
      .catch((err) => dispatch(errorSessionDetails(err.detail)));
  };
}
