import React  from 'react';
import { connect, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import getRouteFor, { Route } from '../../lib/utils/router';
import { State } from '../../lib/reducers';
import { loginGuest } from '../../lib/actions/auth';
import Cookies from 'js-cookie';


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: 0,
      flexWrap: 'nowrap',
    },
    loginLink: {
      textDecoration: 'none',
    },
  })
);


const messages = defineMessages({
  insertYourName: { id: 'guestLoginDialogTitle' },
  clickHereToLogin: { id: 'clickHereToLogin' },
  join: { id: 'joinButton' },
});


function GuestLoginField(props: ExtendedProps) {
  const { onJoin, match, loginEnabled } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const prevDisplayName = Cookies.get('_holocom_guest_display');
  const [displayName, setDisplayName] = React.useState(prevDisplayName);
  const [canJoin, setCanJoin] = React.useState(Boolean(prevDisplayName));

  const meetingId = match.params.id;
  const loginQs = meetingId ? `redirectTo=${meetingId}` : undefined;
  const loginRef = getRouteFor(Route.Login, {}, loginQs);


  const handleJoin = React.useCallback(
    () => {
      if (canJoin) {
        dispatch(loginGuest(displayName));
        onJoin();
      }
    }
    , [canJoin, displayName, onJoin, dispatch]
  );

  const handleChange = React.useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      const dname = ev.target.value;
      setDisplayName(dname);
      setCanJoin(dname.length > 0);
    }
    , [setDisplayName, setCanJoin]
  );

  const handleKeyUp = React.useCallback(
    (ev: React.KeyboardEvent<HTMLInputElement>) => {
      if ((ev.key === 'Enter') && (canJoin)) {
        handleJoin();
      }
    }
    , [handleJoin, canJoin]
  );

  return (
    <>
      <TextField
        inputProps={{ autoCapitalize: 'off' }}
        autoFocus={true}
        fullWidth
        placeholder={formatMessage(messages.insertYourName)}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        defaultValue={prevDisplayName}
      />
      <DialogActions classes={{ spacing: classes.root }}>
        <Grid
          container
          direction='row'
          alignItems='center'
          justify={loginEnabled ? 'space-between' : 'flex-end'}
          className={classes.root}
        >
          {loginEnabled &&
            <Grid item>
              <a href={loginRef} className={classes.loginLink}>
                <Typography color='primary' variant='caption' gutterBottom align='center'>
                  {formatMessage(messages.clickHereToLogin)}
                </Typography>
              </a>
            </Grid>
          }
          <Grid item>
            <Button
              disabled={!canJoin}
              variant='contained'
              onClick={handleJoin}
              color='primary'
            >
              {formatMessage(messages.join)}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
}


type Props = {
  onJoin: () => void;
};


type MappedProps = {
  loginEnabled: boolean;
}


function mapStateToProps(state: State) {
  return {
    loginEnabled: state.appconfig.login_enabled,
  };
}


type ExtendedProps = Props & MappedProps & RouteComponentProps<{id: string}>


export default connect(mapStateToProps)(withRouter(GuestLoginField));
