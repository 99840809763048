import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { iconColors as colors } from '../../colors';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      borderColor: theme.palette.grey.A100,
      borderRadius: '50%',
      padding: theme.spacing(1.5),
      minWidth: theme.spacing(6),
      [theme.breakpoints.down('sm')]: {
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        padding: theme.spacing(1),
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: '2em',
        marginRight: '2em',
      },
      '&:hover': {
        backgroundColor: theme.palette.grey.A100,
      }
    },
    buttonsSelected: {
      borderColor: colors.contrast,
      backgroundColor: colors.inactive,
      '&:hover': {
        backgroundColor: colors.inactive,
      }
    },
    exitButton: {
      color: colors.attention,
      width: 28,
      height: 28
    },
    desktopControlButton: {
      width: 28,
      height: 28
    },
    desktopControlButtonSelected: {
      color: colors.contrast,
      width: 28,
      height: 28
    }
  })
);


export default useStyles;
