import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from '../actions/notifications';

const defaultState = {
  notifications: []
};

function notifications(state = defaultState, action = {}) {
  switch (action.type) {
    case ADD_NOTIFICATION: {
      let new_notifications = [];
      if (action.payload) {
        new_notifications = state.notifications.concat([
          Object.assign({}, action.payload)]);
      } else {
        new_notifications = state.notifications;
      }
      return Object.assign({}, state, {
        notifications: new_notifications
      });
    }
    case REMOVE_NOTIFICATION: {
      const payload = action.payload || {};
      return Object.assign({}, state, {
        notifications: state.notifications.filter(({ ref }) => {
          return payload.ref !== ref;
        }),
      });
    }
    default:
      return state;
  }
}

export default notifications;
export { defaultState };
