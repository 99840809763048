import React from 'react';
import { connect, useDispatch } from 'react-redux';

import sizeMe, { SizeMeProps } from 'react-sizeme';

import Fullscreen from './Fullscreen';
import { GridView } from './GridLayout';
import VideoElement from '../VideoElement';
import VideoToolbar from './VideoToolbar';
import usePublishersWatcher from './usePublishersWatcher';
import { usePrevious } from '../../lib/utils/hooks';
import {
  subscribeToVideo,
  subscribeToAllVideos,
  toggleAllVideoMute,
  changeVideoQuality,
} from '../../lib/actions/room';
import { VideoRoom } from '../../lib/api/videoroom';
import { getLogger } from '../../lib/logger';
import { VideoQualityOptions } from '../../lib/redux_types';
import { amModerator, getModerators } from '../../lib/reduxSelectors/room';
import { State } from '../../lib/reducers';
import { getStreams } from './StandardDimensionLayout';


function getOrderedStreamKeys(remoteVideoStreams: ExtendedProps['remoteVideoStreams']) {
  const getDisplayName = (uid: string) => {
    const stream = remoteVideoStreams[uid as keyof ExtendedProps['remoteVideoStreams']];
    return (stream || { displayName: '' }).displayName;
  };

  const keysAndNames = Object.keys(remoteVideoStreams).map(
    (uid) => [ uid, getDisplayName(uid) ]
  );
  keysAndNames.sort((a, b) => {
    const name1 = a[1].toLowerCase();
    const name2 = b[1].toLowerCase();
    if (name1 > name2) return 1;
    else if (name1 < name2) return -1;
    else return 0;
  });
  return keysAndNames.map(([a, _b]) => a);
}


function renderParticipants(
  props: ExtendedProps,
  participantsGrid: ReturnType<typeof getGridStyles>,
  observedScreens: Set<string>,
) {
  const { remoteVideoStreams, hasVideoStream, localVideoStream, myUserId, localScreenStream } = props;
  const keys = getOrderedStreamKeys(remoteVideoStreams);
  const participants: React.ReactNodeArray = [];
  let idx = 0;

  if (hasVideoStream && myUserId) {
    participants.push(
      <div key={idx} style={participantsGrid[idx]}>
        <Fullscreen user={myUserId}>
          <VideoElement
            user={myUserId}
            mirrored={true}
            addVideoMutedIconOverlay={true}
            src={localVideoStream}
          />
          <VideoToolbar uid={myUserId} />
        </Fullscreen>
      </div>
    );
    idx += 1;
  }

  if (localScreenStream && myUserId) {
    participants.push(
      <div key={idx} style={participantsGrid[idx]}>
        <Fullscreen user={`${myUserId}_screen`}>
          <VideoElement
            user={`${myUserId}_screen`}
            mirrored={false}
            addVideoMutedIconOverlay={true}
            src={localScreenStream}
          />
          <VideoToolbar uid={`${myUserId}_screen`} />
        </Fullscreen>
      </div>
    );
    idx += 1;
  }

  for (let i = 0; i < keys.length; i++) {
    const uid = keys[i];
    const video = remoteVideoStreams[uid as keyof ExtendedProps['remoteVideoStreams']];
    const userId = uid.replace(/_screen$/, '');
    if (uid.endsWith('_screen') && !observedScreens.has(userId)) {
      // we are not watching this screen
      continue;
    }
    if (!uid.endsWith('_screen') && observedScreens.has(userId)) {
      // we are watching user's screen, avoid rendering it
      continue;
    }
    if (video['stream']) {
      participants.push(
        <div key={idx} style={participantsGrid[idx]}>
          <Fullscreen user={uid}>
            <VideoElement
              user={uid}
              mirrored={false}
              addVideoMutedIconOverlay={true}
              src={video['stream']}
            />
            <VideoToolbar uid={uid} />
          </Fullscreen>
        </div>
      );
      idx += 1;
    }
    else {
      continue;
    }
  }
  return participants;
}


function getGridStyles(viewSize: SizeMeProps['size'], num: number) {
  const videoAspectRatio = 16 / 9;
  if (viewSize.width && viewSize.width > 0 && viewSize.height && viewSize.height > 0) {
    let gridSize = Math.ceil(Math.sqrt(num));
    gridSize = gridSize < 3 ? 3 : gridSize;

    let videoWidth = viewSize.width / gridSize;
    let videoHeight = videoWidth / videoAspectRatio;

    if (videoHeight * gridSize > viewSize.height) {
      // height is not enough to conatain all videos, constraint grid size by
      // height
      videoHeight = viewSize.height / gridSize;
      videoWidth = videoHeight * videoAspectRatio;
    }
    const res = [];

    // center grid on view
    const wOffset = Math.abs(gridSize * videoWidth - viewSize.width) / 2;
    const hOffset = Math.abs(gridSize * videoHeight - viewSize.height) / 2;

    let currentRow = 0;
    let currentCol = 0;
    for (let i = 1; i <= num; i++) {
      const el = {
        left: currentRow * videoWidth + wOffset,
        top: currentCol * videoHeight + hOffset,
        width: videoWidth,
        height: videoHeight,
        position: 'absolute' as React.CSSProperties["position"],
      };
      currentCol = currentRow + 1 < gridSize ? currentCol : (currentCol + 1) % gridSize;
      currentRow = (currentRow + 1) % gridSize;
      res.push(el);
    }
    return res;
  }
  else {
    return [];
  }
}


function calculateRemoteStreamsNumber(
  remoteVideoStreams: ExtendedProps['remoteVideoStreams'],
  observedScreens: Set<string>,
) {
  return Object.keys(remoteVideoStreams).reduce(
    (count, uid) => {
      const userId = uid.replace(/_screen$/, '');
      if (uid.endsWith('_screen') && !observedScreens.has(userId)) {
        // this is a screen we are not observing;
        return count;
      }
      if (!uid.endsWith('_screen') && observedScreens.has(userId)) {
        // this is a stream but we are observing his screen
        return count;
      }
      return count + 1;
    }, 0
  );
}


function ModeratorView(props: ExtendedProps & SizeMeProps) {
  const {
    remoteVideoStreams,
    size,
    layoutConfig,
  } = props;

  const observedScreens = layoutConfig.observedScreens || new Set();

  if (size.width && size.width > 0 && size.height && size.height > 0) {
    let participantsCount = calculateRemoteStreamsNumber(remoteVideoStreams, observedScreens);
    if (props.hasVideoStream) participantsCount += 1;
    if (props.localScreenStream) participantsCount += 1;
    const participantsGrid = getGridStyles(size, participantsCount);
    return (
      <div style={{ width: '100%', height: '100%' }}>
        {renderParticipants(props, participantsGrid, observedScreens)}
      </div>
    );
  }
  else {
    return null;
  }
}


const SizedModeratorView = sizeMe({ monitorHeight: true })(ModeratorView);


function UserView(props: ExtendedProps & SizeMeProps & { moderators: string[] }) {
  const dispatch = useDispatch();

  const { moderators, ...restOfProps } = props;

  React.useEffect(
    () => {
      moderators.forEach((uid) => {
        dispatch(subscribeToVideo(uid));
        dispatch(subscribeToVideo(`${uid}_screen`));
      });
      const allButModerators = (uid: string) => !moderators.includes(uid);
      dispatch(toggleAllVideoMute(true, allButModerators));
    }, [dispatch, moderators]
  );

  // filter out non moderators' streams
  const remoteStreams = Object.fromEntries(Object.entries(restOfProps.remoteVideoStreams)
    .filter((el) => moderators.includes(el[0].replace(/_screen$/, ''))));

  return (
    <GridView { ...{ ...restOfProps, remoteVideoStreams: remoteStreams } } />
  );
}


const SizedUserView  = sizeMe({ monitorHeight: true })(UserView);


function LessonLayout(props: ExtendedProps) {
  const { amIRoomModerator, roomModerators } = props;

  const wasIRoomModerator = usePrevious(amIRoomModerator, amIRoomModerator);

  const dispatch = useDispatch();

  if (amIRoomModerator && !wasIRoomModerator) {
    dispatch(subscribeToAllVideos());
  }

  const newPublishers = usePublishersWatcher();
  newPublishers.forEach((u) => {
    if (amIRoomModerator) {
      // if room moderator, automatically subscribe to everyone
      if (u.stream) {
        dispatch(subscribeToVideo(u.uid));
      }
      if (u.screen) {
        dispatch(subscribeToVideo(`${u.uid}_screen`));
      }
    }
    else {
      // if room user, only subscribe to moderators streams
      if (roomModerators.includes(u.uid)) {
        if (u.stream) {
          dispatch(subscribeToVideo(u.uid));
        }
        if (u.screen) {
          dispatch(subscribeToVideo(`${u.uid}_screen`));
        }
      }
    }
  });

  // no need to avoid useless calls since this api won't apply settings if no
  // change is needed
  if (amIRoomModerator) {
    const constraints: VideoQualityOptions = {
      streamQuality: props.configuredVideoQuality,
    };
    if (props.roomOptions.frame_rate) {
      constraints.frameRate = props.roomOptions.frame_rate;
    }
    dispatch(changeVideoQuality(constraints, getLogger('LessonLayout')));
  }
  else {
    const constraints: VideoQualityOptions = { streamQuality: '180p', frameRate: 5 };
    dispatch(changeVideoQuality(constraints, getLogger('LessonLayout')));
  }

  if (amIRoomModerator) {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <SizedModeratorView {...props} />
      </div>
    );
  }
  else {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <SizedUserView {...props} moderators={roomModerators} />
      </div>
    );
  }
}


type MappedProps = {
  amIRoomModerator: boolean;
  hasVideoStream: boolean;
  localVideoStream: State['room']['localvideo_stream'];
  localScreenStream: State['room']['screenStream'];
  remoteVideoStreams: ReturnType<typeof getStreams>;
  myUserId: State['websocket']['uid'];
  roomModerators: string[];
  layout: State['room']['layout'];
  layoutConfig: State['room']['layoutConfig'];
  configuredVideoQuality: undefined | VideoQualityOptions['streamQuality'];
  roomOptions: State['appconfig']['room_options'];
}


type ExtendedProps = {} & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  let hasVideoStream = false;
  if (state.room && state.room.localvideo_stream) {
    hasVideoStream = Boolean(VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream));
  }
  return {
    amIRoomModerator: amModerator(state),
    myUserId: state.websocket.uid,
    localVideoStream: state.room.localvideo_stream,
    localScreenStream: state.room.screenStream,
    remoteVideoStreams: getStreams(state),
    hasVideoStream,
    roomModerators: getModerators(state),
    layout: state.room.layout,
    layoutConfig: state.room.layoutConfig,
    configuredVideoQuality: state.settings.videoQuality ? state.settings.videoQuality.value : undefined,
    roomOptions: state.appconfig.room_options,
  };
};

export default connect(mapStateToProps)(LessonLayout);
