/* Libraries Imports */
import React from 'react';
import { useDispatch } from 'react-redux';
import { useRelayEnvironment } from 'relay-hooks';
import { useIntl, defineMessages } from 'react-intl';
/* Components Imports */
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
//import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
/* Other imports */
import ClosableDialog from '../ClosableDialog';
import { updateLivestreaming } from '../../lib/actions/user';
import {
  getLiveStreamingApiUrl,
  getLiveStreamingApiKey,
  getLiveStreamingPublishUrl
} from '../../lib/api/relay/myProfile';
import { myProfileQueryResponse } from '../../lib/api/relay/__generated__/myProfileQuery.graphql';
/* Style Import */
import useStyles from './style';

const messages = defineMessages({
  cancel: { id: 'cancel' },
  save: { id: 'save' },
  streamTitle: { id: 'streamingSettings' },
  apiUrl: { id: 'liveStreamingCmpApiUrl' },
  apiKey: { id: 'liveStreamingCmpApiKey' },
  pubUrl: { id: 'liveStreamingCmpPubUrl' },
  showApiKey: { id: 'liveStreamingCmpShowApiKey' },
});


type Props = {
  gqlError: Error | null;
  gqlProps: myProfileQueryResponse;
  onClose: () => void;
}

type NullableString = string | null;


function Settings(props: Props) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const relayEnvironment = useRelayEnvironment();

  const { gqlProps: myProfileQueryResponse } = props;

  const [showKey, setShowKey] = React.useState(false);
  const [apiUrlValid, setApiUrlValid] = React.useState(Boolean(getLiveStreamingApiUrl(myProfileQueryResponse)));
  const [apiKeyValid, setApiKeyValid] = React.useState(Boolean(getLiveStreamingApiKey(myProfileQueryResponse)));
  const [publishUrlValid, setPublishUrlValid] = React.useState(Boolean(getLiveStreamingPublishUrl(myProfileQueryResponse)));
  const [apiUrl, setApiUrl] = React.useState(getLiveStreamingApiUrl(myProfileQueryResponse));
  const [apiKey, setApiKey] = React.useState(getLiveStreamingApiKey(myProfileQueryResponse));
  const [publishUrl, setPublishUrl] = React.useState(getLiveStreamingPublishUrl(myProfileQueryResponse));

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.currentTarget.value;
    switch (name) {
      case 'apiUrl':
        setApiUrl(value);
        setApiUrlValid(Boolean(value));
        break;
      case 'apiKey':
        setApiKey(value);
        setApiKeyValid(Boolean(value));
        break;
      case 'publishUrl':
        setPublishUrl(value);
        setPublishUrlValid(Boolean(value));
        break;
      default:
        break;
    }
  };

  const handleClickShowKey = () => {
    setShowKey(!showKey);
  };

  const onLvsSave = () => {
    const vars = {
      apiUrl: apiUrl,
      apiKey: apiKey,
      publishUrl: publishUrl
    };

    dispatch(updateLivestreaming(relayEnvironment, vars));
    props.onClose();
  };

  const canSave = () => {
    if (apiUrlValid && apiKeyValid && publishUrlValid) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <ClosableDialog
        fullWidth={true}
        open={true}
        onClose={props.onClose}
        title={formatMessage(messages.streamTitle)}
      >
        <DialogContent>
          <form noValidate autoComplete="off">
            <List>
              <ListItem className={classes.input}>
                <TextField
                  error={apiUrlValid ? false : true}
                  required
                  label={formatMessage(messages.apiUrl)}

                  fullWidth
                  value={apiUrl}
                  onChange={handleChange('apiUrl')}
                />
              </ListItem>
              <ListItem className={classes.input}>
                <TextField
                  error={apiKeyValid ? false : true}
                  required
                  label={formatMessage(messages.apiKey)}

                  type={showKey ? 'text' : 'password'}
                  fullWidth
                  value={apiKey}
                  onChange={handleChange('apiKey')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment variant="filled" position="end">
                        <IconButton
                          aria-label={formatMessage(messages.showApiKey)}
                          onClick={handleClickShowKey}
                        >
                          {showKey ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </ListItem>
              <ListItem className={classes.input}>
                <TextField
                  error={publishUrlValid ? false : true}
                  required
                  label={formatMessage(messages.pubUrl)}

                  fullWidth
                  value={publishUrl}
                  onChange={handleChange('publishUrl')}
                />
              </ListItem>
            </List>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={onLvsSave} variant='contained' disabled={!canSave()} color="primary">
            {formatMessage(messages.save)}
          </Button>
        </DialogActions>
      </ClosableDialog>
    </React.Fragment>
  );
}


export default Settings;
