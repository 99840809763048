import React from 'react';
import { useIntl, defineMessages } from 'react-intl';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';

import ChatMessage from './ChatMessage';
import { IconMessageSelected } from '../IconSet';

import { AggregateChatMessage } from './utils';


const messages = defineMessages({
  noMessages: { id: 'noMessages' },
});


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    centered: {
      padding: '1em',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexFlow: 'column',
    },
    listItem: {
      alignItems: 'flex-start',
    }
  })
);


type Props = {
  messages: AggregateChatMessage[];
}


function Messages(props: Props) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const lastMessageRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth", block: 'end', inline: 'nearest' });
    }
  });

  const chatMessages = props.messages
    .map(
      (m, idx) => (
        <ListItem key={idx} className={classes.listItem}>
          <ChatMessage message={m} />
        </ListItem>
      )
    );

  if (chatMessages.length === 0) {
    return (
      <div className={classes.centered}>
        <IconMessageSelected size={64} />
        <Typography variant='caption'>
          {formatMessage(messages.noMessages)}
        </Typography>
      </div>
    );
  }

  return (
    <div>
      {chatMessages}
      <div ref={lastMessageRef}></div>
    </div>
  );
}


export default Messages;
