/* Libraries Imports */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, defineMessages } from 'react-intl';
/* UI Imports */
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
/* Other imports */
import IntlPropType from '../../intl';
import { getLogger } from '../../lib/logger';

const messages = defineMessages({
  ok: { id: 'ok' },
  unsupportedBrowserTitle: { id: 'unsupportedBrowserTitle' },
  unsupportedBrowserVersionTitle: { id: 'unsupportedBrowserVersionTitle' },
  supportedExperimentalTitle: { id: 'supportedExperimentalTitle' },
  unsupportedBrowserContent: { id: 'unsupportedBrowserContent' },
  unsupportedBrowserVersionContent: { id: 'unsupportedBrowserVersionContent' },
  supportedExperimentalContent: { id: 'supportedExperimentalContent' },
  supportedBrowsers: { id: 'supportedBrowsers' },
  iosSupportedBrowsers: { id: 'iosSupportedBrowsers' },
});


const reasonVariant = {
  UNSUPPORTED_BROWSER: Symbol('UNSUPPORTED_BROWSER'),
  UNSUPPORTED_VERSION: Symbol('UNSUPPORTED_VERSION'),
  SUPPORTED_EXPERIMENTAL: Symbol('SUPPORTED_EXPERIMENTAL'),
};


class BrowserCheck extends Component {
  constructor(props) {
    super(props);
    this.logger = getLogger('BrowserCheck');
    this.state = {
      show: false,
      reason: null,
      os: false,
    };
    this.minChromeVersion = 74;
    this.minFirefoxVersion = 70;
    this.minEdgeVersion = 17763;
    this.minReadableEdgeVersion = 44;
    this.minSafariVersion = 604;
    this.minReadableSafariVersion = 11;
    this.minumSupportedVersion = '-';

    this.handleRequestClose = this.handleRequestClose.bind(this);
  }

  componentDidMount() {
    this.checkBrowser();
  }

  setMinumSupportedVersion(browser) {
    if (browser === 'chrome') {
      this.supportedVersion = this.minChromeVersion;
    }
    else if (browser === 'firefox') {
      this.supportedVersion = this.minFirefoxVersion;
    }
    else if (browser === 'edge') {
      this.supportedVersion = this.minReadableEdgeVersion;
    }
    else if (browser === 'safari') {
      this.supportedVersion = this.minReadableSafariVersion;
    }
  }

  checkBrowser() {
    const browser = this.props.browser;
    const version = this.props.browserVersion;
    const userAgent = this.props.browserUserAgent;
    this.setMinumSupportedVersion(browser);
    this.logger.info('checking browser compatibility', browser, version);
    let reason = null;
    if (browser === 'chrome') {
      if (version <= this.minChromeVersion) {
        reason = reasonVariant.UNSUPPORTED_VERSION;
      }
    }
    else if (browser === 'firefox') {
      if (version < this.minFirefoxVersion) {
        reason = reasonVariant.UNSUPPORTED_VERSION;
      }
    }
    else if (browser === 'edge') {
      if (version < this.minEdgeVersion) {
        reason = reasonVariant.UNSUPPORTED_VERSION;
      }
    }
    else if ((browser === 'safari') && (userAgent.name === 'crios')) {
      // chrome on iOS
      reason = reasonVariant.UNSUPPORTED_BROWSER;
    }
    else if (browser === 'safari') {
      if (version < this.minSafariVersion) {
        reason = reasonVariant.UNSUPPORTED_VERSION;
      }
    }
    else {
      reason = reasonVariant.UNSUPPORTED_BROWSER;
    }
    if (reason) {
      this.setUnsupportedBrowser(reason);
    }
    else {
      this.props.onCheckSuccess();
    }
  }

  setUnsupportedBrowser(reason) {
    this.logger.error('browser check failure:', reason.toString());  // stupid edge doesn't know how to print symbols
    const os = (this.props.browserUserAgent || {}).name;
    this.setState({ show: true, reason: reason, os: os });
  }

  handleRequestClose() {
    this.setState({ show: false });
    this.props.onCheckSuccess();
  }

  getTitle(reason) {
    const defaultTitle = 'Error';
    const map = {
      [reasonVariant.UNSUPPORTED_BROWSER]: this.props.intl.formatMessage(messages.unsupportedBrowserTitle),
      [reasonVariant.UNSUPPORTED_VERSION]: this.props.intl.formatMessage(messages.unsupportedBrowserVersionTitle),
      [reasonVariant.SUPPORTED_EXPERIMENTAL]: this.props.intl.formatMessage(messages.supportedExperimentalTitle),
    };
    return map[reason] || defaultTitle;
  }

  getContent(reason) {
    const defaultContent = 'Error while detecting browser';
    const map = {
      [reasonVariant.UNSUPPORTED_BROWSER]: this.props.intl.formatMessage(
        messages.unsupportedBrowserContent
      ),
      [reasonVariant.UNSUPPORTED_VERSION]: this.props.intl.formatMessage(
        messages.unsupportedBrowserVersionContent, { version: this.supportedVersion }
      ),
      [reasonVariant.SUPPORTED_EXPERIMENTAL]: this.props.intl.formatMessage(
        messages.supportedExperimentalContent
      ),
    };
    return map[reason] || defaultContent;
  }

  getSupportedBrowsers(reason, os) {
    if (os === 'crios') {
      return this.props.intl.formatMessage(
        messages.iosSupportedBrowsers, { minSafariVersion: this.minReadableSafariVersion }
      );
    }
    if (os === 'ios') {
      if (reason === reasonVariant.SUPPORTED_EXPERIMENTAL) {
        return "";
      }
      else {
        return this.props.intl.formatMessage(
          messages.iosSupportedBrowsers, { minSafariVersion: this.minReadableSafariVersion }
        );
      }
    }
    return this.props.intl.formatMessage(
      messages.supportedBrowsers,
      { minChromeVersion: this.minChromeVersion, minFirefoxVersion: this.minFirefoxVersion }
    );
  }

  getButtons(reason) {
    if (reason && (reason === reasonVariant.SUPPORTED_EXPERIMENTAL)) {
      return (
        <DialogActions>
          <Button onClick={this.handleRequestClose} color="primary" autoFocus>
            {this.props.intl.formatMessage(messages.ok)}
          </Button>
        </DialogActions>
      );
    }
    else {
      return null;
    }
  }

  render() {
    return (
      <Dialog
        open={this.state.show}
        disableBackdropClick
        disableEscapeKeyDown
        maxWidth='md'
      >
        <DialogTitle>
          {this.getTitle(this.state.reason)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {this.getContent(this.state.reason)}
          </DialogContentText>
          <DialogContentText>
            {this.getSupportedBrowsers(this.state.reason, this.state.os)}
          </DialogContentText>
        </DialogContent>
        {this.getButtons(this.state.reason)}
      </Dialog>
    );
  }
}


BrowserCheck.propTypes = {
  intl: IntlPropType.isRequired,
  browser: PropTypes.string.isRequired,
  browserVersion: PropTypes.number.isRequired,
  browserUserAgent: PropTypes.object.isRequired,
  onCheckSuccess: PropTypes.func.isRequired,
};


export default injectIntl(BrowserCheck);
