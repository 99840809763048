import React, { Component } from 'react';
import { Channel } from 'phoenix';
import { connect } from 'react-redux';
import { createStyles, Theme } from '@material-ui/core/styles';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { CardMedia } from '@material-ui/core';
import { Meeting } from '../../lib/reducers/websocket';
import { State } from '../../lib/reducers';
import MeetingDuration from '../MeetingDuration';
import ActionRoomMenu from './ActionRoomMenu';
import RecordOnIcon from './RecordOnIcon';
import StreamingOnIcon from './StreamingOnIcon';
import { isMobileOnly } from 'react-device-detect';


const messages = defineMessages({
  roomInfoTitlePersonal: { id: 'roomInfoTitlePersonal' },
  roomStartRecording: { id: 'roomStartRecording' },
  roomIsBeingLiveStreamed: { id: 'roomIsBeingLiveStreamed' },
});


const style = (theme: Theme) => createStyles({
  title: {
    color: theme.palette.primary.main,
  },
  meeting: {
    color: theme.palette.text.primary,
  },
  logo: {
    width: '10%',
    height: 'auto',
  },
});


type MappedProps = {
  isMeeting: Channel | null;
  meetingDetails: Meeting | null;
  siteTitle: string;
  siteLogo?: string;
}


type Props = {}
  & MappedProps
  & WithStyles<typeof style>
  & { intl: IntlShape }


const mapStateToProps = (state: State): MappedProps => ({
  isMeeting: state.websocket.room,
  meetingDetails: state.websocket.meeting_details,
  siteTitle: state.appconfig.site_title,
  siteLogo: state.appconfig.site_logo,
});


class SiteTitle extends Component<Props, {}> {
  render() {
    if (this.props.isMeeting && this.props.meetingDetails) {
      return (
        <React.Fragment>
          {this.getMeetingInfo(this.props.meetingDetails)}
          <Tooltip title={this.props.intl.formatMessage(messages.roomStartRecording)}>
            <div>
              <RecordOnIcon />
            </div>
          </Tooltip>
          <Tooltip title={this.props.intl.formatMessage(messages.roomIsBeingLiveStreamed)}>
            <div>
              <StreamingOnIcon />
            </div>
          </Tooltip>
        </React.Fragment>
      );
    }
    else {
      return (this.getSiteTitle());
    }

  }

  getMeetingInfo = (meetingDetails: Meeting) => {
    if (isMobileOnly) {
      return (
        <MeetingDuration />
      );
    } else {
      const meetingTitle = this.getMeetingTitle(meetingDetails.title);
      return (
        <React.Fragment>
          <div style={{ display: 'flex' }}>
            <Typography variant="body2" className={this.props.classes.meeting} >
              {`${meetingTitle} -`}
            </Typography >
            <MeetingDuration />
          </div>
          <ActionRoomMenu />
        </React.Fragment>
      );
    }
  }

  getMeetingTitle = (meetingTitle: string): string => {
    const formatMessage = this.props.intl.formatMessage;
    const { meetingDetails } = this.props;
    if (meetingDetails && meetingDetails.room_type === 'personal') {
      const organizerName = meetingDetails.organizer.display || 'unknown';

      return formatMessage(messages.roomInfoTitlePersonal, { user: organizerName });
    }
    else {
      return meetingTitle;
    }
  }

  getSiteTitle = () => {
    const { siteTitle, siteLogo, classes } = this.props;
    if (siteLogo) {
      return (
        <CardMedia
          className={classes.logo}
          image={siteLogo}
          component="img"
        />
      );
    } else {
      return (
        <Typography variant="h6" className={classes.title} >
          {siteTitle}
        </Typography >
      );
    }
  }

}


export default withStyles(style)(injectIntl(connect(mapStateToProps)(SiteTitle)));
