import React from 'react';
import { connect } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import ExtMenu from './ExtMenu';

import { IconMore } from '../../IconSet';
import { State } from '../../../lib/reducers';
import {
  isPipEnabled,
  canChangeRole,
  canFullscreen,
  isKickEnabled
}
  from '../../../lib/reduxSelectors/user';

import classNames from 'classnames';
import useStyles from '../../../style/ControlButton';


const messages = defineMessages({
  moreActionTooltip: { id: 'moreActionTooltip' },
});


function MoreButton(props: ExtendedProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { canClose } = props;

  const setCanClose = React.useCallback((open: boolean) => {
    if (canClose) {
      canClose(open);
    }
  }, [canClose]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setCanClose(false);
  };

  const closeMenu = React.useCallback(() => {
    setAnchorEl(null);
    setCanClose(true);
  }, [setCanClose]);

  const {
    user,
    pipEnabled,
    changeRoleEnabled,
    canFullscreenEnabled,
    canKick,
    iconSize = 20,
    iconColor = 'black',
    mini,
    uid,
  } = props;

  const disableWhenEmpty = props.disableWhenEmpty || false;
  const msg = formatMessage(messages.moreActionTooltip);

  const cls = classNames(classes.controlButton, props.mini ? classes.controlButtonMini : null);

  if (!pipEnabled && !changeRoleEnabled && !canFullscreenEnabled && !canKick) {
    if (disableWhenEmpty) {
      return (
        <Tooltip placement="top" title={msg}>
          <div>
            <IconButton disabled className={cls} size='small'>
              <IconMore color='grey' size={iconSize} />
            </IconButton>
          </div>
        </Tooltip>
      );
    }
    else {
      return null;
    }
  }

  return (
    <React.Fragment>
      <Tooltip placement="top" title={msg}>
        <div>
          <IconButton onClick={handleMenu} className={classNames(cls, props.cName)} size='small'>
            <IconMore color={iconColor} size={iconSize} />
          </IconButton>
          <ExtMenu anchorEl={anchorEl} onClose={closeMenu} user={user} mini={mini} uid={uid} />
        </div>
      </Tooltip>
    </React.Fragment>
  );
}

type Props = {
  user: string;
  iconSize?: number;
  disableWhenEmpty?: boolean;
  cName?: string;
  iconColor?: string;
  canClose?: (open: boolean) => void;
  mini?: boolean;
  uid?: string;
}

type MappedProps = {
  pipEnabled: boolean;
  changeRoleEnabled: boolean;
  canFullscreenEnabled: boolean;
  canKick: boolean;
}

type ExtendedProps = Props & MappedProps;

const mapStateToProps = (state: State, ownProps: Props): MappedProps => {
  const userId = (ownProps.user || "").replace(/_screen$/, '');
  return {
    pipEnabled: isPipEnabled(ownProps.user, state),
    changeRoleEnabled: canChangeRole(userId, state),
    canFullscreenEnabled: canFullscreen(ownProps.user, state),
    canKick: isKickEnabled(userId, state),
  };
};

export default connect(mapStateToProps)(MoreButton);
