import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';

import {
  IconVideo,
  IconVideoOff,
} from '../IconSet';

import { toggleVideoMute, togglePublishVideo } from '../../lib/actions/room';

import { mapStateToProps, MappedProps } from '../Meeting/VideoToolbar/mapState';
import useStyles from '../../style/ControlButton';
import { iconColors as colors } from '../../colors';
import classNames from 'classnames';
import ResponsiveButton from '../ResponsiveButton';


const messages = defineMessages({
  videoButtonEnableTooltip: { id: 'videoButtonEnableTooltip' },
  videoButtonDisableTooltip: { id: 'videoButtonDisableTooltip' },
});


function VideoMuteButton(props: ExtendedProps) {
  const classes = useStyles();

  const {
    mySelf,
    hasVideoStream,
    isVideoMuted,
    uid,
    iconSize = 20,
    iconColor = colors.normal,
    mini,
  } = props;

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const toggleMute = React.useCallback(
    () => {
      if (mySelf) {
        dispatch(togglePublishVideo(!isVideoMuted));
      }
      else {
        dispatch(toggleVideoMute(uid, !isVideoMuted));
      }
    }
    , [dispatch, isVideoMuted, uid, mySelf]
  );

  const controlButtonHidden = !hasVideoStream ? classes.controlButtonHidden : null;
  const msg = formatMessage(
    (isVideoMuted) ? messages.videoButtonEnableTooltip : messages.videoButtonDisableTooltip
  );

  const cls = classNames(
    classes.controlButton,
    props.cName,
    controlButtonHidden);

  // add div due to https://github.com/mui-org/material-ui/issues/8416#issuecomment-332556082
  return (
    <Tooltip placement="top" title={!hasVideoStream || mini ? '' : msg}>
      <div>
        <ResponsiveButton
          disabled={!hasVideoStream}
          cName={cls}
          mini={mini}
          onClick={toggleMute}
          size='small'
          text={msg}>
          {isVideoMuted
            ? <IconVideoOff color={colors.inactive} size={iconSize} />
            : <IconVideo color={iconColor} size={iconSize} />
          }
        </ResponsiveButton>
      </div>
    </Tooltip>
  );
}


type Props = {
  uid: string;
  iconSize?: number;
  iconColor?: string;
  cName?: string;
  mini?: boolean;
}


type ExtendedProps = Props & MappedProps


export default connect(mapStateToProps)(VideoMuteButton);
