import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Switch from '@material-ui/core/Switch';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';

import RippleBadge from '../RosterAvatar/RippleBadge';
import DevicePreview from '../AVSettings';
import DeviceSelection from '../AVSettings/DeviceSelection';
import { IconVideo, IconMicrophone, IconSettings, IconPlay, IconStop } from '../IconSet';
import getRouteFor, { Route } from '../../lib/utils/router';
import { State } from '../../lib/reducers';
import { saveVideoEnabled, saveAudioJoinMuted } from '../../lib/actions/settings';

import LocalStorage from '../../localStorage';

import ClosableDialog from '../ClosableDialog';
import GuestLoginField from './GuestLoginField';

import { Tetris as TestTone } from '../../lib/utils/webaudio/tetris';


const messages = defineMessages({
  join: { id: 'joinButton' },
  checkSettingsAndJoin: { id: 'checkSettingsAndJoin' },
  joinWithVideo: { id: 'joinWithVideo' },
  joinMuted: { id: 'joinWithAudio' },
  mediaSettings: { id: 'mediaSettings' },
  playTestSound: { id: 'playTestSound' },
  stopTestSound: { id: 'stopTestSound' },
});


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    gridRoot: {
      overflowY: 'hidden',
      marginBottom: 0,   // this is needed on firefox, who knows why
    },
  })
);


function TestSoundPlayer({ device: d }: { device: null | MediaDeviceInfo }) {
  const deviceId = d ? d.deviceId : null;
  const { formatMessage } = useIntl();

  const [isPlayingTestSound, setIsPlayingTestSound] = React.useState(false);
  const testToneRef = React.useRef(new TestTone());

  const playTestSound = () => {
    setIsPlayingTestSound(true);
    testToneRef.current.start(deviceId);
  };

  const stopTestSound = () => {
    setIsPlayingTestSound(false);
    testToneRef.current.stop();
  };

  React.useEffect(
    () => {
      const rr = testToneRef.current;
      return () => {
        rr.stop();
      };
    }
    , []
  );

  if (isPlayingTestSound) {
    return (
      <Tooltip title={formatMessage(messages.stopTestSound)} >
        <IconButton onClick={stopTestSound}>
          <RippleBadge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            variant="dot"
          >
            <IconStop size={24} />
          </RippleBadge>
        </IconButton>
      </Tooltip >);
  } else {
    return (<Tooltip title={formatMessage(messages.playTestSound)} >
      <IconButton onClick={playTestSound}>
        <IconPlay size={24} />
      </IconButton>
    </Tooltip >);
  }
}


function JoinMeetingDialog(props: ExtendedProps) {
  const {
    open,
    onJoin,
    history,
    audioInitiallyMuted,
    withVideo,
    isAuthenticatedAsGuest,
    isAuthenticated
  } = props;

  const dispatch = useDispatch();
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const showGuestLogin = !isAuthenticated && !isAuthenticatedAsGuest;

  const [settingsOpen, setSettingsOpen] = React.useState(false);

  const gotoHome = React.useCallback(
    () => history.push(getRouteFor(Route.Home), { doNotConfirm: true })
    , [history]
  );

  React.useEffect(() => {
    const onKeyUp = (ev: KeyboardEvent) => {
      if (open && ev.key === 'Enter' && !showGuestLogin) {
        onJoin();
      }
    };
    window.addEventListener('keyup', onKeyUp, false);
    return () => {
      window.removeEventListener('keyup', onKeyUp, false);
    };
  }, [onJoin, open, showGuestLogin]);

  const handleVideoSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(saveVideoEnabled(event.target.checked, new LocalStorage()));
  };

  const handleAudioSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(saveAudioJoinMuted(!event.target.checked, new LocalStorage()));
  };

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

  return (
    <ClosableDialog
      open={open}
      disableBackdropClick
      onClose={gotoHome}
      fullWidth={false}
      title={formatMessage(messages.checkSettingsAndJoin)}
    >
      <DialogContent>
        <Grid container spacing={6} className={classes.gridRoot}>
          <Grid item xs={settingsOpen ? 6 : 12} style={{ margin: 'auto' }}>
            <div style={{ maxWidth: '400px', margin: 'auto', height: '300px' }}>
              <DevicePreview videoEnabled={withVideo} />
            </div>
          </Grid>
          {settingsOpen &&
            <Grid item xs={6} style={{ paddingBottom: '16px' }}>
              <DeviceSelection withVideo={withVideo} />
            </Grid>
          }
          <Grid item xs={12} style={{ paddingTop: '16px', paddingBottom: '16px' }}>
            <Grid container justify='center' spacing={6}>
              <Grid item>
                <Tooltip title={formatMessage(messages.joinWithVideo)}>
                  <div>
                    <IconVideo size={24} />
                    <Switch
                      color='primary'
                      value='video'
                      checked={withVideo}
                      onChange={handleVideoSwitch}
                    />
                  </div>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={formatMessage(messages.joinMuted)}>
                  <div>
                    <IconMicrophone size={24} />
                    <Switch
                      color='primary'
                      value='audio'
                      disabled={props.roomOptions.join_muted}
                      checked={!audioInitiallyMuted}
                      onChange={handleAudioSwitch}
                    />
                  </div>
                </Tooltip>
              </Grid>
              <Grid item>
                <TestSoundPlayer device={props.currentAudioOutDev} />
              </Grid>
              <Grid item>
                <Tooltip title={formatMessage(messages.mediaSettings)}>
                  <ToggleButton
                    value='settings'
                    selected={settingsOpen}
                    onChange={toggleSettings}
                  >
                    <IconSettings size={24} />
                  </ToggleButton>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          {showGuestLogin
            &&
            <Grid item xs={12} style={{ paddingTop: '16px', paddingBottom: '16px' }}>
              <GuestLoginField onJoin={onJoin} />
            </Grid>
          }
        </Grid>
      </DialogContent>
      {!showGuestLogin
        &&
        <DialogActions>
          <Button variant='contained' onClick={onJoin} color='primary'>
            {formatMessage(messages.join)}
          </Button>
        </DialogActions>
      }
    </ClosableDialog>
  );
}


function mapStateToProps(state: State) {
  return {
    isAuthenticated: state.auth.isAuthenticated,
    isAuthenticatedAsGuest: state.auth.isAuthenticatedAsGuest,
    withVideo: state.settings.videoEnabled,
    audioInitiallyMuted: state.settings.audioJoinMuted,
    currentVideoInputDev: state.settings.videoDevice,
    currentAudioInDev: state.settings.audioInDevice,
    currentAudioOutDev: state.settings.audioOutDevice,
    currentVideoQual: state.settings.videoQuality,
    roomOptions: state.appconfig.room_options,
  };
}


type Props = {
  open: boolean;
  onJoin: () => void;
};


type MappedProps = {
  isAuthenticated: boolean;
  isAuthenticatedAsGuest: boolean;
  withVideo: boolean;
  audioInitiallyMuted: boolean;
  currentVideoInputDev: State['settings']['videoDevice'];
  currentAudioInDev: State['settings']['audioInDev'];
  currentAudioOutDev: State['settings']['audioOutDev'];
  currentVideoQual: State['settings']['videoQuality'];
  roomOptions: State['appconfig']['room_options'];
}


type ExtendedProps = Props & MappedProps & RouteComponentProps<{ id: string }>;


export default connect(mapStateToProps)(withRouter(JoinMeetingDialog));
