/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type FilterMatch = "CONTAINS" | "ENDS" | "EQUALS" | "GT" | "GTE" | "LT" | "LTE" | "STARTS" | "%future added value";
export type SortDirection = "ASC" | "DESC" | "%future added value";
export type SearchSort = {
    readonly direction: SortDirection;
    readonly sort: string;
};
export type SearchFilter = {
    readonly key: string;
    readonly match?: FilterMatch | null;
    readonly value: string;
};
export type myMeetingsQueryVariables = {
    first?: number | null;
    after?: string | null;
    last?: number | null;
    before?: string | null;
    sorting?: ReadonlyArray<SearchSort | null> | null;
    filters?: ReadonlyArray<SearchFilter | null> | null;
};
export type myMeetingsQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"View_data">;
};
export type myMeetingsQuery = {
    readonly response: myMeetingsQueryResponse;
    readonly variables: myMeetingsQueryVariables;
};



/*
query myMeetingsQuery(
  $first: Int
  $after: String
  $last: Int
  $before: String
  $sorting: [SearchSort]
  $filters: [SearchFilter]
) {
  ...View_data_3TNRfT
}

fragment View_data_3TNRfT on RootQueryType {
  myMeetings(first: $first, after: $after, last: $last, before: $before, sorting: $sorting, filters: $filters) {
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
    edges {
      cursor
      node {
        title
        slug
        dtStart
        dtEnd
        id
        userId
        domainId
        notes
        attendees {
          email
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "first",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "after",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "last",
    "type": "Int",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "before",
    "type": "String",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "sorting",
    "type": "[SearchSort]",
    "defaultValue": null
  },
  {
    "kind": "LocalArgument",
    "name": "filters",
    "type": "[SearchFilter]",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "filters",
    "variableName": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "sorting",
    "variableName": "sorting"
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "myMeetingsQuery",
    "type": "RootQueryType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "View_data",
        "args": (v1/*: any*/)
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "myMeetingsQuery",
    "argumentDefinitions": (v0/*: any*/),
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "myMeetings",
        "storageKey": null,
        "args": (v1/*: any*/),
        "concreteType": "MeetingConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "pageInfo",
            "storageKey": null,
            "args": null,
            "concreteType": "PageInfo",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "startCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "endCursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasNextPage",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "hasPreviousPage",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "MeetingEdge",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "cursor",
                "args": null,
                "storageKey": null
              },
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "Meeting",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "title",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "slug",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "dtStart",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "dtEnd",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "id",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "userId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "domainId",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "ScalarField",
                    "alias": null,
                    "name": "notes",
                    "args": null,
                    "storageKey": null
                  },
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "attendees",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Attendee",
                    "plural": true,
                    "selections": [
                      {
                        "kind": "ScalarField",
                        "alias": null,
                        "name": "email",
                        "args": null,
                        "storageKey": null
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "myMeetingsQuery",
    "id": null,
    "text": "query myMeetingsQuery(\n  $first: Int\n  $after: String\n  $last: Int\n  $before: String\n  $sorting: [SearchSort]\n  $filters: [SearchFilter]\n) {\n  ...View_data_3TNRfT\n}\n\nfragment View_data_3TNRfT on RootQueryType {\n  myMeetings(first: $first, after: $after, last: $last, before: $before, sorting: $sorting, filters: $filters) {\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n    edges {\n      cursor\n      node {\n        title\n        slug\n        dtStart\n        dtEnd\n        id\n        userId\n        domainId\n        notes\n        attendees {\n          email\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '980027238db71751608b2541aaecd11e';
export default node;
