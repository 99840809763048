import { Channel, Presence } from 'phoenix';


type LinkQuality = 'high' | 'medium' | 'low'


type RoomRole = 'room_owner' | 'room_moderator' | 'room_user';


export type Participant = {
  display: PresenceMetadata['display'];
  viaPhone: PresenceMetadata['via_phone'];
  role: PresenceMetadata['role'];
  username: PresenceMetadata['username'];
  video_uplink_quality: PresenceMetadata['video_uplink_quality'];
  screen_uplink_quality: PresenceMetadata['screen_uplink_quality'];
  isControllingDesktop: PresenceMetadata['is_controlling_desktop'];
  isSharingDesktopControl: PresenceMetadata['is_sharing_desktop_control'];
  hasStartedDrawing: PresenceMetadata['has_started_drawing'];
  onlineAt: PresenceMetadata['online_at'];
}


type Participants = { [uid: string]: Participant }


type PresenceMetadata = {
  online_at: null | number;
  user_id: string;
  role: RoomRole;
  username: string;
  via_phone: boolean;
  video_uplink_quality: LinkQuality;
  screen_uplink_quality: LinkQuality;
  display: string;
  is_controlling_desktop: boolean;
  is_sharing_desktop_control: boolean;
  has_started_drawing: boolean;
}


type PresenceUser = {
  user_id: string;
  metas: PresenceMetadata[];
}



function _getUserUid(user: PresenceUser) {
  return user.metas[0].user_id;
}


function _remapUser(user: PresenceUser) {
  const metas = user.metas[0];
  const display = metas.display;
  const viaPhone = metas.via_phone;
  const role = metas.role;
  const username = metas.username;
  const onlineAt = metas.online_at;
  return {
    display: display,
    viaPhone: viaPhone,
    role: role,
    username: username,
    // eslint-disable-next-line @typescript-eslint/camelcase
    video_uplink_quality: metas.video_uplink_quality,
    // eslint-disable-next-line @typescript-eslint/camelcase
    screen_uplink_quality: metas.screen_uplink_quality,
    isControllingDesktop: metas.is_controlling_desktop,
    isSharingDesktopControl: metas.is_sharing_desktop_control,
    hasStartedDrawing: metas.has_started_drawing,
    onlineAt,
  };
}


export function onPresenceUpdate(channel: Channel, onDispatch: PresenceDispatchFunction) {
  const presence = new Presence(channel);
  const updatePresence = () => {
    const participants: Participants = {};
    presence.list().forEach((p) => {
      const uid = _getUserUid(p);
      participants[uid] = _remapUser(p);
    });
    onDispatch(participants);
  };
  presence.onSync(updatePresence);
}


export type PresenceDispatchFunction = (participants: Participants) => void
