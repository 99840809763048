import React from 'react';
import StyledMenu from '../../StyledMenu';
import KickMenuItem from './KickMenuItem';
import PipMenuItem from './PipMenuItem';
import FullscreenMenuItem from './FullscreenMenuItem';
import ChangeRoleMenuItem from './ChangeRoleMenuItem';
import MainActions from './MainActions';


type MenuProps = {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  user: string;
  mini?: boolean;
  uid?: string;
}


function ExtMenu({ anchorEl, onClose, user, mini, uid }: MenuProps) {

  return (
    <StyledMenu
      disablePortal
      anchorEl={anchorEl}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      { mini && uid && <MainActions uid={uid} mini={mini} /> }
      <FullscreenMenuItem onClose={onClose} user={user} />
      <PipMenuItem onClose={onClose} user={user} />
      <ChangeRoleMenuItem onClose={onClose} user={user} />
      <KickMenuItem onClose={onClose} user={user} />
    </StyledMenu>
  );
}

export default ExtMenu;
