import { createStyles } from '@material-ui/core/styles';
import { darken } from '@material-ui/core/styles';

const useStyles = (theme) => createStyles({
  '.App': {
    textAlign: 'center',
    backgroundColor: "#E6E6FA",
  },
  '.App-header': {
    backgroundColor: '#222',
    height: '150px',
    padding: '20px',
    color: 'white',
  },
  '.App-intro': {
    fontSize: 'large',
  },
  '@global': {
    '*::-webkit-scrollbar': {
      height: theme.spacing(1),
      width: theme.spacing(1),
    },
    '*::-webkit-scrollbar-track': {
      backgroundColor: theme.palette.background.default,
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: darken(theme.palette.secondary.light, 0.1),
      borderRadius: '50px',
    },

    /*
    * react-split-pane resizer css
    *
    * color should not be static and reflect the theme
    * now is set to the default primary main color
    *
    */
    '.Resizer': {
      background: theme.palette.primary.main,
      opacity: 0.2,
      zIndex: 1,
      mozBoxSizing: 'border-box',
      webkitBoxSizing: 'border-box',
      mozBackgroundClip: 'padding',
      webkitBackgroundClip: 'padding',
      backgroundClip: 'padding-box',
    },
    '.Resizer:hover': {
      webkitTransition: 'all 2s ease',
      transition: 'all 2s ease',
    },
    '.Resizer.horizontal': {
      height: '11px',
      margin: '-5px 0',
      borderTop: '5px solid rgba(255, 255, 255, 0)',
      borderBottom: '5px solid rgba(255, 255, 255, 0)',
      cursor: 'ns-resize',
      width: '100%',
    },
    '.Resizer.horizontal:hover': {
      borderTop: '5px solid rgba(0, 0, 0, 0.5)',
      borderBottom: '5px solid rgba(0, 0, 0, 0.5)',
    },
    '.Resizer.vertical': {
      opacity: 1,
      width: theme.spacing(0.5),
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      marginTop: 'calc(50vh - 45px)',
      marginBottom: 'calc(50vh - 45px)',
      borderRadius: '6px',
      cursor: 'ew-resize',
    },
    '.Resizer.vertical:hover': {
      //borderLeft: '5px solid rgba(0, 0, 0, 0.5)',
      //borderRight: '5px solid rgba(0, 0, 0, 0.5)',
    },
    '.Resizer.disabled': {
      cursor: 'default',
    },
    '.Resizer.disabled:hover': {
      borderColor: 'transparent',
    },
  }
});

export default useStyles;
