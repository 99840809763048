import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import RosterAvatar from '../RosterAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import AudioMuteButton from '../AudioMuteButton';
import VideoMuteButton from '../VideoMuteButton';
import MoreButton from '../Meeting/VideoToolbar/MoreButton';
import UserInfo from './UserInfo';


type Props = {
  uid: string;
  displayName: string;
}


const useStyles = makeStyles((_theme: Theme) => createStyles({
  name: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));


function User(props: Props) {
  const { uid, displayName } = props;
  const classes = useStyles();

  return (
    <React.Fragment>
      <ListItemIcon>
        <RosterAvatar name={displayName} uid={uid} />
      </ListItemIcon>
      <ListItemText className={classes.name} primary={displayName} />
      <UserInfo uid={uid} />
      <AudioMuteButton uid={uid} iconSize={24} />
      <VideoMuteButton uid={uid} iconSize={24} />
      <MoreButton disableWhenEmpty user={uid} iconSize={24} />
    </React.Fragment>
  );
}

export default User;
