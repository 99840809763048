/* Libraries Imports */
import React, { KeyboardEvent } from 'react';
import { useRelayEnvironment } from 'relay-hooks';
/* UI Imports */
import useStyles, { StyledChip } from './style';
/* Components Imports */
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import { IconSend, IconEsc } from '../IconSet';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Tooltip from '@material-ui/core/Tooltip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { searchContacts, getContacts } from '../../lib/api/relay/searchContact';
import useDebounce from '../../lib/utils/debounce';


type Props = {
  autoFocus?: boolean;
  textFieldLabel?: string;
  items: Array<string>;
  onAdd: (value: string) => void;
  onRemove: (index: number) => void;
  validator: (value: string) => boolean;
  invalidMessage?: string;
}


function ListAdder(props: Props) {
  const { onAdd, items } = props;
  const [text, setText] = React.useState<string>('');
  const [invalidText, setInvalidText] = React.useState<boolean>(false);
  const [ options, setOptions ] = React.useState<string[]>([]);
  const debouncedText = useDebounce(text, 200);

  const classes = useStyles();
  const autoFocus = props.autoFocus || false;

  const relayEnvironment = useRelayEnvironment();

  React.useEffect(
    () => {
      if (debouncedText) {
        searchContacts(relayEnvironment, debouncedText)
          .then((data) => {
            const contacts = getContacts(data);
            if (contacts) {
              // remove emails already added
              const filtered = contacts.filter((u) => !items.includes(u));
              setOptions(filtered);
            }
          });
      } else {
        setOptions([]);
      }
    }, [debouncedText, relayEnvironment, setOptions, items]
  );

  const handleAdd = React.useCallback(
    () => {
      const value = text;
      if (value && !invalidText) {
        onAdd(value);
        setText('');
        setInvalidText(false);
      }
    }, [setText, setInvalidText, onAdd, invalidText, text]
  );

  const handleRemove = (index: number) => {
    props.onRemove(index);
  };

  const handleKeyUp = React.useCallback(
    (ev: KeyboardEvent<HTMLInputElement>) => {
      if ((ev.key === 'Enter') && text) {
        handleAdd();
      }
    }, [handleAdd, text]
  );

  const onInputChange = (_event: object, value: string, _reason: string) => {
    setText(value);
    setInvalidText(props.validator ? !props.validator(value) : false);
  };

  const getInputAdornment = React.useCallback(
    () => {
      return (
        <InputAdornment position="end">
          <IconButton
            onClick={handleAdd}
          >
            <IconSend size={24} />
          </IconButton>
        </InputAdornment>
      );
    }, [handleAdd]
  );

  const renderInput = React.useCallback(
    (params) => {
      return (
        <TextField
          {...params}
          onKeyUp={handleKeyUp}
          fullWidth
          autoFocus={autoFocus}
          error={(invalidText && !!props.invalidMessage)}
          inputProps={{ ...params.inputProps, autoComplete: 'off' }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                { getInputAdornment() }
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
        />
      );
    }, [getInputAdornment, handleKeyUp, autoFocus, invalidText, props.invalidMessage]
  );

  return (
    <div className={classes.container}>
      <FormControl error={invalidText}>
        <InputLabel>{props.textFieldLabel}</InputLabel>
        <Autocomplete
          options={options}
          className={classes.autocomplete}
          autoComplete
          autoHighlight
          freeSolo
          size='small'
          onInputChange={onInputChange}
          noOptionsText={''}
          value={text}
          disableClearable={true}
          renderInput={renderInput}
        />
        {(invalidText && props.invalidMessage) ?
          <FormHelperText>{props.invalidMessage}</FormHelperText>
          : null}
      </FormControl>
      <div className={classes.list}>
        {props.items.map((el, idx) => {
          return (
            <EmailItem key={idx} text={el} handleRemove={handleRemove} index={idx} />
          );
        })}
      </div>
    </div>
  );
}


function EmailItem(props: {text: string; handleRemove: (index: number) => void; index: number}) {
  const classes = useStyles();

  const handleRemove = () => {
    props.handleRemove(props.index);
  };

  return (
    <Tooltip
      arrow
      interactive
      title={props.text}
      classes={{ tooltip: classes.tooltip }}
    >
      <StyledChip
        key={props.index}
        className={classes.chip}
        label={props.text}
        onDelete={handleRemove}
        deleteIcon={
          <IconButton size="small">
            <IconEsc size={22} />
          </IconButton>
        }
      />
    </Tooltip>
  );
}


export default ListAdder;
