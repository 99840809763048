import React  from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import CopyToClipboard from 'react-copy-to-clipboard';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { AlternateEmailOutlined, RoomOutlined } from '@material-ui/icons';
import TooltipMultiLineText from '../TooltipMultiLineText';
import ClipboardIcon from '../../style/ClipboardIcon';
import ClosableDialog from '../ClosableDialog';

import getRouteFor, { Route } from '../../lib/utils/router';
import { linkify } from '../../lib/utils';
import { IconUser, IconEmail } from '../IconSet';


const messages = defineMessages({
  title: { id: 'userInformation' },
  email: { id: 'email' },
  username: { id: 'username' },
  name: { id: 'name' },
  personalRoom: { id: 'personalRoom' },
  copyToClipboard: { id: 'copyToClipboard' },
  copiedToClipboard: { id: 'copiedToClipboard' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflow: 'hidden',
      paddingBottom: theme.spacing(2),
    },
    container: {
      padding: theme.spacing(1),
    },
    avatar: {
      width: "50%",
      height: "auto",
      paddingBottom: "50%",
      marginTop: '20px',
    },
    icons: {
      textAlign: 'center',
      alignSelf: 'center',
    },
    userInfoEntryTitle: {
      color: 'rgba(0, 0, 0, 0.54)',
      fontSize: '0.75rem',
      fontWeight: 400,
      lineHeight: '1em',
    },
    userInfoEntryContent: {
      color: 'rgba(0, 0, 0, 0.90)',
      fontSize: '1rem',
      fontWeight: 400,
      lineHeight: '2em',
    },
    linkContent: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    userInfoIconContainer: {
      display: 'flex',
    },
    userInfoLinkContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  })
);


type MappedProps = {
  email: string;
  username: string;
  displayName: string;
}


type Props = {
  onClose: () => void;
} & MappedProps;


type ExtendedProps = Props & {intl: IntlShape};


function UserProfile(props: ExtendedProps) {
  const classes = useStyles();
  const { intl } = props;

  const [linkCopied, setLinkCopied] = React.useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_timeoutHandler, setTimeoutHandler] = React.useState<null | ReturnType<typeof setTimeout>>(null);

  const personalRoomRawUrl = getRouteFor(
    Route.Meeting,
    { slug: props.username },
    undefined,
    window.location.origin
  );
  const personalRoomUrl = linkify(personalRoomRawUrl, '_blank');

  let cboardTtipText = props.intl.formatMessage(messages.copyToClipboard);
  if (linkCopied) {
    cboardTtipText = props.intl.formatMessage(messages.copiedToClipboard);
  }

  const onLinkCopied = () => setLinkCopied(true);

  const handleClipboardTooltipClose = () => {
    // ugly, but avoid re-rendering the normal tooltip text just before closing it
    const tmHandler = setTimeout(() => {
      setLinkCopied(false);
      setTimeoutHandler(null);
    },
    500);
    setTimeoutHandler(tmHandler);
  };

  return (
    <ClosableDialog
      open={true}
      onClose={props.onClose}
      title={props.intl.formatMessage(messages.title)}
    >
      <DialogContent className={classes.root}>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={2} className={classes.icons}>
              <IconUser size={24} />
            </Grid>
            <Grid item xs={10}>
              <div className={classes.userInfoEntryTitle}>
                {intl.formatMessage(messages.name)}
                <div className={classes.userInfoEntryContent}>
                  {props.displayName}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={2} className={classes.icons}>
              <IconEmail size={24} />
            </Grid>
            <Grid item xs={10}>
              <div className={classes.userInfoEntryTitle}>
                {intl.formatMessage(messages.email)}
                <div className={classes.userInfoEntryContent}>
                  {props.email}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={2} className={classes.icons}>
              <AlternateEmailOutlined />
            </Grid>
            <Grid item xs={10}>
              <div className={classes.userInfoEntryTitle}>
                {intl.formatMessage(messages.username)}
                <div className={classes.userInfoEntryContent}>
                  {props.username}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={2} className={classes.icons}>
              <RoomOutlined />
            </Grid>
            <Grid item xs={10}>
              <div className={classes.userInfoEntryTitle}>
                {intl.formatMessage(messages.personalRoom)}
                <div className={classes.userInfoLinkContainer}>
                  <div className={classNames(classes.userInfoEntryContent, classes.linkContent)}>
                    {personalRoomUrl}
                  </div>
                  <div>
                    <IconButton>
                      <TooltipMultiLineText
                        placement="top"
                        title={cboardTtipText}
                        onClose={handleClipboardTooltipClose}
                      >
                        <CopyToClipboard
                          onCopy={onLinkCopied}
                          text={personalRoomRawUrl}>
                          <ClipboardIcon />
                        </CopyToClipboard>
                      </TooltipMultiLineText>
                    </IconButton>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
    </ClosableDialog>
  );
}


type State = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  session: any;
}



const mapStateToProps = (state: State): MappedProps => ({
  email: state.session.email,
  username: state.session.username,
  displayName: state.session.displayName,
});


export default connect(mapStateToProps)(injectIntl(UserProfile));
