import {
  SESSIONDETAILS_REQUEST,
  SESSIONDETAILS_FAILURE,
  SESSIONDETAILS_SUCCESS,
  SESSIONDETAILS_CLEAR,
  SESSION_SETRECORDER,
  Action,
  Session,
} from '../actions/session';


export interface State {
  isFetching: boolean;
  sessionReady: boolean;
  errorCode: null | number;
  errorMessage: null | string;
  isRecorder: boolean;
  realm: null | Session['realm'];
  displayName: null | Session['displayName'];
  username: null | Session['username'];
  roles: Session['roles'];
  stunServers: Session['stunServers'];
  domainId: null | Session['domainId'];
  email: Session['email'];
  userId: null | Session['userId'];
  roomNumber: null | Session['roomNumber'];
  roomOwnerNumber: null | Session['roomOwnerNumber'];
  pstnNumber: null | Session['pstnNumber'];
  callStatsConfig: null | Session['callStatsConfig'];
  chromeScreenShareConfig: null | Session['chromeScreenShareConfig'];
  recordingEnabled: Session['recordingEnabled'];
  livestreamingEnabled: Session['livestreamingEnabled'];
  sendAs: Session['sendAs'];
  personalRoomEnabled: Session['personalRoomEnabled'];
  webinarEnabled: Session['webinarEnabled'];
}


const defaultState = {
  isFetching: false,
  sessionReady: false,
  errorCode: null,
  errorMessage: null,
  isRecorder: false,
  realm: null,
  displayName: null,    // name and surname if a user, requested display name if guest
  username: null,
  roles: [],
  stunServers: [],
  domainId: null,
  email: null,
  userId: null,
  roomNumber: null,
  roomOwnerNumber: null,
  pstnNumber: null,
  callStatsConfig: null,
  chromeScreenShareConfig: null,
  recordingEnabled: false,
  livestreamingEnabled: false,
  sendAs: false,
  personalRoomEnabled: false,
  webinarEnabled: false,
};


function session(state: State = defaultState, action: Action) {
  switch (action.type) {
    case SESSIONDETAILS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        sessionReady: false,
      });
    case SESSIONDETAILS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        sessionReady: false,
        errorMessage: action.payload.errorMessage
      });
    case SESSIONDETAILS_SUCCESS: {
      const sess = action.payload.session;
      return Object.assign({}, state, {
        isFetching: false,
        sessionReady: true,
        realm: sess.realm,
        displayName: sess.displayName,
        username: sess.username,
        roles: sess.roles,
        stunServers: sess.stunServers,
        domainId: sess.domainId,
        email: sess.email,
        userId: sess.userId,
        roomNumber: sess.roomNumber,
        roomOwnerNumber: sess.roomOwnerNumber,
        pstnNumber: sess.pstnNumber,
        callStatsConfig: sess.callStatsConfig,
        chromeScreenShareConfig: sess.chromeScreenShareConfig,
        recordingEnabled: sess.recordingEnabled,
        livestreamingEnabled: sess.livestreamingEnabled,
        sendAs: sess.sendAs,
        personalRoomEnabled: sess.personalRoomEnabled,
        webinarEnabled: sess.webinarEnabled,
      });
    }
    case SESSIONDETAILS_CLEAR:
      return Object.assign({}, state, defaultState);
    case SESSION_SETRECORDER:
      return Object.assign({}, state, {
        isRecorder: action.payload.is_recorder
      });
    default:
      return state;
  }
}
export default session;
export { defaultState };
