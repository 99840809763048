import lodash from 'lodash';
import { State } from '../reducers';
import { RosterUser } from '../redux_types';
import { createDeepEqualitySelector } from '.';


export type ObservedUser = {
  stream: RosterUser['stream'];
  screen: RosterUser['screen'];
  display: RosterUser['display'];
  viaPhone: RosterUser['viaPhone'];
  username: RosterUser['username'];
}

export type ObservedRoster = { [k: string]: ObservedUser }

const _getRoster = (state: State): ObservedRoster => {
  const wantedKeys = ['stream', 'screen', 'display', 'viaPhone', 'username'];
  const roster = state.room.roster;
  const users = Object.keys(roster);
  const observedRoster: ObservedRoster = {};
  for (const user of users) {
    const obsUser = lodash.pick(roster[user], wantedKeys);
    observedRoster[user] = obsUser as ObservedUser;
  }
  return observedRoster;
};

export const getRoster = createDeepEqualitySelector([_getRoster],  (roster) => roster);
