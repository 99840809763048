import React from 'react';

import { useDispatch } from 'react-redux';
import { useIntl } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { CallEndRounded } from '@material-ui/icons';
import ToolbarButton from './ToolbarButton';
import useStyles from './buttonsStyle';
import messages from './buttonsMessages';

import getRouteFor, { Route } from '../../lib/utils/router';
import { tearDownRoom } from '../../lib/actions/room';
import { leaveChannel } from '../../lib/actions/websocket';


type Props = {} & RouteComponentProps


function ExitButton(props: Props) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const onExit = React.useCallback(
    () => {
      dispatch(tearDownRoom());
      dispatch(leaveChannel());
      props.history.push(getRouteFor(Route.Home), { doNotConfirm: true });
    }
    , [dispatch, props.history]
  );

  return (
    <ToolbarButton
      icon={<CallEndRounded className={classes.exitButton} />}
      text={formatMessage(messages.leaveMeeting)}
      buttonProps={{ className: classes.buttons, onClick: onExit }}
    />
  );
}


export default withRouter(ExitButton);
