import React from 'react';
import { connect } from 'react-redux';

import Avatar from '../AvatarNew';
import RippleBadge from './RippleBadge';
import useStyles from './style';
import { IconShareScreen } from '../IconSet';
import { IconButton, Badge } from '@material-ui/core';
import { State } from '../../lib/reducers';


interface Props {
  name: string;
  uid: string;
}

type MappedProps = {
  screen: MediaStream | null;
  isTalking: boolean;
}

type ExtendedProps = Props & MappedProps

const mapStateToProps = (state: State, { uid }: Props): MappedProps => {
  const myUid = state.websocket.uid;
  let screen: MediaStream | null = null;
  if (myUid === uid) {
    screen = state.room.screenStream;
  } else {
    screen = state.room.roster[uid].screen;
  }
  return ({ screen, isTalking: state.room.roster[uid].is_talking });
};

  
function RosterAvatar(props: ExtendedProps) {
  const classes = useStyles();
  const { isTalking, name, screen } = props;

  const getScreenIcon = () => {
    return (
      <div className={classes.badgeContent}>
        <IconButton
          className={classes.screenButton}
          disableRipple={true}
          disableFocusRipple={true}
          disableTouchRipple={true}
          disabled
          size='small'>
          <IconShareScreen size={20} />
        </IconButton>
      </div>
    );
  };

  const getScreenBadge = () => {
    return (
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        badgeContent={getScreenIcon()}
      >
        <Avatar name={name} />
      </Badge>
    );
  };

  const getAvatar = () => {
    return (
      <React.Fragment>
        {screen
          ? getScreenBadge()
          : <Avatar name={name} />
        }
      </React.Fragment>
    );
  };

  return (
    <div className={classes.root}>
      {getAvatar()}
      {isTalking &&
        <RippleBadge
          overlap="circle"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          variant="dot"
          classes={{ badge: classes.badge }}
        >
        </RippleBadge>
      }
    </div>
  );

}


export default connect(mapStateToProps)(RosterAvatar);
