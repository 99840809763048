/* Libraries Imports */
import React from 'react';
import { useIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';
/* Other Imports */
import { ExtendedMeeting } from '.';
import { State as HolocomSessionState } from '../../lib/reducers/session';
/* Local Style */
import useStyles from './style';


const messages = defineMessages({
  roomPstnAccess: { id: 'roomInfoPstnAccess' },
  roomPstnNotAvailable: { id: 'roomInfoPstnNotAvailable' },
  roomPstnNumber: { id: 'roomInfoPstnNumber' },
});


type MappedProps = {
  myUsername: HolocomSessionState['username'];
  roomPin: HolocomSessionState['roomNumber'];
}

type State = {
  session: HolocomSessionState;
}

type Props = {
  roomDetails: ExtendedMeeting;
} & MappedProps

const mapStateToProps = (state: State): MappedProps => {
  return {
    myUsername: state.session.username,
    roomPin: state.session.roomNumber,
  };
};


function ConfPstnAccess(props: Props) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  const getPin = (room: ExtendedMeeting) => {
    let pin = room.pin;
    const ownRoom = (room.organizer || {}).username === props.myUsername;
    if (room.room_type === 'personal' && ownRoom && props.roomPin != null) {
      // use pin from session, as it is updated in real time (e.g. when
      // user regenerates pins)
      pin = props.roomPin;
    }
    return pin;
  };

  const getConfPstnAccessComponent = (room: ExtendedMeeting) => {
    const pin = getPin(room);
    const msg = formatMessage(messages.roomPstnNumber, { number: room.pstnNumber, pin: pin });
    let value = (
      <div className={classes.content}>
        {msg}
      </div>
    );
    if (!(pin && room.number)) {
      value = (
        <div className={classes.content}>
          {formatMessage(messages.roomPstnNotAvailable)}
        </div>
      );
    }
    return (
      <div>
        <div className={classes.title}>
          {formatMessage(messages.roomPstnAccess)}
        </div>
        {value}
      </div>
    );
  };

  return (
    <div>
      {getConfPstnAccessComponent(props.roomDetails)}
    </div>
  );
}


export default connect(mapStateToProps)(ConfPstnAccess);