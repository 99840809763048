import { commitMutation } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';

const mutation = graphql`
mutation updateProfileMutation(
  $input: UpdateProfileInput!
) {
  updateProfile(input: $input) {
    errors {key, message, reason}
    user {
      meta {
        livestreaming {
          apiKey
          apiUrl
          publishUrl
        }
      }
    }
  }
}`;

// FIXME
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export function updateLivestreaming(environment, { apiKey, apiUrl, publishUrl }) {
  const variables = {
    input: {
      profile: {
        meta: { livestreaming: { apiKey, apiUrl, publishUrl } }
      }
    },
  };

  return updateProfile(environment, variables);
}

// FIXME
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export function updateProfile(environment, variables) {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment,
      {
        mutation,
        variables,
        // FIXME
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        onCompleted: ({ updateProfile: { errors, pins } }, otherErrors) => {
          if (otherErrors) {
            return reject(otherErrors);
          } else if (errors) {
            return reject(errors);
          }
          return resolve(pins);
        },
        onError: err => {
          reject(err);
        }
      }
    );
  });
}

