import { commitMutation } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';


const mutation = graphql`
mutation renewMyPinsMutation {
  renewMyPins {
    pins {
      roomNumber
      roomOwnerNumber
    }
    errors {
      key
      message
      reason
    }
  }
}
`;


// FIXME
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
export function renewMyPins(environment) {
  return new Promise((resolve, reject) => {
    commitMutation(
      environment,
      {
        mutation,
        variables: {},
        // FIXME
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        onCompleted: ({ renewMyPins: { errors, pins } }, otherErrors) => {
          if (otherErrors) {
            return reject(otherErrors);
          } else if (errors) {
            return reject(errors);
          }
          return resolve(pins);
        },
        onError: err => {
          reject(err);
        }
      }
    );
  });
}



