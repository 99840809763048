import { createSelector } from 'reselect';

import { createDeepEqualitySelector } from '.';
import { State } from '../reducers';

import { VideoRoom } from '../api/videoroom';


export function isThereAnyVideo(state: State) {
  const localVideo = (
    VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream)
    || state.room.screenStream
  );

  if (localVideo) {
    return true;
  }

  const roster = state.room.roster;
  return Object.keys(roster).some(
    (uid) => {
      const user = roster[uid];
      return (
        Boolean(VideoRoom.getVideoTrackFromStream(user.stream))
        || user.screen
        || user.screenPublisherData
        || user.streamPublisherData
      );
    }
  );
}

export function amModerator(state: State): boolean {
  const myUid = state.websocket.uid;
  if (myUid) {
    const myUser = state.room.roster[myUid];
    if (myUser && myUser.role) {
      return ['room_owner', 'room_moderator'].includes(myUser.role);
    }
  }
  return false;
}


export function isModerator(state: State, uid: string): boolean {
  const user = state.room.roster[uid];
  if (user && user.role) {
    return ['room_owner', 'room_moderator'].includes(user.role);
  }
  return false;
}


function _getModerators(state: State) {
  return Object.keys(state.room.roster).filter(
    (uid) => {
      const user = state.room.roster[uid];
      return ['room_owner', 'room_moderator'].includes(user.role);
    }
  );
}


export const getModerators = createDeepEqualitySelector([_getModerators], (mods) => mods);


function _publishersCount(state: State) {
  let count = 0;
  const roster = state.room.roster;
  Object.keys(roster).forEach(
    (uid) => {
      const user = roster[uid];
      if (user.streamPublisherData) {
        count += 1;
      }

      if (user.screenPublisherData) {
        count += 1;
      }
    }
  );
  return count;
}


export const publishersCount = createSelector([ _publishersCount ], (c: number) => c);


export function isGridLayout(state: State): boolean {
  return state.room.layout === 'default';
}

export function isPresentationLayout(state: State): boolean {
  return state.room.layout === 'featured';
}

export function isFullscreenLayout(state: State): boolean {
  return state.room.layout === 'fullscreen';
}

export function isAudioOnlyLayout(state: State): boolean {
  return state.room.layout === 'audioonly';
}

export function isWebinarLayout(state: State): boolean {
  return state.room.layout === 'webinar';
}


export function isLessonLayout(state: State): boolean {
  return state.room.layout === 'lesson';
}


export function amRoomOwner(state: State): boolean {
  const myUid = state.websocket.uid;
  if (myUid) {
    const myUser = state.room.roster[myUid];
    if (myUser && myUser.role) {
      return ['room_owner'].includes(myUser.role);
    }
  }
  return false;
}

/*
 * now WebinarPresenter == RoomOwner
 * but the logic can be changed here transparently
 */
export function amWebinarPresenter(state: State): boolean {
  return amRoomOwner(state);
}
