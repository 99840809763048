import React from 'react';
import { connect } from 'react-redux';

import { BrowserRouter, Route, Switch, RouteComponentProps } from 'react-router-dom';

import { State } from '../../lib/reducers';
import getRouteFor, { Route as HolocomRoute } from '../../lib/utils/router';
import LocalStorage from '../../localStorage';

import HomePage from '../HomePage';
import Login from '../Login';
import NotFound from '../NotFound';
import Meeting from '../Meeting';
import Appbar from '../Appbar';
import { OAuthConfig } from '../../lib/actions/appconfig';


function handleScreenShare() {
  // FIXME
  // implement this but not here
}


function enableDesktopControl() {
  // FIXME
  // implement this but not here
}


type Props = {
  localStore: LocalStorage;
} & MappedProps;


function HomeComponent(props: Props) {
  return (
    <React.Fragment>
      <Appbar localStore={props.localStore} />
      <HomePage />
    </React.Fragment>
  );
}


function LoginComponent(props: Props) {
  const { localStore, oauth } = props;
  return (
    <React.Fragment>
      <Login oauth={oauth} localStore={localStore} />
    </React.Fragment>
  );
}


function MeetingComponent(props: Props, { match }: RouteComponentProps<{id: string}>) {
  return (
    <React.Fragment>
      <Appbar
        localStore={props.localStore}
        url={match.url}
      />
      <Meeting
        handleScreenShare={handleScreenShare}
        enableDesktopControl={enableDesktopControl}
        localStore={props.localStore}
        meetingId={match.params.id}
        url={match.url}
      />
    </React.Fragment>
  );
}


function MainRouter(props: Props) {
  const getHomeCmp = React.useCallback(() => HomeComponent(props), [props]);
  const getLoginCmp = React.useCallback(() => LoginComponent(props), [props]);
  const getMeetingCmp = React.useCallback((routerProps) => MeetingComponent(props, routerProps), [props]);

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={getRouteFor(HolocomRoute.Home)}
          render={getHomeCmp}
        />
        {props.loginEnabled &&
          <Route
            exact
            path={getRouteFor(HolocomRoute.Login)}
            render={getLoginCmp}
          />
        }
        <Route
          exact
          path={getRouteFor(HolocomRoute.Meeting, { slug: ':id' })}
          render={getMeetingCmp}
        />
        <Route
          exact
          path={getRouteFor(HolocomRoute.RecordedMeeting, { slug: ':id' })}
          render={getMeetingCmp}
        />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
}


type MappedProps = {
  isAuthenticated: boolean;
  loginEnabled: boolean;
  oauth: Array<OAuthConfig>;
}


const mapStateToProps = (state: State): MappedProps => ({
  isAuthenticated: state.auth.isAuthenticated,
  loginEnabled: state.appconfig.login_enabled,
  oauth: state.appconfig.oauth,
});


export default connect(mapStateToProps)(MainRouter);
