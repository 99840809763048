import React  from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { useIntl, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import VideoElement from '../VideoElement';
import NoVideoElement from '../NoVideoElement';

import { State } from '../../lib/reducers';
import { StreamOptions } from '../../lib/redux_types';
import { getLogger } from '../../lib/logger';
import prepareWebRtcProvider from '../../rtc';
import { RtcDevices } from '../../lib/api/rtcDevices';
import { iconColors as colors } from '../../colors';


function getRtc() {
  const logger = getLogger('AVSettings');
  const webrtc = prepareWebRtcProvider();
  return new RtcDevices(webrtc, logger);
}


function acquireMedia(
  audioDev: State['settings']['audioInDevice'],
  videoDev: State['settings']['videoDevice'],
  videoQuality: State['settings']['videoQuality'],
  options: {video: boolean; qualityConstraint: StreamOptions['streamQuality']}
) {
  const rtc = getRtc();
  const audioId = (audioDev || { deviceId: undefined }).deviceId;
  const videoId = (videoDev || { deviceId: undefined }).deviceId;
  const quality = (videoQuality || { value: undefined }).value;
  const opts = { ...options, fallbackToAudioOnly: false };
  return rtc.getLocalStreamByDevices(audioId, videoId, quality, opts);
}


function stopStream(stream: null | MediaStream) {
  if (stream) {
    const rtc = getRtc();
    return rtc.stopStream(stream);
  }
}


type AnalyserCallback = (intensity: number) => void


const messages = defineMessages({
  deviceOverConstrained: { id: 'deviceOverConstrained' },
  deviceNotFound: { id: 'deviceNotFound' },
  deviceError: { id: 'deviceError' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    error: {
      color: theme.palette.error.main,
    },
  })
);


let audioContext: null | AudioContext = null;
function getAudioContext() {
  if (!audioContext && window.AudioContext) {
    audioContext = new window.AudioContext();
  }
  return audioContext;
}


// simple react hook to calculate sound pressure level in the given media
// stream
function useAudioAnalyser(stream: (null | MediaStream), callback: AnalyserCallback) {
  const reqRef = React.useRef<null | ReturnType<typeof requestAnimationFrame>>(null);

  React.useEffect(
    () => {
      if (!stream) {
        return;
      }

      const audioContext = getAudioContext();
      if (!audioContext) {
        return;
      }
      const analyser = audioContext.createAnalyser();
      const audioData = new Float32Array(analyser.frequencyBinCount);
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);

      const processAudio = () => {
        analyser.getFloatTimeDomainData(audioData);
        reqRef.current = requestAnimationFrame(processAudio);

        // audio data is an array with elements in the interval [-1, 1]
        // calculte the rms, and return the value (normalized by 100) to caller
        const squaredSum = audioData.reduce((acc, el) => acc + (el * el), 0);
        const rms = Math.sqrt(squaredSum / audioData.length);
        callback(Math.floor(rms * 100));
      };

      reqRef.current = requestAnimationFrame(processAudio);
      return () => {
        if (reqRef.current) {
          cancelAnimationFrame(reqRef.current);
        }
        analyser.disconnect();
        source.disconnect();
      };
    }
    , [stream, callback]
  );
}


function TalkingIndicator(props: {stream: null | MediaStream}) {
  const { stream } = props;

  const [intensity, setIntensity] = React.useState(0);

  useAudioAnalyser(stream, setIntensity);

  const scaledIntensity = intensity / 33; // scale it to be in the interval [1, 3]

  const centralHeight = scaledIntensity + 0.15;  // set a min height of 0.15
  const sideHeight = scaledIntensity / 2 + 0.15; // half the central height

  return (
    <div style={{
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
    }}>
      <div style={{
        margin: '1em',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
      }}>
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '2em',
          width: '2em',
        }}>
          <div style={{
            transition: 'height 75ms steps(4,jump-both)',
            animation: 'jiggle .6s linear 0s infinite',
            margin: '0.05em',
            backgroundColor: colors.active,
            width: '.25em',
            height: `${sideHeight}em`,
            borderRadius: '.125em',
          }}>
          </div>
          <div style={{
            transition: 'height 75ms steps(4,jump-both)',
            animation: 'jiggle .6s linear 0s infinite',
            margin: '0.05em',
            backgroundColor: colors.active,
            width: '.25em',
            height: `${centralHeight}em`,
            borderRadius: '.125em',
          }}>
          </div>
          <div style={{
            transition: 'height 75ms steps(4,jump-both)',
            animation: 'jiggle .6s linear 0s infinite',
            margin: '0.05em',
            backgroundColor: colors.active,
            width: '.25em',
            height: `${sideHeight}em`,
            borderRadius: '.125em',
          }}>
          </div>
        </div>
      </div>
    </div>
  );
}


function DevicePreview(props: ExtendedProps) {
  const { audioInDevice, videoDevice, videoQuality, videoEnabled, roomOptions } = props;

  const qualityConstraint = roomOptions.stream_quality;
  const [stream, setStream] = React.useState<null | MediaStream>(null);
  const [devError, setDevError] = React.useState<null | Error>(null);

  const streamRef = React.useRef(stream);

  React.useEffect(
    () => {
      acquireMedia(audioInDevice, videoDevice, videoQuality, { video: videoEnabled, qualityConstraint }).then(
        (s: MediaStream) => {
          setStream(s);
          streamRef.current = s;
          setDevError(null);
        }
      ).catch((e: Error) => {
        setStream(null);
        streamRef.current = null;
        setDevError(e);
      });
      return () => {
        stopStream(streamRef.current);
        setStream(null);
        streamRef.current = null;
        setDevError(null);
      };
    }
    , [setStream, setDevError, audioInDevice, videoDevice, videoQuality, videoEnabled, qualityConstraint]
  );

  return (
    <div style={{ height: '100%', position: 'relative' }}>
      { (stream && props.videoEnabled) ?
        <VideoElement
          muted
          mirrored
          fullHeight={false}
          src={stream}
        />
        : <NoVideoElement />
      }
      <TalkingIndicator stream={stream} />
      <StreamError error={devError} />
    </div>
  );
}


function StreamError({ error }: { error: null | { name?: string; constraint?: string } }) {
  const { formatMessage } = useIntl();
  const classes = useStyles();

  if (!error) {
    return null;
  }

  let msg = formatMessage(messages.deviceError);
  if (error.name && error.name === 'OverconstrainedError' && error.constraint) {
    const reason = error.constraint;
    if (reason === 'deviceId') {
      msg = formatMessage(messages.deviceNotFound);
    }
    else if (reason === 'width' || reason === 'height') {
      msg = formatMessage(messages.deviceOverConstrained);
    }
  }
  else if (error.name && error.name === 'NotFoundError') {
    msg = formatMessage(messages.deviceNotFound);
  }

  return (
    <div className={classes.error}>
      {msg}
    </div>
  );
}


type Props = {
  videoEnabled: boolean;
}


type MappedProps = Pick<State['settings'],
    'audioInDevice'
  | 'audioOutDevice'
  | 'videoDevice'
  | 'videoQuality'
  >
  & {
    roomOptions: State['appconfig']['room_options'];
  }


type ExtendedProps = Props & MappedProps


const mapStateToProps = (state: State): MappedProps => {
  return {
    audioInDevice: state.settings.audioInDevice,
    audioOutDevice: state.settings.audioOutDevice,
    videoDevice: state.settings.videoDevice,
    videoQuality: state.settings.videoQuality,
    roomOptions: state.appconfig.room_options,
  };
};


export default connect(mapStateToProps)(DevicePreview);
