/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type DeleteRecordingInput = {
    readonly recording?: RecordingInputDelete | null;
};
export type RecordingInputDelete = {
    readonly domainId: string;
    readonly id: string;
    readonly userId: string;
};
export type deleteRecordingMutationVariables = {
    input: DeleteRecordingInput;
};
export type deleteRecordingMutationResponse = {
    readonly deleteRecording: {
        readonly errors: ReadonlyArray<{
            readonly key: string;
            readonly message: ReadonlyArray<string | null> | null;
            readonly reason: ReadonlyArray<string | null> | null;
        } | null> | null;
        readonly recording: {
            readonly id: string;
            readonly userId: string | null;
            readonly domainId: string | null;
        } | null;
    } | null;
};
export type deleteRecordingMutation = {
    readonly response: deleteRecordingMutationResponse;
    readonly variables: deleteRecordingMutationVariables;
};



/*
mutation deleteRecordingMutation(
  $input: DeleteRecordingInput!
) {
  deleteRecording(input: $input) {
    errors {
      key
      message
      reason
    }
    recording {
      id
      userId
      domainId
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "DeleteRecordingInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "deleteRecording",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteRecordingPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "InputError",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "key",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reason",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "recording",
        "storageKey": null,
        "args": null,
        "concreteType": "Recording",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "userId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "domainId",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "deleteRecordingMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "deleteRecordingMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "deleteRecordingMutation",
    "id": null,
    "text": "mutation deleteRecordingMutation(\n  $input: DeleteRecordingInput!\n) {\n  deleteRecording(input: $input) {\n    errors {\n      key\n      message\n      reason\n    }\n    recording {\n      id\n      userId\n      domainId\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '32854592ffaf4097558dc9ec7ac6174d';
export default node;
