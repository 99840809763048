import { createStyles, Theme } from '@material-ui/core/styles';
const style = (theme: Theme) => createStyles({

  container: {
    margin: theme.spacing(3),
    width: '100%',
  },

  dtContainer: {
    marginRight: theme.spacing(1),
  },

  createMeetingButton: {
    width: '100%',
    marginTop: theme.spacing(5),
    textAlign: 'right',
  },

  dayString: {
    textTransform: 'uppercase',
  },

  title: {
    textTransform: 'capitalize',
  },

  dayDivider: {
    margin: theme.spacing(1),
  },

  meetingList: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
    /**
     * min and max height are set because:
     * 1) minHeight sets minimum space between meetlingList and create button
     * 2) maxHeight avoids having the create button too far below when there are many meetings
     */
    [theme.breakpoints.up('sm')]: {
      minHeight: '33vh',
      maxHeight: '50vh',
    },
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingRight: theme.spacing(1),
    paddingTop: 0,
    width: '100%',
    margin: 0,

  },

  actionButtons: {
    textAlign: 'end',
    alignSelf: 'center',
  },

  detailsInfo: {
    alignSelf: 'center',
  },

  buttonRight: {
    textAlign: 'right',
  },

  buttonNoStyle: {
    margin: 'auto',
    minWidth: 'auto'
  }
});

export default style;
