import React from 'react';

import iconSet from './fontset.json';
import IcomoonReact from 'icomoon-react';


export interface IconSetProps {
  size: number;
  color?: string;
}


/*
function generateIcons() {
  const iconNames = iconSet.icons.map(x => x.properties.name);

  function camelize(s: string) {
    const words = s.split('-');
    const capitalizedWords = words.map((w: string) =>
      w.charAt(0).toUpperCase() + w.slice(1)
    );
    return capitalizedWords.join('');
  }

  iconNames.forEach(n => {
    const s = camelize(n);
    console.log(`
export function Icon${s}(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"${n}"}
    />
  );
}`);
  });
}
console.log(generateIcons());
*/


// All the icons below are automatically generated using the generateIcons()
// above.


export function IconSleepyCalendar(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"sleepy-calendar"}
    />
  );
}

export function IconCalendarNewEvent(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"calendar-new-event"}
    />
  );
}

export function IconRocket(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"rocket"}
    />
  );
}

export function IconDoor(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"door"}
    />
  );
}

export function IconModerator(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"moderator"}
    />
  );
}

export function IconArrowDown(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"arrow-down"}
    />
  );
}

export function IconCalendar(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"calendar"}
    />
  );
}

export function IconChevronDown(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"chevron-down"}
    />
  );
}

export function IconChevronLeft(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"chevron-left"}
    />
  );
}

export function IconChevronRight(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"chevron-right"}
    />
  );
}

export function IconClose(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"close"}
    />
  );
}

export function IconDuplicate(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"duplicate"}
    />
  );
}

export function IconEdit(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"edit"}
    />
  );
}

export function IconEmail(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"email"}
    />
  );
}

export function IconEsc(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"esc"}
    />
  );
}

export function IconEyeClosed(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"eye-closed"}
    />
  );
}

export function IconEye(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"eye"}
    />
  );
}

export function IconHandUp(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"hand-up"}
    />
  );
}

export function IconHomeSelected(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"home-selected"}
    />
  );
}

export function IconHome(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"home"}
    />
  );
}

export function IconLayoutSelected(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"layout-selected"}
    />
  );
}

export function IconLayoutGrid(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"layout-grid"}
    />
  );
}

export function IconLayout(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"layout"}
    />
  );
}

export function IconLockedSelected(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"locked-selected"}
    />
  );
}

export function IconLocked(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"locked"}
    />
  );
}

export function IconLogout(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"logout"}
    />
  );
}

export function IconMaximize(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"maximize"}
    />
  );
}

export function IconMessageSelected(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"message-selected"}
    />
  );
}

export function IconMessage(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"message"}
    />
  );
}

export function IconMicrophoneOff(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"microphone-off"}
    />
  );
}

export function IconMicrophone(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"microphone"}
    />
  );
}

export function IconMinimize(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"minimize"}
    />
  );
}

export function IconMore(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"more"}
    />
  );
}

export function IconPhone(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"phone"}
    />
  );
}

export function IconRecordOn(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"record-on"}
    />
  );
}

export function IconRecord(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"record"}
    />
  );
}

export function IconSearch(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"search"}
    />
  );
}

export function IconSend(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"send"}
    />
  );
}

export function IconSettingsSelected(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"settings-selected"}
    />
  );
}

export function IconSettings(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"settings"}
    />
  );
}

export function IconShareScreen(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"share-screen"}
    />
  );
}

export function IconStats(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"stats"}
    />
  );
}

export function IconStop(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"stop"}
    />
  );
}

export function IconStreaming(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"streaming"}
    />
  );
}

export function IconTrash(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"trash"}
    />
  );
}

export function IconUnlockedSelected(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"unlocked-selected"}
    />
  );
}

export function IconUnlocked(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"unlocked"}
    />
  );
}

export function IconUserAdd(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"user-add"}
    />
  );
}

export function IconUser(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"user"}
    />
  );
}

export function IconUsersSelected(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"users-selected"}
    />
  );
}

export function IconUsers(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"users"}
    />
  );
}

export function IconVideoOff(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"video-off"}
    />
  );
}

export function IconVideo(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"video"}
    />
  );
}

export function IconKey(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"key"}
    />
  );
}

export function IconStreamingActive(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"streaming-attivo"}
    />
  );
}

export function IconUnshare(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"unshare_screen-32"}
    />
  );
}

export function IconModeratorOff(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"moderator-off"}
    />
  );
}

export function IconMiniplayer(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"miniplayer"}
    />
  );
}

export function IconShrink(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"shrink"}
    />
  );
}

export function IconLayoutAudio(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"layout-audio"}
    />
  );
}

export function IconLayoutWebinar(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"layout-webinar"}
    />
  );
}

export function IconExpand(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"expand"}
    />
  );
}

export function IconInfo(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"info"}
    />
  );
}

export function IconPlay(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"play"}
    />
  );
}

export function IconReload(props: IconSetProps) {
  const { size } = props;
  const color = props.color ? props.color : '';

  return (
    <IcomoonReact
      iconSet={iconSet}
      color={color}
      size={size}
      icon={"reload"}
    />
  );
}
