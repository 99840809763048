import { VideoRoom } from '../../lib/api/videoroom';
import { State as RoomState } from '../../lib/reducers/room';
import { State as WebsocketState } from '../../lib/reducers/websocket';


type State = {
  room: RoomState;
  websocket: WebsocketState;
}

export function hasVideoStream(videoStream: MediaStream) {
  let hasVideoStream = false;
  if (videoStream) {
    hasVideoStream = Boolean(VideoRoom.getVideoTrackFromStream(videoStream));
  }
  return hasVideoStream;
}

export function isVideoEnabledForUser(uid: string, state: State) {
  let user = null;

  const myUid = state.websocket.uid;

  const isScreen = uid.endsWith('_screen');
  if (uid) {
    user = state.room.roster[uid.replace(/_screen$/, '')];
  }

  const mySelf = myUid === uid;
  let videoStream = false;
  if (isScreen) {
    if (mySelf && state.room && state.room.screenStream) {
      videoStream = hasVideoStream(state.room.screenStream);
    }
    else if (user && user.screen) {
      videoStream = hasVideoStream(user.screen);
    }
  }
  else {
    if (mySelf && state.room && state.room.localvideo_stream) {
      videoStream = hasVideoStream(state.room.localvideo_stream);
    }
    else if (user && user.stream) {
      videoStream = hasVideoStream(user.stream);
    }
  }

  return videoStream;
}
