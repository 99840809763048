/* tslint:disable */

import { ConcreteRequest } from "relay-runtime";
export type UpdateMeetingInput = {
    readonly meeting?: MeetingInputUpdate | null;
};
export type MeetingInputUpdate = {
    readonly attendees?: ReadonlyArray<AttendeeInput | null> | null;
    readonly doNotify?: boolean | null;
    readonly domainId: string;
    readonly dtEnd: string;
    readonly dtStart: string;
    readonly id: string;
    readonly notes?: string | null;
    readonly number?: string | null;
    readonly title: string;
    readonly userId: string;
};
export type AttendeeInput = {
    readonly email: string;
};
export type updateMeetingMutationVariables = {
    input: UpdateMeetingInput;
};
export type updateMeetingMutationResponse = {
    readonly updateMeeting: {
        readonly errors: ReadonlyArray<{
            readonly key: string;
            readonly message: ReadonlyArray<string | null> | null;
            readonly reason: ReadonlyArray<string | null> | null;
        } | null> | null;
        readonly meeting: {
            readonly id: string;
            readonly userId: string;
            readonly domainId: string;
            readonly title: string;
            readonly dtStart: string;
            readonly dtEnd: string;
            readonly attendees: ReadonlyArray<{
                readonly email: string;
            }>;
            readonly notes: string | null;
        } | null;
    } | null;
};
export type updateMeetingMutation = {
    readonly response: updateMeetingMutationResponse;
    readonly variables: updateMeetingMutationVariables;
};



/*
mutation updateMeetingMutation(
  $input: UpdateMeetingInput!
) {
  updateMeeting(input: $input) {
    errors {
      key
      message
      reason
    }
    meeting {
      id
      userId
      domainId
      title
      dtStart
      dtEnd
      attendees {
        email
      }
      notes
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UpdateMeetingInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "updateMeeting",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateMeetingPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "errors",
        "storageKey": null,
        "args": null,
        "concreteType": "InputError",
        "plural": true,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "key",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "message",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "reason",
            "args": null,
            "storageKey": null
          }
        ]
      },
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "meeting",
        "storageKey": null,
        "args": null,
        "concreteType": "Meeting",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "userId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "domainId",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "title",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dtStart",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "dtEnd",
            "args": null,
            "storageKey": null
          },
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "attendees",
            "storageKey": null,
            "args": null,
            "concreteType": "Attendee",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "alias": null,
                "name": "email",
                "args": null,
                "storageKey": null
              }
            ]
          },
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "notes",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "updateMeetingMutation",
    "type": "RootMutationType",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "updateMeetingMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "updateMeetingMutation",
    "id": null,
    "text": "mutation updateMeetingMutation(\n  $input: UpdateMeetingInput!\n) {\n  updateMeeting(input: $input) {\n    errors {\n      key\n      message\n      reason\n    }\n    meeting {\n      id\n      userId\n      domainId\n      title\n      dtStart\n      dtEnd\n      attendees {\n        email\n      }\n      notes\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
(node as any).hash = '0b5fd214eb8abc07fe6a24e4598e603a';
export default node;
