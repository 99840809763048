import React from 'react';
import { connect } from 'react-redux';
import { defineMessages, useIntl } from 'react-intl';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { IconButton, ListItem, ListItemText, Tabs } from '@material-ui/core';

import { State } from '../../lib/reducers';
import { PublicChatTabLabel, PublicChatTab } from './PublicChatTab';
import { PrivateChatTabLabel, PrivateChatTab } from './PrivateChatTab';
import { IconUserAdd } from '../IconSet';
import SelectDestUserDialog from './SelectDestUserDialog';
import { canSendPrivateMessage, getPrivateMessagesKeys } from './utils';


const messages = defineMessages({
  chat: { id: 'chat' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    panel: {
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'flex-start',
    },
    tabsHeader: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: theme.spacing(1),
    }
  })
);


function ChatPanel(props: ExtendedProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [value, setValue] = React.useState(0);

  const [userSelectionOpen, setUserSelectionOpen] = React.useState<boolean>(false);

  const showUserSelection = (): void => {
    setUserSelectionOpen(true);
  };

  const closeUserSelection = (): void => {
    setUserSelectionOpen(false);
  };

  const handleChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const privateTabsLabel = () => {
    const tabs = props.privateMessages.map((key, idx) => {
      return (
        [
          <PrivateChatTabLabel index={idx + 1} currVal={value} user={key} key={key} />
        ]
      );
    });

    return tabs;
  };


  const privateTabs = () => {
    const tabs = props.privateMessages.map((key, idx) => {
      return (
        [
          <PrivateChatTab index={idx + 1} currVal={value} user={key} key={idx} />
        ]
      );
    });

    return tabs;
  };

  return (
    <div className={classes.panel}>
      <div className={classes.tabsHeader}>
        <ListItem>
          <ListItemText primary={formatMessage(messages.chat)} />
        </ListItem>
        { props.canSendPrivateMessage &&
            <IconButton onClick={showUserSelection}>
              <IconUserAdd size={22} />
            </IconButton>
        }
      </div>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
      >
        <PublicChatTabLabel index={0} currVal={value} />
        { privateTabsLabel() }
      </Tabs>
      <PublicChatTab index={0} currVal={value} />
      { privateTabs() }
      { userSelectionOpen && <SelectDestUserDialog onClose={closeUserSelection} /> }
    </div>
  );
}


type Props = {}

type MappedProps = {
  privateMessages: string[];
  canSendPrivateMessage: boolean;
}

type ExtendedProps = Props & MappedProps

const mapStateToProps = (state: State): MappedProps => ({
  privateMessages: getPrivateMessagesKeys(state.room.privateMessages),
  canSendPrivateMessage: canSendPrivateMessage(state.room.roster),
});


export default connect(mapStateToProps)(ChatPanel);
