import update from 'immutability-helper';

import { VideoRoom } from '../api/videoroom';
import { AudioRoom } from '../api/audioroom';
import { ChatRoom } from '../api/chatroom';
import {
  UserMessage,
  Roster,
  RosterUser,
  RoomLayout,
  RoomLayoutConfig,
  Stream,
  Error,
  ScreenSourceType,
  ChatMessages,
  EventMessage,
  PrivateUserMessage,
  PrivateUserMessages,
  UnreadPrivateUserMessages,
  LastMessage,
} from '../redux_types';

import {
  ROOM_CREATED,
  LOCALSTREAM_VIDEO_ADD,
  LOCALSTREAM_VIDEO_REMOVE,
  LOCALSTREAM_AUDIO_ADD,
  LOCALSTREAM_AUDIO_REMOVE,
  LOCALSTREAM_ERROR,
  REMOTESTREAM_VIDEO_ADD,
  REMOTESTREAM_VIDEO_REMOVE,
  REMOTESTREAM_VIDEO_REMOVE_ALL,
  SCREEN_REQUESTED,
  SCREEN_ERROR,
  SCREEN_VIDEO_ADD,
  SCREEN_VIDEO_REMOVE,
  TALKING_CHANGE,
  MUTEVIDEO,
  MUTEVIDEO_REQUEST,
  MUTEVIDEO_REQUEST_ERROR,
  MUTEAUDIO_REQUEST_ERROR,
  MUTEAUDIO,
  MUTEAUDIO_REQUEST,
  MUTEALLAUDIO_REQUEST,
  MUTEALLAUDIO_DONE,
  CHANGE_USER_ROLE_REQUEST,
  CHANGE_USER_ROLE_SUCCESS,
  CHANGE_USER_ROLE_ERROR,
  APPLY_LAYOUT_REQUEST,
  APPLY_LAYOUT_SUCCESS,
  APPLY_LAYOUT_ERROR,
  LOCK_ROOM_REQUEST,
  LOCK_ROOM_SUCCESS,
  LOCK_ROOM_ERROR,
  FORCE_LAYOUT,
  KICK_USER_REQUEST,
  KICK_USER_SUCCESS,
  KICK_USER_ERROR,
  EXPAND_STREAMVIDEO,
  ENLARGE_STREAMVIDEO,
  PIP_USER,
  FULLSCREEN_USER,
  CHANGE_LAYOUT,
  SET_CHAT_OPEN,
  PUBLIC_USERMESSAGE,
  PUBLIC_USERMESSAGE_RESET_UNREAD,
  PRIVATE_USERMESSAGE,
  PRIVATE_USERMESSAGE_RESET_UNREAD,
  PRIVATE_USERMESSAGE_RESET_UNREAD_THREAD,
  EVENTMESSAGE,
  ROOM_ERROR,
  ROOM_ERROR_ACKED,
  START_RECORDING_REQUEST,
  START_RECORDING_SUCCESS,
  START_RECORDING_ERROR,
  STOP_RECORDING_REQUEST,
  STOP_RECORDING_SUCCESS,
  STOP_RECORDING_ERROR,
  START_LIVESTREAMING_REQUEST,
  START_LIVESTREAMING_SUCCESS,
  START_LIVESTREAMING_ERROR,
  STOP_LIVESTREAMING_REQUEST,
  STOP_LIVESTREAMING_SUCCESS,
  STOP_LIVESTREAMING_ERROR,
  SET_RECORDABLE,
  ENABLE_DESKTOP_CONTROL,
  DESKTOP_CONTROL_REQUESTED,
  SET_LIVESTREAMABLE,
  DURATION_UPDATE,
  TOGGLE_DESKSHARE,
  TOGGLE_DIALOUT,
  PUBLISHER_ADD,
  PUBLISHER_REMOVE,
  SUBSCRIBE_VIDEO_REQUEST,
  SUBSCRIBE_VIDEO_REQUEST_ERROR,
  SUBSCRIBE_VIDEO_SUCCESS,
  ADD_OBSERVED_SCREEN,
  REMOVE_OBSERVED_SCREEN,
  Action,
} from '../actions/room';

import {
  ROSTER_CHANGE,
  ROSTER_RESET,
  ROSTER_SET_AUDIO,
} from '../actions/websocket';


export interface State {
  localvideo_stream: null | Stream;
  localvideo_stream_ts: number;
  audio_stream: null | Stream;
  roomObject: null | VideoRoom;
  roomAudio: null | AudioRoom;
  chatRoom: null | ChatRoom;
  roomError: null | Error & { recoverable: boolean };
  isRequestingScreen: boolean;
  screenStream: null | Stream;
  screenSourceType: null | ScreenSourceType;
  localscreen_stream_ts: number;
  screenError: null | string;
  streamError: null | string;
  roster: Roster;
  isChatOpen: boolean;
  publicMessages: ChatMessages;
  unreadPublicMessages: number;
  privateMessages: PrivateUserMessages;
  unreadPrivateMessages: UnreadPrivateUserMessages;
  lastMessage: LastMessage;
  layout: RoomLayout;
  layoutConfig: RoomLayoutConfig;
  isRequestingApplyLayout: boolean;
  isRequestingLockRoom: boolean;
  isLocked: boolean;
  isLayoutChanged: boolean;
  isRecording: boolean;
  isRequestingRecordingOp: boolean;
  isStreaming: boolean;
  livestreamingEnabled: boolean;
  isRequestingStreamingOp: boolean;
  recordingEnabled: boolean;
  deskShareEnabled: boolean;
  dialoutEnabled: boolean;
  elapsed: number;
  pipEnabled: null | string;
  fullScreenEnabled: null | string;
}


const defaultState: State = {
  // eslint-disable-next-line @typescript-eslint/camelcase
  localvideo_stream: null,
  // eslint-disable-next-line @typescript-eslint/camelcase
  localvideo_stream_ts: 0,
  // eslint-disable-next-line @typescript-eslint/camelcase
  audio_stream: null,
  roomObject: null,
  roomAudio: null,
  chatRoom: null,
  roomError: null,
  isRequestingScreen: false,
  screenStream: null,
  screenSourceType: null,
  // eslint-disable-next-line @typescript-eslint/camelcase
  localscreen_stream_ts: 0,
  screenError: null,
  streamError: null,
  roster: {} as Roster,
  isChatOpen: false,
  publicMessages: [],
  unreadPublicMessages: 0,
  privateMessages: {},
  unreadPrivateMessages: {},
  lastMessage: {} as LastMessage,
  layout: "default",
  layoutConfig: {},
  isRequestingApplyLayout: false,
  isRequestingLockRoom: false,
  isLocked: false,
  isLayoutChanged: false,
  isRecording: false,
  isRequestingRecordingOp: false,
  isStreaming: false,
  livestreamingEnabled: false,
  isRequestingStreamingOp: false,
  recordingEnabled: false,
  deskShareEnabled: false,
  dialoutEnabled: false,
  elapsed: 0,
  pipEnabled: null,
  fullScreenEnabled: null,
};



// FIXME: move to websocket actions when it is converted to ts
interface RosterChangeAction {
  type: typeof ROSTER_CHANGE;
  payload: {
    participants: Roster;
  };
}
interface RosterResetAction {
  type: typeof ROSTER_RESET;
  payload: {
  };
}
interface RosterSetAudioAction {
  type: typeof ROSTER_SET_AUDIO;
  payload: {
    participants: Roster;
  };
}
type RosterAction = RosterChangeAction | RosterResetAction | RosterSetAudioAction


function room(state = defaultState, action: Action | RosterAction) {
  switch (action.type) {
    case ROOM_CREATED:
      return Object.assign({}, state, {
        roomObject: action.payload.roomObject,
        roomAudio: action.payload.roomAudio,
        chatRoom: action.payload.chatRoom,
      });
    case LOCALSTREAM_VIDEO_ADD:
      return Object.assign({}, state, {
        // eslint-disable-next-line @typescript-eslint/camelcase
        localvideo_stream: action.payload.stream,
        // eslint-disable-next-line @typescript-eslint/camelcase
        localvideo_stream_ts: Math.floor(Date.now() / 1000),
        streamError: null,
      });
    case LOCALSTREAM_VIDEO_REMOVE:
      return Object.assign({}, state, {
        // eslint-disable-next-line @typescript-eslint/camelcase
        localvideo_stream: null,
        // eslint-disable-next-line @typescript-eslint/camelcase
        localvideo_stream_ts: 0,
        roomObject: null,
        roomAudio: null,
        chatRoom: null,
        streamError: null,
        publicMessages: [],
        unreadPublicMessages: 0,
        privateMessages: {},
        unreadPrivateMessages: {},
        layout: "default",
        layoutConfig: {},
        isLayoutChanged: false,
        isRecording: false,
        isStreaming: false,
      });
    case LOCALSTREAM_ERROR:
      return Object.assign({}, state, {
        streamError: action.payload.errorMessage,
      });

    case LOCALSTREAM_AUDIO_ADD:
      return Object.assign({}, state, {
        // eslint-disable-next-line @typescript-eslint/camelcase
        audio_stream: action.payload.audio_stream,
      });
    case LOCALSTREAM_AUDIO_REMOVE:
      return Object.assign({}, state, {
        // eslint-disable-next-line @typescript-eslint/camelcase
        audio_stream: null,
      });
    case PUBLISHER_ADD: {
      const uid = action.payload.publisher.display.replace(/_screen$/, '');
      if (!state.roster[uid]) {
        return state;
      }

      const key =
        action.payload.publisher.display.endsWith('_screen')
          ? "screenPublisherData"
          : "streamPublisherData";
      const newState = update(state, {
        roster: {
          [uid]: {
            [key]: {
              $set: {
                ...action.payload.publisher,
                subscribed: false,
                subscribeRequested: false,
                subscribeError: false
              },
            },
          },
        },
      });
      return newState;
    }
    case PUBLISHER_REMOVE: {
      const publisherId = action.payload.publisherId;
      const uid = Object.keys(state.roster).find(
        (uid) => {
          const user = state.roster[uid];
          return (
            user
            && user.streamPublisherData
            && user.streamPublisherData.id === publisherId
          ) || (
            user
            && user.screenPublisherData
            && user.screenPublisherData.id === publisherId
          );
        }
      );
      if (uid && state.roster[uid]) {
        const user = state.roster[uid];
        const key =
          user.streamPublisherData && user.streamPublisherData.id === publisherId
            ? 'streamPublisherData'
            : 'screenPublisherData';
        const newState = update(state, {
          roster: {
            [uid]: {
              [key]: {
                $set: null,
              },
            },
          },
        });
        return newState;
      }
      else {
        return state;
      }
    }
    case SUBSCRIBE_VIDEO_REQUEST: {
      const uid = action.payload.userId;
      const userData = state.roster[uid];
      if (!userData) {
        return state;
      }

      const key =
        action.payload.type === 'screen'
          ? "screenPublisherData"
          : "streamPublisherData";
      const newState = update(state, {
        roster: {
          [uid]: {
            [key]: {
              $set: { ...userData[key], subscribeRequested: true, subscribeError: false }
            },
          },
        },
      });
      return newState;
    }
    case SUBSCRIBE_VIDEO_SUCCESS: {
      const uid = action.payload.userId;
      const key =
        action.payload.type === 'screen'
          ? "screenPublisherData"
          : "streamPublisherData";

      const userData = state.roster[uid];
      if (!userData || !userData[key]) {
        return state;
      }

      const newState = update(state, {
        roster: {
          [uid]: {
            [key]: {
              $set: { ...userData[key], subscribeRequested: false, subscribed: true, subscribeError: false }
            },
          },
        },
      });
      return newState;
    }
    case SUBSCRIBE_VIDEO_REQUEST_ERROR: {
      const uid = action.payload.userId;
      const userData = state.roster[uid];
      if (!userData) {
        return state;
      }

      const key =
        action.payload.type === 'screen'
          ? "screenPublisherData"
          : "streamPublisherData";
      const newState = update(state, {
        roster: {
          [uid]: {
            [key]: {
              $set: { ...userData[key], subscribeRequested: false, subscribed: false, subscribeError: true }
            },
          },
        },
      });
      return newState;
    }
    case REMOTESTREAM_VIDEO_ADD: {
      const username = action.payload.user_id;
      if (!state.roster[username]) {
        // user is not there? ignore stream
        // TODO: this may happen if the presence_diff event arrives from server
        //       after the new stream. It may happens when the server is
        //       clustered and presence delta broadcast interval is too big.
        //       Should we add the stream anyway and update the rest of user
        //       info later?
        //       See #holocom-stack/170
        return state;
      }
      const what = (action.payload.type === 'remotescreenevent') ? 'screen' : 'stream';
      const mutedKey = (action.payload.type === 'remotescreenevent') ? 'isScreenMuted' : 'isVideoMuted';
      let tsKey = 'stream_ts';
      const streamTs = Math.floor(Date.now() / 1000);
      if (what === 'screen') {
        tsKey = 'screen_ts';
      }
      const muted = VideoRoom.isStreamMuted(action.payload.stream);
      const newState = update(state, {
        roster: {
          [username]: {
            [what]: {
              $set: action.payload.stream,
            },
            [tsKey]: {
              $set: streamTs,
            },
            [mutedKey]: {
              $set: muted,
            },
          },
        },
      });
      return newState;
    }
    case REMOTESTREAM_VIDEO_REMOVE: {
      const username = action.payload.user_id;
      if (!state.roster[username]) {
        // user has gone away, e.g. the phoenix channel disconnected
        return state;
      }
      const what = (action.payload.type === 'remotescreenevent') ? 'screen' : 'stream';
      // eslint-disable-next-line @typescript-eslint/camelcase
      let ts_key = 'stream_ts';
      if (what === 'screen') {
        // eslint-disable-next-line @typescript-eslint/camelcase
        ts_key = 'screen_ts';
      }
      const newState = update(state, {
        roster: {
          [username]: {
            [what]: {
              $set: null,
            },
            // eslint-disable-next-line @typescript-eslint/camelcase
            [ts_key]: {
              $set: 0,
            },
          },
        },
      });
      return newState;
    }
    case REMOTESTREAM_VIDEO_REMOVE_ALL: {
      const newRoster: Roster = {};
      Object.keys(state.roster).forEach((key) => {
        const userState = { ...state.roster[key], stream: null, screen: null };
        newRoster[key] = userState;
      });
      return { ...state, roster: newRoster };
    }
    case SCREEN_REQUESTED:
      return Object.assign({}, state, {
        isRequestingScreen: true,
        screenError: null,
        errorCode: null,
      });
    case SCREEN_ERROR:
      return Object.assign({}, state, {
        isRequestingScreen: false,
        screenError: action.payload.errorMessage,
      });
    case SCREEN_VIDEO_ADD:
      return Object.assign({}, state, {
        isRequestingScreen: false,
        screenStream: action.payload.stream,
        screenSourceType: action.payload.screenSourceType,
        // eslint-disable-next-line @typescript-eslint/camelcase
        localscreen_stream_ts: Math.floor(Date.now() / 1000),
      });
    case SCREEN_VIDEO_REMOVE:
      return Object.assign({}, state, {
        isRequestingScreen: false,
        screenStream: null,
        screenSourceType: null,
        // eslint-disable-next-line @typescript-eslint/camelcase
        localscreen_stream_ts: 0,
        elapsed: 0,
      });
    case ROOM_ERROR:
      return Object.assign({}, state, {
        roomError: { ...action.payload.error, recoverable: action.payload.recoverable },
      });
    case ROOM_ERROR_ACKED:
      return Object.assign({}, state, {
        roomError: null
      });
    case ROSTER_CHANGE: {
      const newparts: Roster = {};
      Object.entries(action.payload.participants).forEach(([key, value]) => {
        let partState: RosterUser;
        if (state.roster[key]) {
          partState = Object.assign({}, state.roster[key]);
        }
        else {
          partState = {
            // eslint-disable-next-line @typescript-eslint/camelcase
            is_talking: false,
            stream: null,
            streamPublisherData: null,
            screen: null,
            screenPublisherData: null,
            isVideoMuted: false,
            isScreenMuted: false,
            isRequestingVideoMute: false,
            isRequestingAudioMute: false,
            isRequestingAllAudioMute: false,
            isRequestingRoleChange: false,
            isRequestingUserKick: false,
            isRequestingDesktopControl: false,
            isDesktopControlEnabled: false,
            isAudioMuted: false,
            // eslint-disable-next-line @typescript-eslint/camelcase
            video_uplink_quality: 'low',
            // eslint-disable-next-line @typescript-eslint/camelcase
            screen_uplink_quality: 'low',
            // eslint-disable-next-line @typescript-eslint/camelcase
            screen_ts: 0,
            // eslint-disable-next-line @typescript-eslint/camelcase
            stream_ts: 0,
            isControllingDesktop: false,
            isSharingDesktopControl: false,
            hasStartedDrawing: false,
            viaPhone: false,
            desktopControlType: '',
            display: '',
            username: '',
            role: 'room_user',
            muted: false,
            onlineAt: null,
          };
        }
        partState.display = value.display;
        partState.viaPhone = value.viaPhone;
        partState.role = value.role;
        partState.username = value.username;
        // eslint-disable-next-line @typescript-eslint/camelcase
        partState.video_uplink_quality = value.video_uplink_quality;
        // eslint-disable-next-line @typescript-eslint/camelcase
        partState.screen_uplink_quality = value.screen_uplink_quality;
        partState.isControllingDesktop = value.isControllingDesktop;
        partState.hasStartedDrawing = value.hasStartedDrawing;
        partState.isSharingDesktopControl = value.isSharingDesktopControl;
        partState.onlineAt = value.onlineAt;
        newparts[key] = partState;
      });
      return Object.assign({}, state, {
        roster: newparts,
      });
    }
    case ROSTER_RESET:
      return Object.assign({}, state, {
        roster: {}
      });
    case ROSTER_SET_AUDIO: {
      const newparts: Roster = {};
      Object.entries(state.roster).forEach(([key, value]) => {
        const stateToSet = action.payload.participants[key];
        if (stateToSet) {
          const newvalue = Object.assign({}, value);
          newvalue.isAudioMuted = stateToSet.muted;
          newparts[key] = newvalue;
        }
        else {
          newparts[key] = value;
        }
      });
      return Object.assign({}, state, {
        roster: newparts,
      });
    }
    case TALKING_CHANGE: {
      const username = action.payload.username;
      const talking = action.payload.is_talking;
      if (!state.roster[username]) {
        return state;
      }
      const newState = update(state, {
        roster: {
          [username]: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            is_talking: {
              $set: talking
            },
          },
        },
      });
      return newState;
    }
    case MUTEVIDEO: {
      const username = action.payload.user_id;
      const currentState = state.roster[username];
      if (!currentState) {
        return state;
      }
      const what = action.payload.type === 'remotescreenevent' ? 'isScreenMuted' : 'isVideoMuted';
      const newState = update(state, {
        roster: {
          [username]: {
            [what]: {
              $set: action.payload.muted,
            },
            isRequestingVideoMute: {
              $set: false,
            },
          },
        },
      });
      return newState;
    }
    case MUTEVIDEO_REQUEST: {
      const username = action.payload.user_id;
      const currentState = state.roster[username];
      if (!currentState) {
        return state;
      }
      const newState = update(state, {
        roster: {
          [username]: {
            isRequestingVideoMute: {
              $set: true,
            },
          },
        },
      });
      return newState;
    }
    case MUTEVIDEO_REQUEST_ERROR: {
      const username = action.payload.user_id;
      const currentState = state.roster[username];
      if (!currentState) {
        return state;
      }
      const newState = update(state, {
        roster: {
          [username]: {
            isRequestingVideoMute: {
              $set: false,
            },
          },
        },
      });
      return newState;
    }
    case MUTEAUDIO: {
      const username = action.payload.user_id;
      const currentState = state.roster[username];
      if (!currentState) {
        return state;
      }
      const desiredState: { [key: string]: object } = {
        isAudioMuted: {
          $set: action.payload.muted,
        },
        isRequestingAudioMute: {
          $set: false,
        },
      };
      if (action.payload.muted && currentState.is_talking) {
        desiredState["is_talking"] = { $set: false };
      }
      const newState = update(state, {
        roster: {
          [username]: desiredState,
        },
      });
      return newState;
    }
    case ENABLE_DESKTOP_CONTROL: {
      const username = action.payload.userId;
      const currentState = state.roster[username];
      if (!currentState) {
        return state;
      }

      return update(state, {
        roster: {
          [username]: {
            isDesktopControlEnabled: {
              $set: action.payload.desktopControlEnabled,
            },
            desktopControlType: {
              $set: action.payload.desktopControlType,
            },
            isRequestingDesktopControl: {
              $set: false,
            }
          },
        },
      });
    }
    case DESKTOP_CONTROL_REQUESTED: {
      const username = action.payload.userId;
      const currentState = state.roster[username];
      if (!currentState) {
        return state;
      }

      return update(state, {
        roster: {
          [username]: {
            isRequestingDesktopControl: {
              $set: true,
            }
          },
        },
      });
    }
    case MUTEAUDIO_REQUEST: {
      const username = action.payload.user_id;
      const newState = update(state, {
        roster: {
          [username]: {
            isRequestingAudioMute: {
              $set: true,
            },
          },
        },
      });
      return newState;
    }
    case MUTEAUDIO_REQUEST_ERROR: {
      const username = action.payload.user_id;
      const currentState = state.roster[username];
      if (!currentState) {
        return state;
      }
      const newState = update(state, {
        roster: {
          [username]: {
            isRequestingAudioMute: {
              $set: false,
            },
          },
        },
      });
      return newState;
    }
    case MUTEALLAUDIO_REQUEST: {
      const username = action.payload.user_id;
      const newState = update(state, {
        roster: {
          [username]: {
            isRequestingAllAudioMute: {
              $set: true,
            },
          },
        },
      });
      return newState;
    }
    case MUTEALLAUDIO_DONE: {
      const username = action.payload.user_id;
      const currentState = state.roster[username];
      if (!currentState) {
        return state;
      }
      const newState = update(state, {
        roster: {
          [username]: {
            isRequestingAllAudioMute: {
              $set: false,
            },
          },
        },
      });
      return newState;
    }
    case CHANGE_USER_ROLE_REQUEST: {
      const username = action.payload.user_id;
      const currentState = state.roster[username];
      if (!currentState) {
        return state;
      }
      const newState = update(state, {
        roster: {
          [username]: {
            isRequestingRoleChange: {
              $set: true,
            },
          },
        },
      });
      return newState;
    }
    case CHANGE_USER_ROLE_SUCCESS: {
      const username = action.payload.user_id;
      const currentState = state.roster[username];
      if (!currentState) {
        return state;
      }
      const newState = update(state, {
        roster: {
          [username]: {
            isRequestingRoleChange: {
              $set: false,
            },
          },
        },
      });
      return newState;
    }
    case CHANGE_USER_ROLE_ERROR: {
      const username = action.payload.user_id;
      const currentState = state.roster[username];
      if (!currentState) {
        return state;
      }
      const newState = update(state, {
        roster: {
          [username]: {
            isRequestingRoleChange: {
              $set: false,
            },
          },
        },
      });
      return newState;
    }
    case APPLY_LAYOUT_REQUEST: {
      const newState = update(state, {
        isRequestingApplyLayout: {
          $set: true,
        },
      });
      return newState;
    }
    case APPLY_LAYOUT_SUCCESS: {
      const newState = update(state, {
        isRequestingApplyLayout: {
          $set: false,
        },
        isLayoutChanged: {
          $set: false,
        }
      });
      return newState;
    }
    case APPLY_LAYOUT_ERROR: {
      const newState = update(state, {
        isRequestingApplyLayout: {
          $set: false,
        },
      });
      return newState;
    }
    case LOCK_ROOM_REQUEST: {
      const newState = update(state, {
        isRequestingLockRoom: {
          $set: true,
        },
      });
      return newState;
    }
    case LOCK_ROOM_SUCCESS: {
      const locked = action.payload.is_locked;
      const newState = update(state, {
        isRequestingLockRoom: {
          $set: false,
        },
        isLocked: {
          $set: locked,
        }
      });
      return newState;
    }
    case LOCK_ROOM_ERROR: {
      const newState = update(state, {
        isRequestingLockRoom: {
          $set: false,
        },
      });
      return newState;
    }
    case FORCE_LAYOUT: {
      const layout = action.payload.layout_type;
      // eslint-disable-next-line @typescript-eslint/camelcase
      const layout_config = action.payload.layout_config;
      // eslint-disable-next-line @typescript-eslint/camelcase
      if (layout && layout_config) {
        const newState = update(state, {
          layout: {
            $set: layout
          },
          layoutConfig: {
            // eslint-disable-next-line @typescript-eslint/camelcase
            $set: layout_config
          },
          isLayoutChanged: {
            $set: false,
          }
        });
        return newState;
      }
      else {
        return state;
      }
    }
    case CHANGE_LAYOUT: {
      const newState = update(state, {
        layout: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          $set: action.payload.layout_type
        },
        layoutConfig: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          $set: action.payload.layout_config || {}
        },
        isLayoutChanged: {
          $set: true
        }
      });
      return newState;
    }
    case EXPAND_STREAMVIDEO: {
      const newState = update(state, {
        layoutConfig: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          featured_id: {
            $set: action.payload.featured_id,
          },
          // eslint-disable-next-line @typescript-eslint/camelcase
          featured_type: {
            $set: action.payload.stream_type,
          }
        },
        isLayoutChanged: {
          $set: true,
        }
      });
      return newState;
    }

    case ADD_OBSERVED_SCREEN: {
      let observedScreens: Set<string> = new Set();
      if (state.layoutConfig && state.layoutConfig.observedScreens) {
        observedScreens = new Set(state.layoutConfig.observedScreens);
      }
      observedScreens.add(action.payload.userId);
      const newState = update(state, {
        layoutConfig: {
          $set: { ...(state.layoutConfig || {}), observedScreens },
        },
        isLayoutChanged: {
          $set: true,
        }
      });
      return newState;
    }

    case REMOVE_OBSERVED_SCREEN: {
      let observedScreens: Set<string> = new Set();
      if (state.layoutConfig && state.layoutConfig.observedScreens) {
        observedScreens = new Set(state.layoutConfig.observedScreens);
      }
      observedScreens.delete(action.payload.userId);
      const newState = update(state, {
        layoutConfig: {
          $set: { ...(state.layoutConfig || {}), observedScreens },
        },
        isLayoutChanged: {
          $set: true,
        }
      });
      return newState;
    }

    case PIP_USER: {
      const newState = update(state, {
        pipEnabled: {
          $set: action.payload.pipUser,
        },
      });
      return newState;
    }

    case FULLSCREEN_USER: {
      const newState = update(state, {
        fullScreenEnabled: {
          $set: action.payload.fullscreenUser,
        },
      });
      return newState;
    }

    case ENLARGE_STREAMVIDEO: {
      const newState = update(state, {
        layoutConfig: {
          // eslint-disable-next-line @typescript-eslint/camelcase
          enlarge_video: {
            $set: action.payload.enlarge,
          }
        },
        isLayoutChanged: {
          $set: true,
        }
      });
      return newState;
    }
    case KICK_USER_REQUEST: {
      const username = action.payload.user_id;
      const currentState = state.roster[username];
      if (!currentState) {
        return state;
      }
      const newState = update(state, {
        roster: {
          [username]: {
            isRequestingUserKick: {
              $set: true,
            },
          },
        },
      });
      return newState;
    }
    case KICK_USER_SUCCESS: {
      const username = action.payload.user_id;
      const currentState = state.roster[username];
      if (!currentState) {
        return state;
      }
      const newState = update(state, {
        roster: {
          [username]: {
            isRequestingUserKick: {
              $set: false,
            },
          },
        },
      });
      return newState;
    }
    case KICK_USER_ERROR: {
      const username = action.payload.user_id;
      const currentState = state.roster[username];
      if (!currentState) {
        return state;
      }
      const newState = update(state, {
        roster: {
          [username]: {
            isRequestingUserKick: {
              $set: false,
            },
          },
        },
      });
      return newState;
    }
    case SET_CHAT_OPEN: {
      return Object.assign({}, state, {
        isChatOpen: action.payload.isOpen,
      });
    }
    case PUBLIC_USERMESSAGE: {
      const message: UserMessage = {
        class: action.payload.class,
        subClass: action.payload.subClass,
        data: {
          from: action.payload.from,
          fromDisplayName: action.payload.fromDisplayName,
          message: action.payload.message,
          timestamp: action.payload.timestamp,
          color: null,
        }
      };
      const lastMessage: LastMessage = {
        from: action.payload.from,
        displayName: action.payload.fromDisplayName,
        message: action.payload.message,
      };
      return Object.assign({}, state, {
        publicMessages: [...state.publicMessages, message],
        unreadPublicMessages: ++state.unreadPublicMessages,
        lastMessage: lastMessage,
      });
    }
    case PUBLIC_USERMESSAGE_RESET_UNREAD: {
      return Object.assign({}, state, {
        unreadPublicMessages: 0,
      });
    }
    case PRIVATE_USERMESSAGE: {
      const message: PrivateUserMessage = {
        class: action.payload.class,
        subClass: action.payload.subClass,
        data: {
          to: action.payload.to,
          toDisplayName: action.payload.toDisplayName,
          from: action.payload.from,
          fromDisplayName: action.payload.fromDisplayName,
          message: action.payload.message,
          timestamp: action.payload.timestamp,
          color: null,
        }
      };
      const lastMessage: LastMessage = {
        from: action.payload.from,
        displayName: action.payload.fromDisplayName,
        message: action.payload.message,
      };

      const isMessageMine = action.payload.from === action.payload.myUsername;

      const thread = isMessageMine
        ? action.payload.to
        : action.payload.from;

      const newPrivateMessagesParts = Object.assign({}, state.privateMessages);
      const newUnreadPrivateMessagesParts = Object.assign({}, state.unreadPrivateMessages);
      let partStatePrivateMessages: PrivateUserMessage[];
      let partStateUnreadPrivateMessages: number;
      if (state.privateMessages[thread]) {
        partStatePrivateMessages = newPrivateMessagesParts[thread];
        partStateUnreadPrivateMessages = newUnreadPrivateMessagesParts[thread];
      } else {
        partStatePrivateMessages = [];
        partStateUnreadPrivateMessages = 0;
      }

      partStatePrivateMessages = [...partStatePrivateMessages, message];

      if (!isMessageMine) {
        partStateUnreadPrivateMessages = ++partStateUnreadPrivateMessages;
      }

      newPrivateMessagesParts[thread] = partStatePrivateMessages;
      newUnreadPrivateMessagesParts[thread] = partStateUnreadPrivateMessages;

      return Object.assign({}, state, {
        privateMessages: newPrivateMessagesParts,
        unreadPrivateMessages: newUnreadPrivateMessagesParts,
        lastMessage: lastMessage,
      });
    }
    case PRIVATE_USERMESSAGE_RESET_UNREAD: {
      return Object.assign({}, state, {
        unreadPrivateMessages: 0,
      });
    }
    case PRIVATE_USERMESSAGE_RESET_UNREAD_THREAD: {
      const thread = action.payload.thread;
      const newState = update(state, {
        unreadPrivateMessages: {
          [thread]: {
            $set: 0,
          },
        },
      });
      return newState;
    }
    case EVENTMESSAGE: {
      const message: EventMessage = {
        class: action.payload.class,
        subClass: action.payload.subClass,
        data: {
          from: 'system',
          username: action.payload.username,
          fromDisplayName: action.payload.fromDisplayName,
          timestamp: action.payload.timestamp,
          color: null,
        }
      };
      return Object.assign({}, state, {
        publicMessages: [...state.publicMessages, message],
      });
    }
    case START_RECORDING_SUCCESS: {
      return Object.assign({}, state, {
        isRecording: true,
        isRequestingRecordingOp: false,
      });
    }
    case START_RECORDING_ERROR: {
      return Object.assign({}, state, {
        isRecording: false,
        isRequestingRecordingOp: false,
      });
    }
    case STOP_RECORDING_SUCCESS: {
      return Object.assign({}, state, {
        isRecording: false,
        isRequestingRecordingOp: false
      });
    }
    case STOP_RECORDING_ERROR: {
      return Object.assign({}, state, {
        isRequestingRecordingOp: false
        // isRecording: false,
        // TODO: log something here? what to do?
      });
    }
    case START_RECORDING_REQUEST: {
      const newState = update(state, {
        isRequestingRecordingOp: {
          $set: true,
        },
      });
      return newState;
    }
    case STOP_RECORDING_REQUEST: {
      const newState = update(state, {
        isRequestingRecordingOp: {
          $set: true,
        },
      });
      return newState;
    }
    case START_LIVESTREAMING_SUCCESS: {
      return Object.assign({}, state, {
        isStreaming: true,
        isRequestingStreamingOp: false,
      });
    }
    case START_LIVESTREAMING_ERROR: {
      return Object.assign({}, state, {
        isStreaming: false,
        isRequestingStreamingOp: false,
      });
    }
    case STOP_LIVESTREAMING_SUCCESS: {
      return Object.assign({}, state, {
        isStreaming: false,
        isRequestingStreamingOp: false
      });
    }
    case STOP_LIVESTREAMING_ERROR: {
      return Object.assign({}, state, {
        isRequestingStreamingOp: false
      });
    }
    case START_LIVESTREAMING_REQUEST: {
      const newState = update(state, {
        isRequestingStreamingOp: {
          $set: true,
        },
      });
      return newState;
    }
    case STOP_LIVESTREAMING_REQUEST: {
      const newState = update(state, {
        isRequestingStreamingOp: {
          $set: true,
        },
      });
      return newState;
    }
    case SET_LIVESTREAMABLE: {
      return update(state, {
        livestreamingEnabled: {
          $set: action.payload.streamable,
        },
      });
    }
    case SET_RECORDABLE: {
      return update(state, {
        recordingEnabled: {
          $set: action.payload.recordable,
        },
      });
    }
    case DURATION_UPDATE: {
      return update(state, {
        elapsed: {
          $set: action.payload.elapsed,
        },
      });
    }
    case TOGGLE_DESKSHARE: {
      return update(state, {
        deskShareEnabled: {
          $set: action.payload.is_enabled,
        },
      });
    }
    case TOGGLE_DIALOUT: {
      return update(state, {
        dialoutEnabled: {
          $set: action.payload.is_enabled,
        },
      });
    }
    default:
      return state;
  }
}


export default room;
export { defaultState };
