import { defineMessages } from 'react-intl';


const messages = defineMessages({
  leaveMeeting: { id: 'leave' },
  enable: { id: 'enable' },
  disable: { id: 'disable' },
  invite: { id: 'invite' },
  shareScreen: { id: 'shareScreen' },
  unShareScreen: { id: 'unShareScreen' },
  manageYourControlsHere: { id: 'manageYourControlsHere' },
  enableDesktopControl: { id: 'enableDesktopControl' },
  disableDesktopControl: { id: 'disableDesktopControl' }
});


export default messages;
