import uuid from 'uuid';
import { addNotification, removeNotification } from '../actions/notifications';

export const DEBUG = Symbol('DEBUG');
export const INFO = Symbol('INFO');
export const WARNING = Symbol('WARNING');
export const ERROR = Symbol('ERROR');

let _instance = null;

class Notifications {
  constructor(dispatch, logger) {
    this._dispatch = dispatch;
    this._logger = logger;
  }

  newEvent(priority, code, reason, message, payload) {
    const event = { prio: priority, code: code, reason: reason, message: message, payload: payload || {} };
    const ref = uuid.v4();
    this.expireEvent(ref);

    this._logger.debug("Dispatching notification ", event, ref);

    this._dispatch(addNotification(ref, event));
  }

  expireEvent(ref) {
    setTimeout(() => {
      this._logger.debug("Starting expire event for notification", ref);
      this._dispatch(removeNotification(ref));
    }, 3000);
  }
}

export function newEvent(priority, code, reason, message, payload) {
  if (_instance) {
    _instance.newEvent(priority, code, reason, message, payload);
  }
}

export function initNotifications(dispatch, logger) {
  if (!_instance) {
    logger.info("Started notifications subsystem");
    _instance = new Notifications(dispatch, logger);
  }
  return _instance;
}

