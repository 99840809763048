import React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';

import { withStyles, createStyles, WithStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';

import { IconRocket } from '../IconSet';
import EnterMeetingDialog from '../EnterMeetingDialog';

import getRouteFor, { Route } from '../../lib/utils/router';
import { State as SessionState } from '../../lib/reducers/session';

import combineStyles from '../../lib/utils/combineStyle';
import homeStyles from '../../style/HomePadding';
import classNames from 'classnames';


const styles = (theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.secondary.light,
      width: '100%',
      height: '100%',
      paddingTop: theme.spacing(2), // misterious margin added by who knows who
      paddingBottom: theme.spacing(2)
    },
    grid: {
      width: '100%',
      height: '100%',
    },
    title: {
      color: theme.palette.primary.main,
    },
    body: {
    },
    noPadding: {
      padding: 0,
    },
    noPaddingLeft: {
      paddingLeft: 0,
    },
    button: {
      marginTop: theme.spacing(1),
      paddingRight: 0,
    },
    meetingInputButton: {
      textAlign: 'right'
    }
  });

const combinedStyles = combineStyles(styles, homeStyles);


const messages = defineMessages({
  hello: { id: 'hello' },
  startANewMeeting: { id: 'startANewMeeting' },
  youCanInvite: { id: 'youCanInvite' },
  start: { id: 'start' },
  doYouHaveAnInvite: { id: 'doYouHaveAnInvite' },
});


type ReduxState = {
  session: SessionState;
}


type Props = {
};


type State = {
  inviteDialogOpen: boolean;
};


type MappedProps = {
  username: null | string;
  displayName: string;
}


const mapStateToProps = (state: ReduxState): MappedProps => ({
  username: state.session.username,
  displayName: state.session.displayName || "",
});

type withStyleProps = keyof ReturnType<typeof styles> | keyof ReturnType<typeof homeStyles>;

type ExtendedProps = Props
  & MappedProps
  & WithStyles<withStyleProps>
  & RouteComponentProps
  & {intl: IntlShape};


class StartMeetingHeader extends React.Component<ExtendedProps, State> {
  constructor(props: ExtendedProps) {
    super(props);
    this.state = {
      inviteDialogOpen: false,
    };
  }

  gotoPersonalRoom = () => {
    if (this.props.username) {
      const url = getRouteFor(Route.Meeting, { slug: this.props.username });
      this.props.history.push(url);
    }
  }

  closeInviteDialog = () => {
    this.setState({ inviteDialogOpen: false });
  }

  openInviteDialog = () => {
    this.setState({ inviteDialogOpen: true });
  }

  render() {
    const { classes, intl } = this.props;

    return (
      <React.Fragment>
        { this.state.inviteDialogOpen && <EnterMeetingDialog onClose={this.closeInviteDialog}/> }
        <div className={classes.root}>
          <Grid
            container
            direction='row'
            alignItems='center'
            justify='center'
            className={classNames(classes.grid, classes.homePadding)}
          >
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.title}>
                {`${intl.formatMessage(messages.hello)} ${this.props.displayName}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Box
                display="flex"
                p={2}
                alignItems='flex-end'
                className={classes.noPaddingLeft}
              >
                <Box className={classes.noPaddingLeft} p={1} flexGrow={1}>
                  <Box
                    display="flex"
                    p={1}
                    alignItems='center'
                    alignContent='center'
                    className={classes.noPadding}
                  >
                    <Box className={classes.noPaddingLeft} p={1}>
                      <IconRocket size={86}/>
                    </Box>
                    <Box p={1} flexGrow={1}>
                      <Typography variant="h5" className={classes.body}>
                        {intl.formatMessage(messages.startANewMeeting)}
                      </Typography>
                      <Typography variant="body2" className={classes.body}>
                        {intl.formatMessage(messages.youCanInvite)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  alignItems='flex-end'
                  flexDirection="column"
                  p={1}
                  justifyContent='flex-end'
                  className={classes.noPadding}
                >
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={this.gotoPersonalRoom}
                  >
                    {intl.formatMessage(messages.start)}
                  </Button>
                  <Box p={1} className={classes.button}>
                    <Link
                      component="button"
                      variant="body2"
                      onClick={this.openInviteDialog}
                      className={classes.meetingInputButton}
                    >
                      {intl.formatMessage(messages.doYouHaveAnInvite)}
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}


export default withStyles<withStyleProps>(combinedStyles, { withTheme: true })(
  connect(mapStateToProps)(
    injectIntl(withRouter(StartMeetingHeader))
  )
);
