import { Api, Transport } from '../api/videoroom';
import {
  forceLayout, startRecordingSuccess, lockRoomStatus,
  stopRecordingSuccess, setRecordable, toggleDeskshare,
  toggleDialout, onEnableDesktopControl,
  // setMousePointer,
  startLivestreamingSuccess, stopLivestreamingSuccess,
  setLivestreamable,
} from './room';
import { setRecorder } from './session';
import { newEvent, INFO } from '../notifications';
import { traceError as sentryTraceError } from '../sentry';


export const SOCKET_CONNECT = 'SOCKET_CONNECT';
export const SOCKET_CONNECTING = 'SOCKET_CONNECTING';
export const SOCKET_FAILURE = 'SOCKET_FAILURE';
export const SOCKET_DISCONNECT = 'SOCKET_DISCONNECT';
export const JOIN_FAILURE = 'JOIN_FAILURE';
export const JOIN_SUCCESS = 'JOIN_SUCCESS';
export const JOIN_REQUEST = 'JOIN_REQUEST';
export const LEAVE_SUCCESS = 'LEAVE_SUCCESS';
export const ROSTER_CHANGE = 'ROSTER_CHANGE';
export const ROSTER_RESET = 'ROSTER_RESET';
export const ROSTER_SET_AUDIO = 'ROSTER_SET_AUDIO';
export const SET_ROOM_ROLE = 'SET_ROOM_ROLE';


function connecting() {
  return {
    type: SOCKET_CONNECTING,
    payload: {}
  };
}


function connectionSuccess(token, apiserver) {
  return {
    type: SOCKET_CONNECT,
    payload: {
      token: token,
      apiserver: apiserver,
    }
  };
}


function disconnectSuccess() {
  return {
    type: SOCKET_DISCONNECT,
    payload: {
    }
  };
}


function connectionError(errmessage, errcode) {
  return {
    type: SOCKET_FAILURE,
    payload: {
      errorMessage: errmessage,
      errorCode: errcode,
    },
    error: true
  };
}


function joinFailure(errmessage, errcode, payload) {
  return {
    type: JOIN_FAILURE,
    payload: {
      errorMessage: errmessage,
      errorCode: errcode,
      errorPayload: payload
    },
    error: true
  };
}


function joinSuccess(room, name, uid, room_uid, meeting_details, room_roles) {
  return {
    type: JOIN_SUCCESS,
    payload: {
      room: room,
      room_name: name,
      room_roles: room_roles,
      uid: uid,
      room_uid: room_uid,
      meeting_details: meeting_details
    },
  };
}


function joinRequest() {
  return {
    type: JOIN_REQUEST,
    payload: {
    },
  };
}


function leaveSuccess(reason) {
  return {
    type: LEAVE_SUCCESS,
    payload: {
      reason: reason
    },
  };
}


function rosterChange(participants) {
  return {
    type: ROSTER_CHANGE,
    payload: {
      participants: participants,
    }
  };
}


function rosterReset() {
  return {
    type: ROSTER_RESET,
    payload: {
    }
  };
}


function setRosterAudioMute(participantsAudioState) {
  return {
    type: ROSTER_SET_AUDIO,
    payload: {
      participants: participantsAudioState,
    }
  };
}


function setRoomRole(role) {
  return {
    type: SET_ROOM_ROLE,
    payload: {
      role: role,
    },
  };
}


function connect(token, logger, dispatch, getState, mouseEvent) {
  dispatch(connecting());
  const transport = new Transport('/socket', token);
  transport.connect().then(
    () => {
      let apiserver = new Api(transport, logger);
      let setAudioState = (members) => {
        let res = {};
        Object.keys(members).forEach(
          (k) => {
            const metas = members[k].metas;
            if (metas && metas[0]) {
              res[k] = { muted: metas[0].muted };
            }
          }
        );
        dispatch(setRosterAudioMute(res));
      };
      let applyLayout = (data) => {
        dispatch(forceLayout(data.layout, data.layoutConfig));
      };
      let onLockRoom = (data) => {
        dispatch(lockRoomStatus(data.locked));
      };
      let onRecordingStatus = (d) => {
        if (d.is_recording !== undefined) {
          if (d.is_recording) {
            notifyStartRecording();
            dispatch(startRecordingSuccess());
          }
          else {
            notifyStopRecording();
            dispatch(stopRecordingSuccess());
          }
        }

        if (d.is_livestreaming !== undefined) {
          if (d.is_livestreaming) {
            notifyStartStreaming();
            dispatch(startLivestreamingSuccess());
          }
          else {
            notifyStopStreaming();
            dispatch(stopLivestreamingSuccess());
          }
        }
      };

      apiserver.subscribe('audio_state', (d) => setAudioState(d));
      apiserver.subscribe('applyLayout', (d) => applyLayout(d));
      apiserver.subscribe('lockRoom', (d) => onLockRoom(d));
      apiserver.subscribe('recordingStatus', (d) => onRecordingStatus(d));
      apiserver.subscribe('startDeskControl',
        (d) => dispatch(onEnableDesktopControl(d.user, true, d.desktopControlType)));
      apiserver.subscribe('stopDeskControl', (d) => {
        notifyStopDesktopControl();
        dispatch(onEnableDesktopControl(d.user, false, null));
      });
      apiserver.subscribe('startDrawing', () => {
        const state = (getState() || {}).settings;
        if (state.isElectron) {
          notifyStartDrawing();
        } else {
          notifyStartDrawingBrowser();
        }

      });
      apiserver.subscribe('stopDrawing', () => {
        notifyStopDrawing();
      });
      apiserver.subscribe('mouseEvent', (d) => {
        const state = (getState() || {}).websocket;

        if (state.uid !== d.userId) {
          // if (d.mouseEventType === 'move') {
          //   dispatch(setMousePointer(d));
          // }
          if (typeof mouseEvent !== 'undefined') {
            mouseEvent(d.userId, d.displayName, d.mouseEventType, d.mouseEventData);
          }

        }

      });
      apiserver.subscribe('channelevent', (d) => {
        const state = (getState() || {}).websocket;
        if (d.type === 'leave' && d.reason) {
          const channel = (state || {}).room;
          if (channel) {
            channel.leave();
          }
          const videoRoom = (getState() || {}).room;
          const roomObject = (videoRoom || {}).roomObject;
          if (roomObject) {
            roomObject.tearDown();
          }
          dispatch(leaveSuccess(d.reason));
        }
        else if (d.type === 'myrole_change' && d.role) {
          dispatch(setRoomRole(d.role));
        }
        else if (d.type === 'join_on_locked' && d.dname) {
          newEvent(INFO, 'joinOnLockedRoom', 'joinOnLockedRoom',
            `${d.dname} could not enter the room because it is locked`, { dname: d.dname });
        }
      });

      return dispatch(connectionSuccess(token, apiserver));

    }
  ).catch(
    (reason) => {
      dispatch(connectionError(reason, null));
    }
  );
}


function disconnect(dispatch, getState) {
  const state = (getState() || {}).websocket;
  const apiserver = (state || {}).apiserver;
  if (apiserver && apiserver.transport) {
    apiserver.transport.disconnect();
    dispatch(disconnectSuccess());
  }
}


function recordingStatus(is_recording) {
  if (is_recording) {
    notifyStartRecording();
    return startRecordingSuccess();
  } else {
    return stopRecordingSuccess();
  }
}

function notifyStopDesktopControl() {
  newEvent(INFO, 'stopDesktopControl', 'stopDesktopControl',
    'Remote desktop control for this room has been disabled');
}
function notifyStartDrawing() {
  newEvent(INFO, 'startDrawing', 'startDrawing',
    'Remote drawing for this room has started');
}
function notifyStartDrawingBrowser() {
  newEvent(INFO, 'startDrawingBrowser', 'startDrawingBrowser',
    'Remote drawing for this room has started. You need to manually start desktop share in fullscreen to use it.');
}
function notifyStopDrawing() {
  newEvent(INFO, 'stopDrawing', 'stopDrawing',
    'Remote drawing for this room has been disabled');
}

function notifyStartRecording() {
  newEvent(INFO, 'roomStartRecording', 'roomStartRecording',
    'This room is being recorded.');
}

function notifyStopRecording() {
  newEvent(INFO, 'roomStopRecording', 'roomStopRecording',
    'Recording has been stopped.');
}

function streamingStatus(is_streaming) {
  if (is_streaming) {
    notifyStartStreaming();
    return startLivestreamingSuccess();
  } else {
    return stopLivestreamingSuccess();
  }
}

function notifyStartStreaming() {
  newEvent(INFO, 'roomStartStreaming', 'roomStartStreaming',
    'This room is being streaming.');
}

function notifyStopStreaming() {
  newEvent(INFO, 'roomStopStreaming', 'roomStopStreaming',
    'Streaming has been stopped.');
}

function configureRecording(dispatch, record_status) {
  // set the room recording status
  dispatch(recordingStatus(record_status.is_recording));

  // set the room recording status
  dispatch(streamingStatus(record_status.is_livestreaming));

  // flag the session as beloging to a recorder
  dispatch(setRecorder(record_status.is_recorder));

  // flag the room as recordable or not
  dispatch(setRecordable(record_status.enabled));

  // flag the room as livestreamable or not
  dispatch(setLivestreamable(record_status.livestreaming_enabled));
}

function configureDeskshare(dispatch, deskshare_status) {
  dispatch(toggleDeskshare(deskshare_status.enabled));
}

function configureDialout(dispatch, dialout_status) {
  dispatch(toggleDialout(dialout_status.enabled));
}

function join(roomname, dispatch, getState, logger) {
  const state = (getState() || {}).websocket;
  const apiserver = (state || {}).apiserver;
  const session = (getState() || {}).session;
  const realm = (session || {}).realm;
  const roomState = (getState() || {}).room;
  const roomObject = roomState.roomObject;

  dispatch(joinRequest());
  if (apiserver) {
    const topic = 'room:' + roomname + '@' + realm;
    let updateRosterCb = (users) => dispatch(rosterChange(users));
    apiserver.joinVideoRoom(topic, {}, updateRosterCb).then(
      ({ channel, uid, room_uid, meeting_details, room_roles, layout, locked,
        record_status, deskshare_status, deskcontrol_status, dialout_status }) => {
        channel.onError((e) => {
          channel.leave();
          logger.error("channel error", e);
          sentryTraceError('channel error', { errCode: 1408, errMessage: 'channel_error' });
          dispatch(joinFailure('channel_error', 1408));
        });
        if (roomObject) {
          roomObject.setUserId(uid);
          roomObject.setRoomId(room_uid);
        }
        dispatch(joinSuccess(channel, roomname, uid, room_uid, meeting_details, room_roles));
        configureRecording(dispatch, record_status);
        configureDeskshare(dispatch, deskshare_status);
        configureDialout(dispatch, dialout_status);
        dispatch(lockRoomStatus(locked));
        dispatch(forceLayout((layout || {}).layout, (layout || {}).layoutConfig));

        if (typeof deskcontrol_status !== 'undefined' && deskcontrol_status.running === true) {
          setTimeout(() => {
            dispatch(onEnableDesktopControl(deskcontrol_status.payload.user,
              true, deskcontrol_status.payload.desktopControlType));
          }, 500);
        }

      }
    ).catch(
      ({ type, reason, payload, errorCode }) => {
        logger.error("failed join", type, reason, errorCode);
        reason = type === 'timeout' ? 'timeout' : reason;
        errorCode = errorCode ? errorCode : 1001;
        sentryTraceError('failed join', { errCode: errorCode, errMessage: reason, payload: payload });
        dispatch(joinFailure(reason, errorCode, payload));
      }
    );
  }
}


function leave(dispatch, getState) {
  const state = (getState() || {}).websocket;
  const channel = (state || {}).room;
  if (channel) {
    channel.leave();
  }
  dispatch(leaveSuccess('user'));
  dispatch(rosterReset());
}


export function connectSocket(token, logger, mouseEvent) {
  return (dispatch, getState) => {
    return connect(token, logger, dispatch, getState, mouseEvent);
  };
}


export function disconnectSocket() {
  return (dispatch, getState) => {
    return disconnect(dispatch, getState);
  };
}


export function joinRoom(room, logger) {
  return (dispatch, getState) => {
    return join(room, dispatch, getState, logger);
  };
}


export function leaveChannel() {
  return (dispatch, getState) => {
    return leave(dispatch, getState);
  };
}
