import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';

import { Meeting } from '../../lib/reducers/websocket';
import { State } from '../../lib/reducers';
import ClosableDialog from '../ClosableDialog';
import Typography from '@material-ui/core/Typography';
import InviteEmailButton from './InviteEmailButton';
import CopyLinkButton from './CopyLinkButton';
import InviteDialButton from './InviteDialButton';

import getRouteFor, { Route } from '../../lib/utils/router';
import { toggleInviteParticipantsDialog } from '../../lib/actions/room';
import { amModerator } from '../../lib/reduxSelectors/room';


const messages = defineMessages({
  title: { id: 'invitePersonToMeeting' },
  email: { id: 'email' },
  username: { id: 'username' },
  name: { id: 'name' },
  personalRoom: { id: 'personalRoom' },
  copyToClipboard: { id: 'copyToClipboard' },
  copiedToClipboard: { id: 'copiedToClipboard' },
});


const useStyles = makeStyles((theme: Theme) =>
  createStyles({

    content: {
      overflow: "hidden",
    },

    title: {
      color: theme.palette.primary.main,
      textAlign: "center"
    },

    container: {
      padding: theme.spacing(2),
    },

    buttons: {
      alignSelf: "center",
      textAlign: "center"
    }

  })
);


type MappedProps = {
  roomDetails: Meeting | null;
  dialoutEnabled: boolean;
  toggle: boolean;
  amModerator: boolean;
}


type Props = {}


type ExtendedProps = Props & MappedProps;


function InviteToMeetingDialog(props: ExtendedProps) {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { dialoutEnabled, amModerator } = props;
  let rawUrl = "";
  const dispatch = useDispatch();

  const closeInviteDialog = (): void => {
    dispatch(toggleInviteParticipantsDialog());
  };

  if (props.roomDetails) {
    rawUrl = getRouteFor(Route.Meeting, { slug: props.roomDetails.slug }, undefined, window.location.origin);
  }

  return (
    <ClosableDialog
      open={props.toggle}
      onClose={closeInviteDialog}
      fullWidth={false}
      maxWidth='md'
      title={formatMessage(messages.title)}
    >
      <DialogContent className={classes.content}>
        <Grid container
          justify="space-evenly"
          spacing={4}
          alignItems="flex-start" className={classes.container}>
          {amModerator &&
            <Grid item xs={4} className={classes.buttons}>
              <InviteEmailButton />
            </Grid>
          }
          <Grid item xs={!amModerator ? 6 : 4} className={classes.buttons}>
            <CopyLinkButton rawUrl={rawUrl}/>
          </Grid>
          {amModerator && dialoutEnabled &&
            <Grid item xs={4} className={classes.buttons}>
              <InviteDialButton />
            </Grid>
          }
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.title}>
              {rawUrl}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </ClosableDialog>
  );
}


const mapStateToProps = (state: State): MappedProps => {
  return {
    roomDetails: state.websocket.meeting_details,
    dialoutEnabled: state.room.dialoutEnabled,
    toggle: state.invite_participants.toggle_dialog,
    amModerator: amModerator(state),
  };
};


export default connect(mapStateToProps)(InviteToMeetingDialog);
