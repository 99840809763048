import React from 'react';
import { connect } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { VideoRoom } from '../../lib/api/videoroom';
import { State as RoomState } from '../../lib/reducers/room';
import { State as WebsocketState } from '../../lib/reducers/websocket';
import { IconUsers, IconMicrophone } from '../IconSet';
import { isMobileOnly } from 'react-device-detect';
import { Box } from '@material-ui/core';
import classNames from 'classnames';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    center: {
      textAlign: 'center',
      color: theme.palette.text.primary,
    },
    container: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    onlyAudio: {
      marginLeft: theme.spacing(1.5),
    }
  })
);


const messages = defineMessages({
  meetingParticipantsSummary: { id: 'meetingParticipantsSummary' },
  meetingParticipantsSummaryAudioOnly: { id: 'meetingParticipantsSummaryAudioOnly' },
  meetingParticipantsSummaryAudioOnlyMobile: { id: 'meetingParticipantsSummaryAudioOnlyMobile' },
});


function ParticipantsCount(props: ExtendedProps) {
  const { usersCount, usersWithAudioOnly, inMeeting } = props;
  const onlyAudio = usersWithAudioOnly > 0;

  const { formatMessage } = useIntl();

  const classes = useStyles();

  const getParticipants = () => {
    let text = formatMessage(messages.meetingParticipantsSummary, { totalUsers: usersCount });
    if (onlyAudio) {
      text += formatMessage(messages.meetingParticipantsSummaryAudioOnly, { audioOnlyUsers: usersWithAudioOnly });
    }
    return (
      <Typography variant="body2" className={classes.center} >
        {text}
      </Typography>
    );
  };

  const getOnlyAudioMobile = () => {
    if (onlyAudio) {
      return (
        <Box p={0} className={classes.onlyAudio}>
          (<IconMicrophone size={20}/> {usersWithAudioOnly})
        </Box>
      );
    } else {
      return null;
    }
  };

  const getParticipantsMobile = () => {
    return (
      <Box p={0} className={classNames(classes.container, classes.center)}>
        <IconUsers size={20} /> {usersCount}
        {getOnlyAudioMobile()}
      </Box>
    );
  };

  if (!inMeeting) {
    return null;
  }

  return (
    <React.Fragment>
      { isMobileOnly ? getParticipantsMobile() : getParticipants() }
    </React.Fragment>
  );
}


type State = {
  room: RoomState;
  websocket: WebsocketState;
}


type MappedProps = {
  usersCount: number;
  usersWithAudioOnly: number;
  inMeeting: boolean;
}


type ExtendedProps = {} & MappedProps;


const mapStateToProps = (state: State): MappedProps => {
  const roster = state.room.roster;
  const myUserId = state.websocket.uid;
  const audioOnlyStreamsCount = Object.keys(state.room.roster).reduce(
    (audioCount, uid: string) => {
      if (uid === myUserId) {
        const iHaveVideo = VideoRoom.getVideoTrackFromStream(state.room.localvideo_stream) && !roster[uid].isVideoMuted;
        return iHaveVideo ? audioCount : audioCount + 1;
      }
      else if (VideoRoom.getVideoTrackFromStream(roster[uid].stream)) {
        return audioCount;
      }
      else {
        return audioCount + 1;
      }
    }
    , 0
  );
  return {
    usersCount: Object.keys(state.room.roster).length,
    usersWithAudioOnly: audioOnlyStreamsCount,
    inMeeting: Boolean(state.websocket.room),
  };
};


export default connect(mapStateToProps)(ParticipantsCount);
