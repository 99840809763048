import React from 'react';
import { connect } from 'react-redux';
import { injectIntl, defineMessages, IntlShape } from 'react-intl';
import { isMobileOnly } from 'react-device-detect';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import GridList from '@material-ui/core/GridList';

import DebouncedTextField from '../DebouncedTextField';
import { IconSearch } from '../IconSet';
import User from './User';
import Header from './Header';
import { State } from '../../lib/reducers';

import { keys } from '../../lib/utils/array';
import { getColumns } from '../../lib/utils/rosterDimensions';
import { getRoster, ObservedUser } from '../../lib/reduxSelectors/roster';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
      backgroundColor: theme.palette.background.default,
    },
    inputField: {
      width: '100%',
    },
    userlist: {
      overflowY: 'auto',
      padding: theme.spacing(2),
    },
    search: {
      marginRight: theme.spacing(1),
    }
  }),
);


const messages = defineMessages({
  findParticpants: { id: 'findParticipants' },
  participantNumber: { id: 'participantNumber' },
  filteredParticipantNumber: { id: 'filteredParticipantNumber' },
});


const minFilterLen = 3;


function orderedRosterKeys(roster: MappedProps['roster']) {
  const keysAndNames = Object.keys(roster).map((uid) => [uid, roster[uid].display]);
  keysAndNames.sort((a, b) => {
    if (a[1] > b[1]) return 1;
    else if (a[1] < b[1]) return -1;
    else return 0;
  });
  return keysAndNames.map(([a, _b]) => a);
}


function renderRoster(roster: MappedProps['roster'], filter: string) {
  const users = orderedRosterKeys(roster).flatMap((key, idx) => {
    const user = roster[key] as ObservedUser;
    const displayName = user.display;
    if (filter.length >= minFilterLen && !displayName.toLowerCase().includes(filter.toLowerCase())) {
      return [];
    }
    else {
      return (
        [
          <ListItem key={idx}>
            <User uid={key} displayName={displayName} />
          </ListItem>
        ]
      );
    }
  });

  return users;
}


function Roster(props: ExtendedProps) {
  const classes = useStyles();

  const { roster, intl, isAuthenticatedAsGuest, isAuthenticated } = props;

  const [filter, setFilter] = React.useState("");

  const handleFilterChange = React.useCallback(
    (s) => {
      setFilter(s);
    },
    []
  );

  if (!isAuthenticated && !isAuthenticatedAsGuest) {
    return null;
  }

  const users = renderRoster(roster, filter);

  const rosterLen = keys(roster).length;
  const usersLen = users.length;
  const headerText = (rosterLen === usersLen)
    ? intl.formatMessage(messages.participantNumber, { number: rosterLen })
    : intl.formatMessage(messages.filteredParticipantNumber, { number: rosterLen, filtered: usersLen });

  const cols = (!isMobileOnly && props.size) ? getColumns(props.size) : 1;

  return (
    <List component="nav" className={classes.root}>
      <Header headerText={headerText} />
      <ListItem>
        <DebouncedTextField
          inputProps={{ autoCapitalize: 'off', className: classes.inputField }}
          InputProps={{
            endAdornment:
              <InputAdornment className={classes.search} position="end">
                <IconSearch size={22} />
              </InputAdornment>,
          }}
          autoFocus={isMobileOnly ? false : true}
          fullWidth
          placeholder={intl.formatMessage(messages.findParticpants)}
          onChange={handleFilterChange}
        />
      </ListItem>
      <GridList cellHeight={50} className={classes.userlist} cols={cols}>
        {users}
      </GridList>
    </List>
  );
}


type MappedProps = {
  roster: ReturnType<typeof getRoster>;
  isAuthenticatedAsGuest: boolean;
  isAuthenticated: boolean;
}


type Props = {
  size?: string | number;
}


type ExtendedProps = Props & MappedProps & { intl: IntlShape }


const mapStateToProps = (state: State): MappedProps => ({
  roster: getRoster(state),
  isAuthenticated: state.auth.isAuthenticated,
  isAuthenticatedAsGuest: state.auth.isAuthenticatedAsGuest,
});


export default connect(mapStateToProps)(injectIntl(Roster));
