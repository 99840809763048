import { graphql } from 'babel-plugin-relay/macro';
import { sessionDetailsQuery, sessionDetailsQueryResponse } from './__generated__/sessionDetailsQuery.graphql';

export type SessionDetails = sessionDetailsQuery['response']['loggedUser']['session'];

export const sessionDetailsQueryRequest = graphql`
query sessionDetailsQuery{
  loggedUser {
    session{
      realm
      displayName
      username
      roles
      stunServers{credential, urls, username}
      domainId
      email
      userId
      roomNumber
      roomOwnerNumber
      pstnNumber
      callStatsConfig {
        appId
        appSecret
        endpoint
      }
      chromeScreenShareConfig {
        url
      }
      recordingEnabled
      livestreamingEnabled
      sendAs
      personalRoomEnabled
      webinarEnabled
    }
  }
}
`;

export function getSession(response: sessionDetailsQueryResponse) {
  return response['loggedUser']['session'];
}


