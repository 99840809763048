import React from 'react';

import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import grey from '@material-ui/core/colors/grey';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { IconClose } from '../IconSet';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: 'inline-flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: theme.spacing(1),
    },
    title: {
      padding: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
    icon: {
      marginLeft: theme.spacing(1),
    },
  })
);


type Props = {
  onClose: () => void;
  title: string;
};


function ClosableDialog(props: Props & DialogProps) {
  const classes = useStyles();

  const { title, onClose, children, ...rest } = props;

  return (
    <Dialog onClose={onClose} {...rest}>
      <DialogTitle disableTypography className={classes.content} >
        <Typography className={classes.title} variant="h6">
          { title }
        </Typography>
        <IconButton className={classes.icon} onClick={onClose}>
          <IconClose color={grey[500]} size={24} />
        </IconButton>
      </DialogTitle>
      {children}
    </Dialog>
  );
}


export default withMobileDialog({ breakpoint: 'xs' })(ClosableDialog);
