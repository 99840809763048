import {
  INVITE_PARTICIPANTS_REQUEST,
  INVITE_PARTICIPANTS_SUCCESS,
  INVITE_PARTICIPANTS_ERROR,
  INVITE_PARTICIPANTS_TOGGLE_DIALOG,
} from '../actions/room';


const defaultState = {
  isFetching: false,
  error: false,
  errCode: null,
  toggle_dialog: false
};


function invite_participants(state = defaultState, action = {}) {
  switch (action.type) {
    case INVITE_PARTICIPANTS_TOGGLE_DIALOG:
      return Object.assign({}, state, {
        toggle_dialog: !state.toggle_dialog,
      });
    case INVITE_PARTICIPANTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        error: false,
        errCode: null
      });
    case INVITE_PARTICIPANTS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        error: false,
        errCode: null
      });
    case INVITE_PARTICIPANTS_ERROR:
      return Object.assign({}, state, {
        isFetching: false,
        error: true,
        errCode: action.payload.errCode
      });
    default:
      return state;
  }
}


export default invite_participants;
export { defaultState };
