import React from 'react';
import uuid from 'uuid';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import {
  IconLayout,
  IconLayoutGrid,
  IconLayoutAudio,
  IconLayoutWebinar,
} from '../IconSet';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { State } from '../../lib/reducers';
import { useIntl, defineMessages } from 'react-intl';
import Tooltip from '@material-ui/core/Tooltip';
import CascadingMenu, { CascadingMenuItem } from "../CascadingMenu/CascadingMenu";
import {
  applyLayout,
  changeLayout,
} from '../../lib/actions/room';
import {
  RoomLayout,
  RoomLayoutStreamType,
  RoomLayoutConfig
} from '../../lib/redux_types';
import { amModerator } from '../../lib/reduxSelectors/room';



const messages = defineMessages({
  selectToMeLayout: { id: 'selectToMeLayout' },
  selectToAllLayout: { id: 'selectToAllLayout' },
  gridLayout: { id: 'gridLayout' },
  webinarLayout: { id: 'webinarLayout' },
  lessonLayout: { id: 'lessonLayout' },
  presentationLayout: { id: 'presentationLayout' },
  audioOnlyLayout: { id: 'audioOnlyLayout' },
  fullscreenLayout: { id: 'fullscreenLayout' },
  chooseLayout: { id: 'chooseLayout' },
});


type MappedProps = {
  myUid: string | null;
  roles: ReadonlyArray<string>;
  layout: State['room']['layout'];
  layoutConfig: State['room']['layoutConfig'];
  amModerator: boolean;
  allowedLayouts: RoomLayout[] | undefined;
}


const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    handleChangeLayout: (layoutType, layoutConfig) => changeLayout(layoutType, layoutConfig),
    handleApplyLayout: () => applyLayout(),
  },
  dispatch
  );


const mapStateToProps = (state: State): MappedProps => ({
  myUid: state.websocket.uid,
  roles: state.websocket.room_roles,
  layout: state.room.layout,
  layoutConfig: state.room.layoutConfig,
  amModerator: amModerator(state),
  allowedLayouts: state.appconfig.room_options.allowed_layouts,
});


type Props = {
  disabled?: boolean;
} & MappedProps & ReturnType<typeof mapDispatchToProps>;


function filterAllowedLayouts(menuItems: CascadingMenuItem[], allowedLayouts: MappedProps['allowedLayouts']) {
  if (!allowedLayouts) {
    return menuItems;
  }

  const mapping: { [key: string]: RoomLayout } = {
    'fullscreen': 'fullscreen',
    'presentation': 'featured',
    'grid': 'default',
    'webinar': 'webinar',
    'audio': 'audioonly',
    'lesson': 'lesson',
  };
  return menuItems.filter(
    (item) => {
      const mapped = mapping[item.key as keyof (typeof mapping)];
      if (item.key === 'grid') {
        return true;  // always include the default layout
      }
      return (mapped && allowedLayouts.includes(mapped));
    }
  );
}


function MenuLayout(props: Props) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const { formatMessage } = useIntl();

  const open = Boolean(anchorEl);

  const getPresentationSubItems = (): Array<CascadingMenuItem> => {
    return [
      {
        key: uuid.v4(),
        caption: formatMessage(messages.selectToMeLayout),
        onClick: () => {
          if (props.myUid) {
            const layoutConfig = {
              // eslint-disable-next-line @typescript-eslint/camelcase
              enlarge_video: false,
              // eslint-disable-next-line @typescript-eslint/camelcase
              featured_id: props.myUid,
              // eslint-disable-next-line @typescript-eslint/camelcase
              featured_type: "stream" as RoomLayoutStreamType
            };
            SelectToMe("featured", layoutConfig);
          }
        }
      },
      {
        key: uuid.v4(),
        caption: formatMessage(messages.selectToAllLayout),
        onClick: () => {
          if (props.myUid) {
            const currentLayoutConfig = props.layoutConfig;

            const layoutConfig = {
              // eslint-disable-next-line @typescript-eslint/camelcase
              enlarge_video: currentLayoutConfig.enlarge_video || false,
              // eslint-disable-next-line @typescript-eslint/camelcase
              featured_id: currentLayoutConfig.featured_id || props.myUid,
              // eslint-disable-next-line @typescript-eslint/camelcase
              featured_type: currentLayoutConfig.featured_type || ("stream" as RoomLayoutStreamType),
            };

            SelectToAll('featured', layoutConfig);
          }
        },
        show: props.roles.some(r => ['room_owner', 'room_moderator'].includes(r))
      }
    ];
  };

  const getFullScreenSubItems = (): Array<CascadingMenuItem> => {
    return [
      {
        key: uuid.v4(),
        caption: formatMessage(messages.selectToMeLayout),
        onClick: () => {
          if (props.myUid) {
            const layoutConfig = {};
            SelectToMe('fullscreen', layoutConfig);
          }
        }
      },
      {
        key: uuid.v4(),
        caption: formatMessage(messages.selectToAllLayout),
        onClick: () => {
          if (props.myUid) {
            const currentLayoutConfig = props.layoutConfig;

            const layoutConfig = {
              // eslint-disable-next-line @typescript-eslint/camelcase
              enlarge_video: currentLayoutConfig.enlarge_video || false,
              // eslint-disable-next-line @typescript-eslint/camelcase
              featured_id: currentLayoutConfig.featured_id || props.myUid,
              // eslint-disable-next-line @typescript-eslint/camelcase
              featured_type: currentLayoutConfig.featured_type || ("stream" as RoomLayoutStreamType),
            };
            SelectToAll('fullscreen', layoutConfig);
          }
        },
        show: props.roles.some(r => ['room_owner', 'room_moderator'].includes(r))
      }
    ];
  };

  const getGridSubItems = (): Array<CascadingMenuItem> => {
    return [
      {
        key: uuid.v4(),
        caption: formatMessage(messages.selectToMeLayout),
        onClick: () => {
          const layoutConfig = {};
          SelectToMe('default', layoutConfig);
        }
      },
      {
        key: uuid.v4(),
        caption: formatMessage(messages.selectToAllLayout),
        onClick: () => {
          const layoutConfig = {};
          SelectToAll('default', layoutConfig);
        },
        show: props.roles.some(r => ['room_owner', 'room_moderator'].includes(r))
      }
    ];
  };

  const getMenuItems = (): Array<CascadingMenuItem> => {
    const menuItems: Array<CascadingMenuItem> = [
      {
        key: "fullscreen",
        caption: formatMessage(messages.fullscreenLayout),
        icon: <IconLayoutWebinar size={24} />,
        subMenuItems: getFullScreenSubItems()
      },
      {
        key: "presentation",
        caption: formatMessage(messages.presentationLayout),
        icon: <IconLayout size={24} />,
        subMenuItems: getPresentationSubItems()
      },
      {
        key: "grid",
        caption: formatMessage(messages.gridLayout),
        icon: <IconLayoutGrid size={24} />,
        subMenuItems: getGridSubItems()
      },
      {
        key: "audio",
        caption: formatMessage(messages.audioOnlyLayout),
        icon: <IconLayoutAudio size={24} />,
        onClick: () => SelectToMe('audioonly', {}),
      },
    ];

    if (props.amModerator) {
      const webinarItem: CascadingMenuItem =
        {
          key: "webinar",
          caption: formatMessage(messages.webinarLayout),
          icon: <IconLayoutWebinar size={24} />,
          onClick: () => SelectToAll('webinar', {}),
        };
      menuItems.push(webinarItem);

      const lessonItem: CascadingMenuItem =
        {
          key: "lesson",
          caption: formatMessage(messages.lessonLayout),
          icon: <IconLayoutWebinar size={24} />,
          onClick: () => SelectToAll('lesson', {}),
        };
      menuItems.push(lessonItem);
    }

    return filterAllowedLayouts(menuItems, props.allowedLayouts);
  };

  const closeMenu = React.useCallback(
    () => {
      setAnchorEl(null);
    }
    , []
  );

  function SelectToMe(layoutType: RoomLayout, layoutConfig: RoomLayoutConfig) {
    props.handleChangeLayout(layoutType, layoutConfig);
    setAnchorEl(null);
  }

  function SelectToAll(layoutType: RoomLayout, layoutConfig: RoomLayoutConfig) {
    props.handleChangeLayout(layoutType, layoutConfig);
    props.handleApplyLayout();
    setAnchorEl(null);
  }

  const disabled = props.disabled ? true : false;

  return (
    <React.Fragment>
      <ListItem disabled={disabled} button onClick={handleMenu}>
        <Tooltip
          placement="left"
          title={formatMessage(messages.chooseLayout)}
        >
          <ListItemIcon>
            <IconLayout size={28} />
          </ListItemIcon>
        </Tooltip>
      </ListItem>
      <CascadingMenu
        open={open}
        menuItems={getMenuItems()}
        anchorEl={anchorEl}
        onClose={closeMenu}
      />

    </React.Fragment >);

}

export default connect(mapStateToProps, mapDispatchToProps)(MenuLayout);
