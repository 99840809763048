import React from 'react';

import { connect } from 'react-redux';

import SplitPane from 'react-split-pane';

import { useLocation } from "react-router-dom";

import { useIntl, defineMessages } from 'react-intl';
import { isMobileOnly } from 'react-device-detect';

import { createStyles, Theme, makeStyles, useTheme } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';

import LocalStorage from '../../localStorage';
import { IconUsers } from '../IconSet';
import { IconUsersSelected } from '../IconSet';
import MenuLayout from '../MenuLayout';
import Room from './Room';
import Roster from '../Roster';
import { ChatIcon, ChatPanel } from '../Chat';
import LockRoom from '../LockRoom';
import OwnMeetingControls from '../OwnMeetingControls';
import MeetingTitle from './MeetingTitle';
import InviteToMeetingDialog from '../InviteToMeetingDialog';
import ShortcutKeys from './ShortcutKeys';
import Pip from './Pip';
import JoinMeetingDialog from './JoinMeetingDialog';
import MeetingLeftDialog from '../MeetingLeftDialog';
import JoinErrorDialog from './JoinErrorDialog';
import BlockNavigation from './BlockNavigation';
import DesktopControl from './DesktopControl';

import { State } from '../../lib/reducers';
import { isRecorder } from '../../lib/reduxSelectors/session';
import useDebounce from '../../lib/utils/debounce';
import {
  isAudioOnlyLayout,
  isThereAnyVideo,
  isWebinarLayout,
  isLessonLayout
} from '../../lib/reduxSelectors/room';
import { amModerator } from '../../lib/reduxSelectors/room';
import {
  getRosterSizePercent,
  getMinRosterSizePx,
  getMaxRosterSizePx,
  getPercent,
} from '../../lib/utils/rosterDimensions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainSplit: {
      position: 'relative !important' as 'relative',
    },
    content: {
      height: '100%',
      backgroundColor: theme.palette.secondary.light,
    },
    toolbar: {
      height: '100%',
    },
    pane: {
      height: '100%',
    },
  })
);


const messages = defineMessages({
  chatIconButtonTooltip: { id: 'chatIconButtonTooltip' },
  attendees: { id: 'attendees' },
});


type Props = {
  handleScreenShare: () => void;
  enableDesktopControl: () => void;
  localStore: LocalStorage;
  meetingId: string;
  url: string;
}

type ExtendedProps = Props & MappedProps


enum PaneType {
  Roster,
  Chat,
}

type SelectedPane = PaneType | null;


function PaneComponent({ selection, size }: { selection: SelectedPane; size: string }) {
  if (selection === PaneType.Roster) {
    return (<Roster size={size} />);
  }
  else if (selection === PaneType.Chat) {
    return (<ChatPanel />);
  }
  else {
    return null;
  }
}


function useQuery() {
  return new URLSearchParams(useLocation().search);
}


function shouldSkipJoinDialog(params: URLSearchParams, loggedIn: boolean) {
  if (!loggedIn) {
    return false;
  }
  const skip = (params.get("skip_device_settings") || "").toLowerCase();
  return ["yes", "true", "on"].includes(skip);
}


function Meeting(props: ExtendedProps) {
  const { isLoggedIn } = props;

  const queryParams = useQuery();

  const classes = useStyles();
  const { formatMessage } = useIntl();
  const initialPaneSize = isMobileOnly ? '100%' : getRosterSizePercent();
  const minPaneSize = isMobileOnly ? 0 : getMinRosterSizePx();
  const maxPaneSize = getMaxRosterSizePx();

  const [paneOpened, setPaneOpened] = React.useState(false);
  const [selectedPane, setSelectedPane] = React.useState<SelectedPane>(null);
  const [paneSize, setPaneSize] = React.useState<string>(initialPaneSize);

  const debouncedPaneSize = useDebounce(paneSize, 100);

  const disableLayoutAndRoster = (
    !props.isThereAnyVideo
    || (props.isWebinarLayout && !props.amModerator)
    || (props.isLessonLayout && !props.amModerator)
  );

  const setNewPaneSize = (size: number) => {
    const newSize = getPercent(size);
    setPaneSize(newSize);
  };

  const openPane = (
    wantedPane: PaneType,
    isPaneOpened: boolean,
    currentPane: SelectedPane
  ) => {
    if (!isPaneOpened) {
      setPaneOpened(true);
      setSelectedPane(wantedPane);
    }
    else {
      if (currentPane === wantedPane) {
        // user clicked on the icon relative to the currently opened pane:
        // close it
        setPaneOpened(false);
      }
      else {
        // user clicked on another pane: open the new pane
        setSelectedPane(wantedPane);
      }
    }
  };

  const onRosterClick = React.useCallback(
    () => openPane(PaneType.Roster, paneOpened, selectedPane)
    , [paneOpened, selectedPane]
  );

  const onChatClick = React.useCallback(
    () => openPane(PaneType.Chat, paneOpened, selectedPane)
    , [paneOpened, selectedPane]
  );

  if (paneOpened && selectedPane === PaneType.Roster && (props.isAudioOnlyLayout || disableLayoutAndRoster)) {
    setSelectedPane(null);
    setPaneOpened(false);
  }

  const [joinDialogDismissed, setJoinDialogDismissed] = React.useState(shouldSkipJoinDialog(queryParams, isLoggedIn));
  const onJoinDialogDismissed = React.useCallback(
    () => setJoinDialogDismissed(true)
    , [setJoinDialogDismissed]
  );
  const theme = useTheme();
  return (
    <React.Fragment>
      <SplitPane
        split="vertical"
        minSize={60}
        defaultSize={props.isRecorder ? 0 : 60}
        allowResize={false}
        className={classes.mainSplit}
        resizerStyle={{ visibility: 'hidden', display: 'none' }}
      >
        <div className={classes.toolbar}>
          { !props.isRecorder &&
            <List>
              <ListItem
                disabled={disableLayoutAndRoster || props.isAudioOnlyLayout}
                button
                onClick={onRosterClick}
              >
                <Tooltip
                  placement="left"
                  title={formatMessage(messages.attendees)}
                >
                  <ListItemIcon>
                    {(paneOpened && selectedPane === PaneType.Roster)
                      ? <IconUsersSelected size={28} />
                      : <IconUsers size={28} />
                    }
                  </ListItemIcon>
                </Tooltip>
              </ListItem>
              <ListItem button onClick={onChatClick}>
                <Tooltip
                  placement="left"
                  title={formatMessage(messages.chatIconButtonTooltip)}
                >
                  <ListItemIcon>
                    <ChatIcon
                      chatPanelOpen={paneOpened && (selectedPane === PaneType.Chat)}
                    />
                  </ListItemIcon>
                </Tooltip>
              </ListItem>
              <MenuLayout disabled={disableLayoutAndRoster} />
              <ListItem button>
                <ListItemIcon>
                  <LockRoom />
                </ListItemIcon>
              </ListItem>
            </List>
          }
        </div>
        <SplitPane
          split="vertical"
          minSize={minPaneSize}
          maxSize={maxPaneSize}
          defaultSize={initialPaneSize}
          allowResize={isMobileOnly ? false : paneOpened}
          resizerStyle={paneOpened ? { marginLeft: '8px' } : { visibility: 'hidden', display: 'none' }}
          size={paneOpened ? paneSize : 0}
          onChange={setNewPaneSize}
          pane1Style={(paneOpened && isMobileOnly) ? { zIndex: theme.zIndex.modal - 2 } : {}}

        >
          <div className={classes.pane}>
            <PaneComponent size={debouncedPaneSize} selection={selectedPane} />
          </div>
          <div className={classes.content}>
            <JoinMeetingDialog
              open={!joinDialogDismissed && !props.isRecorder && !shouldSkipJoinDialog(queryParams, isLoggedIn)}
              onJoin={onJoinDialogDismissed}
            />
            {(joinDialogDismissed || props.isRecorder || shouldSkipJoinDialog(queryParams, isLoggedIn)) && (
              <>
                <Room />
                <MeetingTitle />
                <ShortcutKeys />
                <OwnMeetingControls />
                <InviteToMeetingDialog />
                <MeetingLeftDialog />
                <JoinErrorDialog />
                <BlockNavigation />
                <Pip />
                <DesktopControl />
              </>
            )}
          </div>
        </SplitPane>
      </SplitPane>
    </React.Fragment>
  );
}


type MappedProps = {
  isRecorder: boolean;
  isThereAnyVideo: boolean;
  isWebinarLayout: boolean;
  isLessonLayout: boolean;
  isAudioOnlyLayout: boolean;
  amModerator: boolean;
  isLoggedIn: boolean;
}


const mapStateToProps = (state: State, ownProps: Props): MappedProps => {
  return {
    isRecorder: isRecorder(state, ownProps.url),
    isThereAnyVideo: isThereAnyVideo(state),
    isWebinarLayout: isWebinarLayout(state),
    isLessonLayout: isLessonLayout(state),
    isAudioOnlyLayout: isAudioOnlyLayout(state),
    amModerator: amModerator(state),
    isLoggedIn: state.auth.isAuthenticated || state.auth.isAuthenticatedAsGuest,
  };
};


export default connect(mapStateToProps)(Meeting);
