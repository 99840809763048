class ChatRoom {
  constructor(signalingServer, logger) {
    this.signalingServer = signalingServer;
    this.logger = logger;
    this.eventSubscription = this.signalingServer().subscribe('event', (data) => this._onEvent(data));

    // initialize handlers to keep track of them and to avoid typescript errors
    this.onEventMessageReceived = (_c, _s, _u, _d, _t)   => {};
    this.onPublicMessageReceived = (_c, _s, _f, _fd, _m, _t) => {};
    this.onPrivateMessageReceived = (_c, _s, _to, _tod, _f, _fd, _m, _t)   => {};
  }

  _onEvent(data) {
    if (data.type === 'chat') {
      const msg = data.data;
      const { class: cls, subclass, data: dt } = msg;
      switch (cls) {
        case 'event':
          this.onEventMessageReceived(cls, subclass, dt.username, dt.display_name, dt.ts);
          break;
        case 'message': {
          switch (subclass) {
            case 'public': {
              this.onPublicMessageReceived(cls, subclass, dt.from, dt.from_display_name, dt.message, dt.ts);
              break;
            }
            case 'private': {
              this.onPrivateMessageReceived(
                cls,
                subclass,
                dt.to,
                dt.to_display_name,
                dt.from,
                dt.from_display_name,
                dt.message,
                dt.ts
              );
              break;
            }
            default: break;
          }
          break;
        }
        default: break;
      }
    }
  }

  publishChatMessage(message, to) {
    return this.signalingServer().publishChatMessage(message, to);
  }

  tearDown() {
    if (this.eventSubscription) {
      this.signalingServer().unsubscribe('event', this.eventSubscription);
    }
  }
}

export { ChatRoom };
