import React  from 'react';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import ClosableDialog from '../ClosableDialog';

import getRouteFor, { Route } from '../../lib/utils/router';
import { State } from '../../lib/reducers';
import {
  createVideoRoom,
  roomErrorAcked,
  tearDownRoom,
} from '../../lib/actions/room';
import { joinRoom, leaveChannel } from '../../lib/actions/websocket';
import prepareWebRtcProvider from '../../rtc';
import { getLogger } from '../../lib/logger';


const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    fullWidth: {
      width: '100%',
    },
    link: {
      textDecoration: 'none',
    },
  }),
);



const messages = defineMessages({
  ok: { id: 'ok' },
  clickHereToLogin: { id: 'clickHereToLogin' },
  clickHereToLoginToOwnRoom: { id: 'clickHereToLoginToOwnRoom' },
  errorCodeGenericTitle: { id: 'errorCodeGenericTitle' },
  tooEarlyTitle: { id: 'errorCode1002Title' },
  tooEarlyRoomNotAvailable: { id: 'theRoomIsNotAvailableYet' },
  tooEarlyMeetingIsFromTo: { id: 'theRoomIsScheduledFromTo' },
  tooEarlyMeetingIsTodayFromTo: { id: 'theRoomIsScheduledTodayFromTo' },
  tooEarlyMeetingWillBeAvailableIn: { id: 'theRoomWillBeAvailableIn' },
  tryAgain: { id: 'tryAgain' },
  error: { id: 'error' },
  networkError: { id: 'networkError' },
  publishError: { id: 'mediaPublishFailure' },
  answerError: { id: 'answerError' },
  roomErrorContent: { id: 'unexpectedRoomError' },
  rpcTimeoutErrorContent: { id: 'rpcTimeoutError' },
  errorCode: {
    1000: {
      title: { id: 'errorCode1000Title' },
      message: { id: 'errorCode1000Body' },
    },
    1001: {
      title: { id: 'errorCode1001Title' },
      message: { id: 'errorCode1001Body' },
    },
    1002: {
      title: { id: 'errorCode1002Title' },
      message: { id: 'errorCode1002Body' },
    },
    1003: {
      title: { id: 'errorCode1003Title' },
      message: { id: 'errorCode1003Body' },
    },
    1004: {
      title: { id: 'errorCode1004Title' },
      message: { id: 'errorCode1004Body' },
    },
    1005: {
      title: { id: 'errorCode1005Title' },
      message: { id: 'errorCode1005Body' },
    },
    1008: {
      title: { id: 'errorCode1008Title' },
      message: { id: 'errorCode1008Body' },
    },
    1009: {
      title: { id: 'errorCode1009Title' },
      message: { id: 'errorCode1009Body' },
    },
    1010: {
      title: { id: 'errorCode1010Title' },
      message: { id: 'errorCode1010Body' },
    },
    1011: {
      title: { id: 'errorCode1011Title' },
      message: { id: 'errorCode1011Body' },
    },
    1012: {
      title: { id: 'errorCode1012Title' },
      message: { id: 'errorCode1012Body' },
    },
    1013: {
      title: { id: 'errorCode1013Title' },
      message: { id: 'errorCode1013Body' },
    },
    1408: {
      title: { id: 'connectionClosedUnexpectedlyTitle' },
      message: { id: 'connectionClosedUnexpectedlyMessage' },
    },
    4001: {
      title: { id: 'errorCode4001Title' },
      message: { id: 'errorCode4001Body' },
    },
    generic: {
      title: { id: 'errorCodeGenericTitle' },
      message: { id: 'errorCodeGenericBody' },
    }
  },
});


const reconnectionDelay = 15000; // Auto-reconnection delay in ms


type MappedProps = {
  joinFailed: boolean;
  roomError: State['room']['roomError'];
  errorCode: State['websocket']['errorCode'];
  errorMessage: State['websocket']['errorMessage'];
  errorPayload: State['websocket']['errorPayload'];
  isAuthenticated: boolean;
  loginEnabled: boolean;
}


type Props = {
};


type ExtendedProps = Props & MappedProps & RouteComponentProps<{id: string}>;


function useTimeout(callback: () => void, errorCode: number | null, condition: boolean) {
  React.useEffect(() => {
    let timerId: NodeJS.Timeout | null = null;
    if (condition) {
      timerId = setTimeout(callback, reconnectionDelay);
    }
    return () => {
      if (timerId) clearTimeout(timerId);
    };
  }, [callback, errorCode, condition]);
}


function tooEarlyForMeeting(errorCode: MappedProps['errorCode']) {
  return errorCode === 1002;
}


function isRetriableError(errorCode: MappedProps['errorCode']) {
  if (errorCode) {
    return [
      1000, // private room not running
      1001, // unhandled server error
      1002, // too early for scheduled meeting
      1005, // room owner not present yet
      1013, // room locked
      1408, // connection error
    ].includes(errorCode);
  }
  else {
    return false;
  }
}


function backToHome(history: RouteComponentProps['history'], confirm = true) {
  history.push(getRouteFor(Route.Home), { doNotConfirm: confirm });
}


// FIXME
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function reconnect(slug: string, dispatch: any) {
  if (slug) {
    const logger = getLogger(`Meeting ${slug}`);
    dispatch(roomErrorAcked());
    dispatch(tearDownRoom());
    dispatch(leaveChannel());
    dispatch(createVideoRoom(slug, prepareWebRtcProvider(), logger));
    dispatch(joinRoom(slug, logger));
  }
}


function SchedulingMessage(props: ExtendedProps) {
  const { formatMessage } = useIntl();

  const { errorPayload } = props;

  if (errorPayload && errorPayload.dt_start && errorPayload.dt_end) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const [ start, end ] = [ moment(errorPayload.dt_start), moment(errorPayload.dt_end) ];
    const now = moment();

    let message = '';
    if (start.isSame(now, 'day')) {
      message = formatMessage(messages.tooEarlyMeetingIsTodayFromTo,
        { start: start.format('LT'), end: end.format('LT') });
    }
    else {
      message = formatMessage(messages.tooEarlyMeetingIsFromTo,
        { date: start.format('LL'), start: start.format('LT'), end: end.format('LT') });
    }
    return (
      <DialogContentText>
        {message}
      </DialogContentText>
    );
  }
  else {
    return null;
  }
}


function AvailableInMessage(props: ExtendedProps) {
  const { formatMessage } = useIntl();

  const { errorPayload } = props;

  if (errorPayload && errorPayload.dt_start) {
    // eslint-disable-next-line @typescript-eslint/camelcase
    const start = moment(errorPayload.dt_start);
    const now = moment();
    const time = moment(start).from(now);
    if (start.diff(now, 'seconds') < 0) {
      return null;
    }

    return (
      <DialogContentText>
        {formatMessage(messages.tooEarlyMeetingWillBeAvailableIn, { time: time })}
      </DialogContentText>
    );
  }
  else {
    return null;
  }
}


function TooEarlyForMeeting(props: ExtendedProps) {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const { errorPayload } = props;
  const title = (errorPayload || {}).title || '<unknown>';

  const gotoHome = React.useCallback(
    () => backToHome(props.history, true)
    , [props.history]
  );

  const tryReconnect = React.useCallback(
    () => {
      const slug = props.match.params.id;
      if (slug) {
        reconnect(slug, dispatch);
      }
    }, [dispatch, props.match.params.id]
  );

  const condition = !!props.errorCode;
  useTimeout(tryReconnect, props.errorCode, condition);

  return (
    <ClosableDialog
      open={true}
      disableBackdropClick
      onClose={gotoHome}
      fullWidth
      title={formatMessage(messages.tooEarlyTitle)}
    >
      <DialogContent>
        <DialogContentText>
          {formatMessage(messages.tooEarlyRoomNotAvailable, { name: title })}
        </DialogContentText>
        <SchedulingMessage {...props} />
        <AvailableInMessage {...props} />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={tryReconnect} color='primary'>
          {formatMessage(messages.tryAgain)}
        </Button>
      </DialogActions>
    </ClosableDialog>
  );
}


function DialogTitle(props: ExtendedProps) {
  const { formatMessage } = useIntl();

  const { errorCode } = props;

  const errorCodes = messages.errorCode;
  type errKey = keyof typeof errorCodes;

  let label = errorCodes.generic.title;
  if (errorCode) {
    if (errorCodes[errorCode as errKey] && errorCodes[errorCode as errKey].title) {
      label = errorCodes[errorCode as errKey].title;
    }
  }

  return formatMessage(label);
}


function DialogMessage(props: ExtendedProps) {
  const { formatMessage } = useIntl();

  const { errorCode, errorPayload } = props;

  const errorCodes = messages.errorCode;
  type errKey = keyof typeof errorCodes;

  let label = errorCodes.generic.message;
  if (errorCode) {
    if (errorCodes[errorCode as errKey] && errorCodes[errorCode as errKey].message) {
      label = errorCodes[errorCode as errKey].message;
    }
  }

  let formattedMessage = '';
  if (errorCode === 1003 && errorPayload && errorPayload.dt_end) {
    // scheduled meeting has ended
    const end = moment(errorPayload.dt_end);
    const now = moment();
    const format = end.isSame(now, 'day') ? 'LT' : 'LL';
    // eslint-disable-next-line @typescript-eslint/camelcase
    formattedMessage = formatMessage(label, { dt_end: end.format(format) });
  }
  else if (errorCode === 1003) {
    formattedMessage = formatMessage(errorCodes.generic.message);
  }
  else {
    formattedMessage = formatMessage(label);
  }

  return (
    <DialogContentText>
      {formattedMessage}
    </DialogContentText>
  );
}


function RetriableError(props: ExtendedProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const gotoHome = React.useCallback(
    () => backToHome(props.history, true)
    , [props.history]
  );

  const tryReconnect = React.useCallback(
    () => {
      const slug = props.match.params.id;
      if (slug) {
        reconnect(slug, dispatch);
      }
    }, [dispatch, props.match.params.id]
  );

  const condition: boolean = !!props.errorCode && props.errorCode === 1005;
  useTimeout(tryReconnect, props.errorCode, condition);

  const title = DialogTitle(props);
  const message = DialogMessage(props);

  return (
    <ClosableDialog
      open={true}
      disableBackdropClick
      onClose={gotoHome}
      fullWidth
      title={title}
    >
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <Box
          className={classes.fullWidth}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
        >
          <Box flexGrow={1}>
            <LoginLink {...props} />
          </Box>
          <Box>
            <Button variant='contained' onClick={tryReconnect} color='primary'>
              {formatMessage(messages.tryAgain)}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </ClosableDialog>
  );
}


function NonRetriableError(props: ExtendedProps) {
  const classes = useStyles();

  const { formatMessage } = useIntl();

  const gotoHome = React.useCallback(
    () => backToHome(props.history, true)
    , [props.history]
  );

  const title = DialogTitle(props);
  const message = DialogMessage(props);

  return (
    <ClosableDialog
      open={true}
      disableBackdropClick
      onClose={gotoHome}
      fullWidth
      title={title}
    >
      <DialogContent>
        {message}
      </DialogContent>
      <DialogActions>
        <Box
          className={classes.fullWidth}
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
        >
          <Box flexGrow={1}>
            <LoginLink {...props} />
          </Box>
          <Box>
            <Button variant='contained' onClick={gotoHome} color='primary'>
              {formatMessage(messages.ok)}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </ClosableDialog>
  );
}


function LoginLink(props: ExtendedProps) {
  const classes = useStyles();

  const { isAuthenticated, errorCode, loginEnabled } = props;

  const { formatMessage } = useIntl();

  const meetingId = props.match.params.id;
  const loginQs = meetingId ? `redirectTo=${meetingId}` : undefined;
  const loginRef = getRouteFor(Route.Login, undefined, loginQs);

  const msgDef = (errorCode === 1000) ? messages.clickHereToLogin : messages.clickHereToLoginToOwnRoom;
  const msg = formatMessage(msgDef);

  if (isAuthenticated || !loginEnabled) {
    return null;
  }

  return (
    <a href={loginRef} className={classes.link}>
      <Typography
        color='primary'
        variant='caption'
        gutterBottom
        align='center'
      >
        {msg}
      </Typography>
    </a>
  );
}


function RoomError(props: ExtendedProps) {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch();

  const dismiss = React.useCallback(
    () => {
      if (props.roomError && props.roomError.recoverable) {
        dispatch(roomErrorAcked());
      }
      else {
        backToHome(props.history, true);
      }
    }
    , [props.history, props.roomError, dispatch]
  );

  const tryReconnect = () => {
    const slug = props.match.params.id;
    if (slug) {
      reconnect(slug, dispatch);
    }
  };

  const renderMsg = () => {
    const errorMap = {
      1408: messages.networkError,
      1409: messages.publishError,
      5004: messages.rpcTimeoutErrorContent,
      9000: messages.answerError,
    };
    const errCode = (props.roomError || {}).errorCode;

    const mappedError = errCode ? errorMap[errCode as keyof (typeof errorMap)] : null;
    let errmsg;
    if (mappedError) {
      errmsg = formatMessage(mappedError);
    }
    else {
      errmsg = 'Generic Error';
    }
    return formatMessage(messages.roomErrorContent, { errorMessage: errmsg });
  };

  const getButtons = () => {
    if (props.roomError && props.roomError.recoverable) {
      return (
        <Button variant='contained' onClick={dismiss} color='primary'>
          {formatMessage(messages.ok)}
        </Button>
      );
    }
    else {
      return (
        <Button variant='contained' onClick={tryReconnect} color='primary'>
          {formatMessage(messages.tryAgain)}
        </Button>
      );
    }
  };

  return (
    <ClosableDialog
      open={true}
      disableBackdropClick
      onClose={dismiss}
      fullWidth
      title={formatMessage(messages.error)}
    >
      <DialogContent>
        <DialogContentText>
          {renderMsg()}
        </DialogContentText>
        <SchedulingMessage {...props} />
        <AvailableInMessage {...props} />
      </DialogContent>
      <DialogActions>
        {getButtons()}
      </DialogActions>
    </ClosableDialog>
  );
}


function JoinErrorDialog(props: ExtendedProps) {
  const { joinFailed, roomError, errorCode } = props;

  if (roomError) {
    return <RoomError {...props} />;
  }

  if (tooEarlyForMeeting(errorCode)) {
    return <TooEarlyForMeeting {...props} />;
  }

  if (isRetriableError(errorCode)) {
    return <RetriableError {...props} />;
  }

  if (joinFailed || roomError) {
    return <NonRetriableError {...props} />;
  }

  return null;
}


function mapStateToProps(state: State) {
  return {
    joinFailed: state.websocket.joinFailure,
    roomError: state.room.roomError,
    errorCode: state.websocket.errorCode,
    errorMessage: state.websocket.errorMessage,
    errorPayload: state.websocket.errorPayload,
    isAuthenticated: state.auth.isAuthenticated,
    loginEnabled: state.appconfig.login_enabled,
  };
}


export default connect(mapStateToProps)(withRouter(JoinErrorDialog));
