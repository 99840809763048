export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export function addNotification(reference, data) {
  return {
    type: ADD_NOTIFICATION,
    payload: {
      ref: reference,
      notification: data
    }
  };
}

export function removeNotification(reference) {
  return {
    type: REMOVE_NOTIFICATION,
    payload: {
      ref: reference
    }
  };
}
