import { commitMutation } from 'react-relay';
import { graphql } from 'babel-plugin-relay/macro';
import { newEvent, ERROR } from '../../notifications';

const mutation = graphql`
mutation scheduleMeetingMutation(
  $input: ScheduleMeetingInput!
) {
  scheduleMeeting(input: $input)
  {
    errors {
      key
      message
      reason
    }
    meeting {
      id
      userId
      domainId
      title
      url
      slug
      number
    }
  }
}
`;

// FIXME
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
function maybeNotifyError(response) {
  let inError = false;

  if (response && response.scheduleMeeting.errors !== null) {
    inError = true;
  }
  if (!response) {
    inError = true;
  }

  if (inError) {
    newEvent(ERROR, 'errorCodeGenericBody', 'errorCodeGenericBody',
      "Schedule meeting error");
  }
}

// FIXME
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
function scheduleMeeting(environment, { title, userId, domainId, dtStart, dtEnd, users, notes }) {
  const attendees = users.value.map((el: string) => {
    return ({ email: el });
  });
  return new Promise((resolve, reject) => {
    const variables = {
      input: {
        meeting: {
          title,
          userId,
          domainId,
          dtStart,
          dtEnd,
          attendees,
          notes
        }
      },
    };
    commitMutation(
      environment,
      {
        mutation,
        variables,
        onCompleted: (response, errors) => {
          if (errors) {
            maybeNotifyError(null);
            return reject(errors);
          }
          maybeNotifyError(response);
          return resolve(response);
        },
        onError: err => {
          maybeNotifyError(null);
          reject(err);
        }
      },
    );
  });
}

export { scheduleMeeting };
