import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { useIntl, defineMessages } from 'react-intl';

import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import DevicePreview from '../AVSettings';
import DeviceSelection from '../AVSettings/DeviceSelection';
import ClosableDialog from '../ClosableDialog';

import { getLogger } from '../../lib/logger';
import { State } from '../../lib/reducers';
import { toggleDeviceSettings } from '../../lib/actions/settings';
import { republishStream } from '../../lib/actions/room';


const messages = defineMessages({
  ok: { id: 'ok' },
  dialogTitle: { id: 'deviceSelectorTitle' },
});


function DeviceSelector(props: ExtendedProps) {
  const { isOpen, inMeeting } = props;

  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const close = React.useCallback(
    () => dispatch(toggleDeviceSettings())
    , [dispatch]
  );

  const roomOptions = {
    video: true,
    muted: false,
    frameRate: props.roomOptions.frame_rate,
    streamQuality: props.roomOptions.stream_quality,
  };

  const accept = React.useCallback(
    () => {
      if (inMeeting) {
        const logger = getLogger(`Republish stream`);
        dispatch(republishStream(roomOptions, logger));
      }
      dispatch(toggleDeviceSettings());
    }
    , [dispatch, inMeeting, roomOptions]
  );

  return (
    <ClosableDialog
      open={isOpen}
      disableBackdropClick
      onClose={close}
      fullWidth={false}
      title={formatMessage(messages.dialogTitle)}
    >
      <DialogContent>
        <div style={{ height: '300px', marginBottom: '2em' }}>
          <DevicePreview videoEnabled={true} />
        </div>
        <DeviceSelection withVideo={true} />
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={accept} color='primary'>
          {formatMessage(messages.ok)}
        </Button>
      </DialogActions>
    </ClosableDialog>
  );
}


type Props = {
};


type MappedProps = {
  isOpen: State['settings']['device_settings'];
  inMeeting: boolean;
  roomOptions: State['appconfig']['room_options'];
}


type ExtendedProps = Props & MappedProps


function mapStateToProps(state: State) {
  return {
    isOpen: state.settings.device_settings,
    inMeeting: Boolean(state.websocket.room),
    roomOptions: state.appconfig.room_options,
  };
}


export default connect(mapStateToProps)(DeviceSelector);
